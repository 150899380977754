<template>
    <div>
        <div v-if="shouldShowEffectSlots()">
            <div
                v-for="item in this.pEffectSlots" :key="item">
                Visual effects of <strong>{{ item }}</strong> (update behaviour will reset the period)
                <v-list-item
                    no-gutters v-for="visualEffect in getVisualEffectByTarget(item)"
                    :key="visualEffect.effect">

                    <v-btn
                        class="mx-2"
                        text
                        icon
                        color="red lighten-2"
                        @click="removeVisualEffect(visualEffect.effect)"
                    >
                        <v-icon> mdi-delete-forever</v-icon>
                    </v-btn>

                    <strong> {{ renderVisualEffect(visualEffect) }} ({{visualEffect.duration}}s) </strong>
                    <v-checkbox
                            class="ml-5"
                            label='aura'
                            v-model="visualEffect.aura">
                    </v-checkbox>

                </v-list-item>

                <v-row no-gutters class="ml-1 mt-1">
                    <v-btn
                        class="ma-2"
                        text
                        icon
                        color="green lighten-2"
                        @click="enableVisualEffectSelector(item)"
                    >
                        <v-icon>mdi-creation</v-icon>
                    </v-btn>
                </v-row>
            </div>
        </div>


        <v-overlay :value="showEffectSelector">
            <VisualEffectSelector
                :p-visual-effect-of-targets="visualEffectBySelectedTarget"
                :pBehaviourBean="this.pBehaviourBean"
                @close="showEffectSelector = false"
                @saveTheVisualAndTargets="saveTheVisualAndTargets"
            />
        </v-overlay>

    </div>
</template>

<script>

import VisualEffectSelector from "@/item-editor/modules/BehaviourForm/screens/VisualEffectSelector";

export default {
    name: "VisualEffectOfTargets",
    components: {VisualEffectSelector},
    props: ['pEffectSlots', 'pVisualEffectOfTargets', 'pBehaviourBean'],
    data() {
        return {
            showEffectSelector: false,
            visualEffectOfTargets: this.pVisualEffectOfTargets ?? [],
            selectedTarget: null,
        }
    },
    methods: {
        shouldShowEffectSlots() {
            if (this.pEffectSlots) {
                return true;
            }
            return false;
        },
        renderVisualEffect: function (visualEffect) {
            let result = "";
            result = visualEffect.effect;
            return result;
        },
        getVisualEffectByTarget (target) {
            return this.visualEffectOfTargets.filter(effect => effect.targets.includes(target));
        },

        enableVisualEffectSelector(target) {
            this.selectedTarget = target;
            this.showEffectSelector = true
        },

        removeVisualEffect: function (visualEffectValue) {
            this.visualEffectOfTargets = this.visualEffectOfTargets.filter(vs => vs.effect !== visualEffectValue);
            this.$emit('eventRefreshVisualAndTargets', this.visualEffectOfTargets);
        },

        //listening
        saveTheVisualAndTargets(visualEffects) {
            //clear selected target from existing effect
            this.visualEffectOfTargets.forEach(effect => {
                let foundTarget = effect.targets.includes(this.selectedTarget);
                if (foundTarget) {
                    effect.targets = effect.targets.filter(target => target !== this.selectedTarget);
                }

            });

            //disable this to fix bug, effect property was reset
            //this.visualEffectOfTargets = this.visualEffectOfTargets.filter(effect => effect.targets.length > 0);

            //study new effects
            visualEffects.forEach(effectNew => {
                let effectExist = this.visualEffectOfTargets.find(({effect}) => effect === effectNew.value);
                if (effectExist) {
                    let foundTarget = effectExist.targets.includes(this.selectedTarget);
                    if (!foundTarget) {
                        effectExist.targets.push(this.selectedTarget);
                    }
                } else {
                    this.visualEffectOfTargets.push({
                        effect: effectNew.value,
                        duration: effectNew.duration,
                        targets: [this.selectedTarget]
                    });
                }
            });
            this.$emit('eventRefreshVisualAndTargets', this.visualEffectOfTargets);
        },
    },
    computed: {
        visualEffectBySelectedTarget () {
            return this.visualEffectOfTargets.filter(effect => effect.targets.includes(this.selectedTarget));
        }
    },
    watch: {
        pVisualEffectOfTargets : function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.visualEffectOfTargets = newVal ?? [];
            }
        }
    }
}
</script>

<style scoped>

</style>