import {ItemColorCodes, ItemColorNames} from "@/item-editor/constants/ItemColors";
import BehaviourCategories from "@/item-editor/constants/BehaviourCategories";

export default {
     getColorCodeByCat(cat){
          if (cat === BehaviourCategories.attack){
               return ItemColorCodes.rouge;
          } else if (cat === BehaviourCategories.defense){
               return ItemColorCodes.purple;
          } else if (cat === BehaviourCategories.health){
               return ItemColorCodes.green;
          } else if (cat === BehaviourCategories.farm){
               return ItemColorCodes.blue;
          } else {
               return ItemColorCodes.brown;
          }
     },
     getColorNameByCat(cat){
          if (cat === BehaviourCategories.attack){
               return ItemColorNames.rouge;
          } else if (cat === BehaviourCategories.defense){
               return ItemColorNames.purple;
          } else if (cat === BehaviourCategories.health){
               return ItemColorNames.green;
          } else if (cat === BehaviourCategories.farm){
               return ItemColorNames.blue;
          } else {
               return ItemColorNames.brown;
          }
     },
}