<template>
  <v-card elevation="0" light width="1200">
      <HeaderPopup
            @save="validateToSaveOrCreateNewItem"
            @close="close()"
            :p-show-sibling="false"
            :p-can-save="false"
      >
          {{getFormTitle}}
      </HeaderPopup>
      <v-form ref="form" lazy-validation>
          <v-card elevation="0"
                  class="grey lighten-5 overflow-hidden"
                  :max-height="computeWindowHeight">

              <v-row>
                  <v-col cols="2">
                      <v-img class="my-3 ml-3"
                             v-if="this.getPossibleBoarderIcon"
                             :src="getBoarderIcon()"
                             min-width="100"
                             max-width="100"
                             @click="clickToChangeIcon"
                      >
                          <v-row class="fill-height">
                              <v-row justify="center">
                                  <v-card-title>
                                      <v-img
                                              :src="getIcon()"
                                              max-width="60px"
                                              max-height="60px"
                                              dark
                                              class="mt-3"
                                      ></v-img>
                                  </v-card-title>
                              </v-row>
                          </v-row>
                      </v-img>
                      <v-img
                              v-if="!this.getPossibleBoarderIcon"
                              :src="getIcon()"
                              max-width="85px"
                              max-height="85px"
                              dark
                              @click="clickToChangeIcon"
                              class="my-3">
                      </v-img>
                  </v-col>
                  <v-col cols="3" align-self="center">
                      <v-chip class="mt-1" label small>
                          #{{this.itemData.category}}
                      </v-chip>
                      <v-chip class="mt-1" label small v-if="itemData.color">
                          #{{itemData.color}}
                      </v-chip>
                      <v-chip class="mt-1" label small v-if="this.renderMainBehaviourCat()">
                          #{{this.renderMainBehaviourCat()}}
                      </v-chip>
                      <v-chip class="mt-1" label small v-if="this.shouldRenderTargetSelect()">
                          #{{this.renderTargetSelect()}}
                      </v-chip>
                  </v-col>
                  <v-col class="pt-10" v-if="itemFormDescription!==''">
                      <v-card-text v-html="itemFormDescription"></v-card-text>
                  </v-col>
              </v-row>

              <v-row class="pt-0 mt-0">
                  <v-col v-if="this.itemData.category==='weapon' || this.itemData.category==='glove'">
                      <v-container v-if="supportedHeroes.length > 0">
                          Heroes can use this weapon:
                          <v-row>
                              <v-img
                                      v-for="hID in supportedHeroes" :key="hID"
                                      :src="getHeroHeadIcon(hID)"
                                      max-width="50px"
                                      max-height="50px"
                                      dark
                                      class="mt-3"
                              ></v-img>
                          </v-row>
                      </v-container>
                      <v-container v-else>
                          <v-chip
                                  class="ma-2"
                                  color="red lighten-1"
                                  text-color="white"
                          >
                              There is no hero data matching this weapon.
                          </v-chip>
                      </v-container>
                      <br/>
                  </v-col>
                  <v-col class="pt-6 mt-0" cols="5">
                      <InputValidate :rules="[itemData.behaviours.length > 0 || 'Behaviour required!!!']">
                          Behaviour *
                      </InputValidate>
                      <v-container>
                          <ul>
                              <p v-for="behaviour in itemData.behaviours" :key="behaviour.behaviourBean.join">

                                  <v-btn
                                          class="ma-2"
                                          text
                                          icon
                                          color="red lighten-2"
                                          @click="removeBehaviour(behaviour.behaviourBean.join)"
                                          v-if="!isDisableInput"
                                  >
                                      <v-icon> mdi-delete-forever </v-icon>
                                  </v-btn>

                                  <v-btn
                                          class="ma-2"
                                          text
                                          icon
                                          color="green lighten-2"
                                          @click="editBehaviour(behaviour.behaviourBean.join)"
                                          v-if="!isDisableInput"
                                  >
                                      <v-icon>mdi-table-edit</v-icon>
                                  </v-btn>
                                  {{ renderDetailBean(behaviour) }} {{renderGameplayImpact(behaviour)}}
                              </p>
                          </ul>

                          <v-btn
                                  class="ma-2"
                                  text
                                  icon
                                  @click="hideOrShowFormBehaviour()"
                                  v-if="!isDisableInput"
                          >
                              <v-icon color="green lighten-2">mdi-note-plus</v-icon>
                          </v-btn>
                      </v-container>
                  </v-col>
                  <v-col class="pt-6 mt-0">
                      <InputValidate
                              v-if="!isLifeTimeHide"
                              :rules="[itemData.lifeTime.type !== '' || 'Life required!!!']">
                          Life time - How long item can stay *
                      </InputValidate>
                      <v-container v-if="!isLifeTimeHide">
                          <v-row v-if="!isDisableInput">
                              <v-col>
                                  <v-combobox
                                          :disabled="itemData.behaviours.length <= 0"
                                          :items="computeRefLifeTime"
                                          v-model="itemData.lifeTime.bean"
                                          label="Type"
                                          dense
                                          @change="didSelectLifeTimeType"
                                          :rules="rulesNotEmpty('life type')"
                                  ></v-combobox>
                              </v-col>
                          </v-row>
                          <ul v-if="isDisableInput">
                              <!-- <p>{{itemData.lifeTime.bean.text}}</p> -->
                          </ul>
                      </v-container>
                  </v-col>
              </v-row>

              <InputValidate v-if="behaviourNeedTargetSelect" :rules="[itemData.targetSelect.length>0 || 'Target required!!!']">
                  Select Owner to equip or to use *
              </InputValidate>
              <v-container v-if="behaviourNeedTargetSelect">
                  <v-btn
                      class="mx-1 mb-3"
                      v-for="mode in refTargetSelects"
                      :key="mode"
                      @click="didSelectTarget(mode)"
                  >
                      <v-icon  :color="renderTargetSelectBookmark(mode)"  >mdi-bookmark</v-icon>
                      {{mode}}
                  </v-btn>
              </v-container>

              <InputValidate v-if="shouldShowModeSelect" :rules="[itemData.modes.length>0 || 'mode required!!!']">
                  Available to use in mode *
              </InputValidate>
              <v-container v-if="shouldShowModeSelect">
                  <v-btn
                          class="mx-1  mb-3"
                          v-for="mode in refModes"
                          :key="mode"
                          @click="didSelectMode(mode)"
                  >
                      <v-icon  :color="renderModeBookmark(mode)"  >mdi-bookmark</v-icon>
                      {{mode}}
                  </v-btn>
              </v-container>

              <v-row v-if="!isDisableInput">
                  Localize Data
                  <v-container>
                      <LocalizeItemForm :itemData="itemData">
                      </LocalizeItemForm>
                  </v-container>
              </v-row>

              <v-container class="ml-0 pl-0 pt-0">
                  {{getGenericListTitle}}
              </v-container>
              <v-virtual-scroll
                      :item-height="getHeightSiblingItem()"
                      :height="450"
                      :items="this.optimizedList"
              >
                  <template v-slot:default="{ item }">
                      <v-row v-if="item.length > 0" dense no-gutters class="mt-3">
                          <v-col
                                  :cols=1
                                  v-for="it in item"
                                  :key="it.level"
                                  class="mx-15"
                          >
                              <SiblingLevelCardItem :pLevelCardData="it"></SiblingLevelCardItem>
                          </v-col>
                      </v-row>
                  </template>
              </v-virtual-scroll>

              <v-overlay :value="showFormBehaviour" v-if="formBehaviour">
                  <NewBehaviourForm
                          @close="hideOrShowFormBehaviour()"
                          @addVisualEffect="addVisualEffect"
                          :pFormBehaviour="formBehaviour"
                          :pItemCategory="itemData.category"
                  />
              </v-overlay>

              <v-overlay :value="showFormSibling" v-if="formSibling">
                  <SiblingGenerationForm
                          @close="hideOrShowFormSibling()"
                          :pItemData="itemData"
                  />
              </v-overlay>

              <v-overlay :value="isLoading">
                  <v-progress-circular
                          indeterminate
                          size="64"
                  ></v-progress-circular>
              </v-overlay>

              <v-snackbar
                      v-model="snackbar"
                      :timeout="timeoutSnackbar"
                      :color="colorSnackbar"
                      centered
              >
                  {{ textSnackbar }}

                  <template v-slot:action="{ attrs }">
                      <v-btn
                              color="blue"
                              text
                              v-bind="attrs"
                              @click="snackbar = false"
                      >
                          Close
                      </v-btn>
                  </template>
              </v-snackbar>

          </v-card>
      </v-form>
  </v-card>

</template>

<script>
    import SMPNum from "../../../../SMPNum";
    import lodash from 'lodash';
    import NewBehaviourForm from "@/item-editor/modules/BehaviourForm/screens/NewBehaviourForm";
    import EquipmentDataLoader from "@/item-editor/dao/EquipmentDataLoader";
    import HeroAndWeaponDataLoader from "@/item-editor/dao/HeroAndWeaponDataLoader";
    import ShieldDataLoader from "@/item-editor/dao/ShieldDataLoader";
    import ItemApi from "../../../dao/ItemApi.js"
    import LocalizeItemForm from "@/item-editor/modules/LocalizeItemForm";
    import {EVENT_ON_ITEM_SAVED, EVENT_TO_CHANGE_ICON_ITEM, EVENT_TO_CHANGE_ICON_SIBLING_ITEM, GlobalEvents} from "@/item-editor/events/GlobalEvents";
    import ItemColorConstants from "../../../constants/ItemColorConstants.js";
    import GamePlayImpactType from "@/item-editor/constants/GamePlayImpactType";
    import HeaderPopup from "../../HeaderPopupComponent.vue";
    import ItemCategories, {ItemCategoryNames} from "@/item-editor/constants/ItemCategories";
    import ItemColors from "@/item-editor/constants/ItemColors";
    import InputValidate from "../../InputVilidateComponent.vue";
    import ItemLifeTime, {ItemLifeTimeShortcut} from "@/item-editor/constants/ItemLifeTime";
    import BehaviourUtils from "@/item-editor/Utils/BehaviourUtils";
    import LifeTimeUtils from "@/item-editor/Utils/LifeTimeUtils";
    import ItemTargets from "@/item-editor/constants/ItemTargets";
    import SiblingGenerationForm from "../screens/SiblingGenerationForm";
    import SiblingLevelCardItem from "./SiblingLevelCardItem";

    let valueTypes = [];
    GamePlayImpactType.valueTypes.forEach(v => {
        valueTypes.push(v);
    });


export default {
    name: "NewSiblingItemForm",
    props: ['pItemData', 'pSiblingUtils'],
    data() {
        return {
            isNewItemForm: true,
            snackbar: false,
            textSnackbar: '',
            colorSnackbar: 'success',
            timeoutSnackbar: 2000,
            isLoading: false,
            showFormBehaviour: false,
            refEquipmentData: [],
            refHeroAndWeaponData: [],
            refShieldData:[],
            refModes: ['Battle', 'Normal'],
            refTargetSelects: [],
            refCategories: ItemCategories,
            refColors: ItemColors,
            refLifeTimeType: ItemLifeTime,
            itemData: null,
            backUpIconName: null,
            formBehaviour: null,
            itemFormDescription: "",
            isLifeTimeHide: false,
            showFormSibling: false,
            formSibling: null,
            lastItemStatus: "",
            isDisableInput: true,
            optimizedList: [],
            siblingUtils: null,
        }
    },
    components: {
        SiblingGenerationForm,
        LocalizeItemForm,
        NewBehaviourForm,
        HeaderPopup,
        InputValidate,
        SiblingLevelCardItem,
    },
    methods: {
        renderMainBehaviourCat (){
            let mainBehaviour = this.getMainBehaviour();
            if (mainBehaviour){
                return mainBehaviour.behaviourBean.cat;
            } else {
                return null;
            }
        },
        renderGameplayImpact: function (behaviour) {
            if (behaviour.gamePlayImpact.value < 0 || !behaviour.gamePlayImpact.valueType) {
                return '';
            }
            let des = "";
            let bonus = behaviour.gamePlayImpact.value;
            let bean = behaviour.gamePlayImpact.bean;
            if(bean !== null && bean.valueType !== null && bean.valueType.value === "DirectDisplayValue"){
                des += bean.valueType.text;
            } else if(behaviour.gamePlayImpact.valueType === "DirectValue"){
                des = SMPNum.fromPowerString(bonus).ToReadableAlphabetV2();
            } else {
                des = bonus;
            }
            des += " "
                + GamePlayImpactType.valueTypeSymbols[behaviour.gamePlayImpact.valueType];

            if (behaviour.gamePlayImpact.periodValue > 0) {
                des += ' every ' + behaviour.gamePlayImpact.periodValue
                    + " "
                    + GamePlayImpactType.periodTypeSymbols[behaviour.gamePlayImpact.periodType];
            }

            return des;
        },
        renderDetailBean(behaviour){
            let detail = "" + behaviour.behaviourBean.des.details;
            if(behaviour.type === "inc_powerup_capacity"){
                let skillType = behaviour.gamePlayImpact.skillType;
                if(skillType !== null) {
                    if (detail.includes("Power up")) {
                        detail = detail.replace("Power up", skillType);
                    } else {
                        detail += " " + behaviour.gamePlayImpact.skillType;
                    }
                } else if (detail.includes("Power up")) {
                    detail = detail.replace("Power up", "all power-up");
                }
            }
            return detail;
        },

        initDefaultFormBehavior() {
            this.formBehaviour = {
                behaviourBean: null,
                type: "",
                gamePlayImpact: {
                    value: null,
                    valueType: null,
                    periodValue: null,
                    periodType: null,
                    skillType: null,
                    visualEffectOfTargets: [],
                    receivers: [],
                }
            };
        },
        getIcon() {
            let iconName = this.itemData.iconName + ".png";

            let result = null;
            try {
                result = require('../../../assets/weapons/textures/' + iconName);
            } catch(e){
                console.log("Not found image name : " + iconName);
                result = require('../../../assets/weapons/no-image.png');
            }

            return result;
        },
        getBoarderIcon() {
            let color = this.itemData.color;
            if (!color) {
                return this.getIcon();
            }
            let iconName = ItemColorConstants.iconColorNames[color];
            return require('../../../assets/icons/itemboarders/' + iconName);
        },
        getHeroHeadIcon(hID) {
            return require('../../../assets/heroes/id' + hID+'.png');
        },
        renderModeBookmark(target) {
            let color = 'grey';
            if (!this.itemData.modes) {
                color = 'green';
                return color;
            }
            this.itemData.modes.forEach(v => {
                if (v === target) {
                    color = 'green';
                }
            })
            return color;
        },
        renderTargetSelectBookmark(target) {
            let color = 'grey';
            this.itemData.targetSelect.forEach(v => {
                if (v === target) {
                    color = 'green';
                }
            })
            return color;
        },
        shouldRenderTargetSelect() {
            return this.itemData.targetSelect && this.itemData.targetSelect.length > 0;
        },
        renderTargetSelect() {
            let targets = '';
            let length = this.itemData.targetSelect.length;
            for (let i = 0; i < length; i++) {
                targets += this.itemData.targetSelect[i];
                if (i < length - 1) {
                    targets += ' | '
                }
            }

            return targets;
        },

        showSibling(){
            this.hideOrShowFormSibling();
        },
        isShowSiblingBT() {
            if (this.isNewItemForm && !this.isUpdateSiblingForm()) {
                return false
            } else {
                return true;
            }
        },
        hideOrShowFormSibling: function () {
            if (!this.showFormSibling) {
                //test set not null;
                this.formSibling = {

                };
            }
            this.showFormSibling = (!this.showFormSibling);
        },

        clearModeSelect() {
            if(this.itemData.modes.length > 0){
                this.itemData.modes = [];
                this.validateItemFormDescription();
            }
        },
        didSelectMode: function (target) {
            if (!lodash.includes(this.itemData.modes, target)) {
                this.itemData.modes.push(target);
            } else {
                let indexToRemove = lodash.findIndex(this.itemData.modes, function (n) {
                    return n === target;
                })
                this.itemData.modes.splice(indexToRemove, 1);
            }
            this.validateItemFormDescription();
        },
        didSelectTarget: function (target) {
            if (!lodash.includes(this.itemData.targetSelect, target)) {
                this.itemData.targetSelect.push(target);
            } else {
                let indexToRemove = lodash.findIndex(this.itemData.targetSelect, function (n) {
                    return n === target;
                })
                this.itemData.targetSelect.splice(indexToRemove, 1);
            }
            this.validateItemFormDescription();
        },

        hideOrShowFormBehaviour: function () {
            if (!this.showFormBehaviour) {
                this.initDefaultFormBehavior();
            }
            this.showFormBehaviour = (!this.showFormBehaviour);

            if(!this.showFormBehaviour){
                this.refreshSignLifeTimeDirect();
            }
            this.validateItemFormDescription();
        },
        refreshSignLifeTimeDirect(){
            this.isLifeTimeHide = false;
            let mainBehave = this.getMainBehaviour();
            if (mainBehave) {
                //add auto transfer impact duration to life time
                let refDirectLifeTime = LifeTimeUtils.getDirectLifeTime(mainBehave);
                if (refDirectLifeTime !== null) {
                    this.didAssignLifeTimeDirect(refDirectLifeTime);
                }
            }
        },
        didSelectLifeTimeType() {
            let lifeTime = ItemLifeTimeShortcut[this.itemData.lifeTime.bean.value];
            this.assignLifeTime(lifeTime);
        },
        didAssignLifeTimeDirect(lifeTime){
            this.isLifeTimeHide = true;
            this.itemData.lifeTime.bean = lifeTime.bean;
            this.assignLifeTime(lifeTime);
        },
        assignLifeTime(lifeTime){
            if (lifeTime){
                this.itemData.lifeTime.type = lifeTime.type;
                this.itemData.lifeTime.limit = lifeTime.value;
            }else {
                this.itemData.lifeTime.type = '';
                this.itemData.lifeTime.limit = 0;
            }
            this.validateItemFormDescription();
        },
        clickToChangeIcon() {
            if(this.isDisableInput) return;
            if(this.isUpdateSiblingForm()){
                GlobalEvents.$emit(EVENT_TO_CHANGE_ICON_SIBLING_ITEM,this.itemData);
            } else {
                GlobalEvents.$emit(EVENT_TO_CHANGE_ICON_ITEM,this.itemData);
            }
        },
        validateToSaveOrCreateNewItem(){
            let successful = this.$refs.form.validate();
            if (successful){
                this.saveOrCreateNewItem();
            }
        },
        saveOrCreateNewItem() {

            let item = this.itemData;
            console.log("Equipment code : " + item.equipmentCode);
            //we remove equipmentType from model, because is duplicate with category
            //item.equipmentType = "gameplay";
            item.availableForSale = false;
            item.costPerUnit = "1";
            item.level = 1;
            item.gameLevel = {
                levelStart: -1,
                levelEnd: -1
            };
            item.status = "dev";

            this.isLoading = true;

            if (this.isNewItemForm) {
                //create
                this.postNewItem(item);
            } else {
                //update
                this.updateItem(item);
            }
        },

        postNewItem(item) {
            ItemApi.postItem(item)
                .then(result => {
                    console.log("Result : " + result);
                    this.snackbar = true;
                    this.textSnackbar = "New item was created!";
                    this.colorSnackbar = 'success';

                    setTimeout(() => {
                        this.isLoading = false;
                        this.close();
                        GlobalEvents.$emit(EVENT_ON_ITEM_SAVED);
                    }, 1500);

                })
                .catch(error => {
                    this.snackbar = true;
                    this.textSnackbar = "Failed to create a new item: " + error;
                    this.colorSnackbar = 'red';

                    setTimeout(() => {
                        this.isLoading = false;
                    }, 200);
                })
        },
        updateItem(item) {
            ItemApi.updateItem(item)
                .then(result => {
                    console.log("Result : " + result);
                    this.snackbar = true;
                    this.textSnackbar = "Item was updated!";
                    this.colorSnackbar = 'success';

                    setTimeout(() => {
                        this.isLoading = false;
                        this.close();
                        GlobalEvents.$emit(EVENT_ON_ITEM_SAVED);
                    }, 1500);

                })
                .catch(error => {
                    this.snackbar = true;
                    this.textSnackbar = "Failed to update item: " + error;
                    this.colorSnackbar = 'red';

                    setTimeout(() => {
                        this.isLoading = false;
                    }, 200);
                })
        },

        removeBehaviour: function (join) {
            let filtered = this.itemData.behaviours.filter(function (behaviour) {
                return behaviour.behaviourBean.join !== join
            });
            this.itemData.behaviours = filtered;
            this.refreshItemColor();
            this.refreshItemTarget();
            this.refreshSignLifeTimeDirect();
            this.validateItemFormDescription();
            //refresh lifetime
            this.itemData.lifeTime.bean = null;
        },
        editBehaviour: function (join) {
            let indexToEdit = lodash.findIndex(this.itemData.behaviours, function (bh) {
                return bh.behaviourBean.join === join;
            })

            this.formBehaviour = lodash.cloneDeep(this.itemData.behaviours[indexToEdit]);
            this.formBehaviour.isEdit = true;
            this.formBehaviour.indexToEdit = indexToEdit;
            this.showFormBehaviour = true;
            this.refreshSignLifeTimeDirect();
            this.validateItemFormDescription();
        },
        close() {
            this.$emit('close');
        },

        //listening
        addVisualEffect(formBehaviour) {
            if (formBehaviour.isEdit) {
                let indexToEdit = formBehaviour.indexToEdit;
                if (indexToEdit >= 0) {
                    formBehaviour.isEdit = null;
                    this.itemData.behaviours.splice(indexToEdit, 1, formBehaviour);
                }
            } else {
                this.itemData.behaviours.push(formBehaviour);
            }

            //refresh color
            this.refreshItemColor();

            //Item target
            this.refreshItemTarget();

            //refresh lifetime
            this.itemData.lifeTime.bean = null;

        },
        getMainBehaviour(){
            if (this.itemData.behaviours.length > 0){
                let mainBehaviour = this.itemData.behaviours[0];
                return mainBehaviour;
            } else {
                return null;
            }
        },
        refreshItemColor(){
            let mainBehaviour = this.getMainBehaviour();
            if (mainBehaviour){
                this.itemData.color = BehaviourUtils.getColorNameByCat(mainBehaviour.behaviourBean.cat);
            } else {
                this.itemData.color = null;
            }
        },
        refreshItemTarget(){
            let mainBehaviour = this.getMainBehaviour();
            if (mainBehaviour) {
                this.itemData.targetSelect = mainBehaviour.behaviourBean.owners;
                this.itemData.usageRules = mainBehaviour.behaviourBean.usageRules;


            }
        },
        refreshImpactReceivers(){
            if (this.itemData.behaviours.length > 0){
                this.itemData.behaviours.forEach(behaviour =>{
                    behaviour.gamePlayImpact.receivers = [];
                    behaviour.behaviourBean.receivers.forEach(rc => {
                        behaviour.gamePlayImpact.receivers.push(rc.value);
                    });
                });
            }
        },

        initializeItemData() {
            this.itemData = lodash.cloneDeep(this.pItemData);
            this.isNewItemForm = this.itemData.status === undefined || this.itemData.status === "new";

            this.validateIconNameMatchingBehaviours();
            this.backUpIconName = this.itemData.iconName;

            this.refreshItemTarget();
            this.refreshImpactReceivers();
            this.refreshSignLifeTimeDirect();
            this.validateItemFormDescription();

            this.lastItemStatus = this.itemData.status;
            if(this.lastItemStatus === "showListSibling"){
                this.showSibling();
            }
        },
        isUpdateSiblingForm(){
            return this.lastItemStatus === "updateSibling";
        },
        validateIconNameMatchingBehaviours(){
            let mainBehaviour = this.getMainBehaviour();
            if (mainBehaviour && mainBehaviour.behaviourBean && mainBehaviour.behaviourBean.join){
                let shouldCancel = false;
                if (this.itemData.category === ItemCategoryNames.potion){
                    if (!mainBehaviour.behaviourBean.join.includes('potion')){
                        shouldCancel = true;
                    }
                }else {
                    if (mainBehaviour.behaviourBean.join.includes('potion')){
                        shouldCancel = true
                    }
                }
                if (shouldCancel){
                    this.snackbar = true;
                    this.textSnackbar = "New icon not match with the behaviours! Please choose new icon that match or remove the behaviours";
                    this.colorSnackbar = 'red accent-2';
                    this.timeoutSnackbar = 6000;

                    if (this.backUpIconName) {
                        this.itemData.iconName = this.backUpIconName;
                        this.itemData.category = this.categoryByIconName(this.itemData.iconName);
                    }
                }
            }
        },

        validateItemFormDescription() {
            let description = "";

            //impact
            let behavArray = [];

            this.itemData.behaviours.forEach(behaviour => {
                if (behaviour.behaviourBean.des && behaviour.behaviourBean.des.details){
                    behavArray.push(behaviour.behaviourBean.des.details + " " + this.renderGameplayImpact(behaviour));
                }
            });

            let descBehav = this.getSplitDescriptionOnArray(behavArray);
            if (descBehav !== "") {
                description += descBehav;
            }

            //mode
            let descMode = this.getSplitDescriptionOnArray(this.itemData.modes);
            if (descMode !== "") {
                description += " use on " + descMode + " mode.";
            }

            //target
            if (this.itemData.targetSelect) {
                let descTarget = this.getOrSplitDescriptionOnArray(this.itemData.targetSelect);
                if (descTarget !== "") {
                    let isNewLine = this.description !== "";
                    if (isNewLine) {
                        description += "<br/>";
                    }
                    if(this.itemData.category === ItemCategoryNames.potion){
                        description += "It can been used by " + descTarget;
                    }else{
                        description += "It can been equipped on " + descTarget;
                    }
                }
            }

            //life time
            let descLife = this.getLifeTimeDescription();
            if (descLife !== "") {
                description += " and " + descLife + ".";
            } else {
                description += ".";
            }

            this.itemFormDescription = description;
        },
        getSplitDescriptionOnArray(array) {
            let desc = "";
            for (let i = 0; i < array.length; i++) {
                if (desc !== "") {
                    if (i === array.length - 1) {
                        desc += " and ";
                    } else {
                        desc += ", ";
                    }
                }
                desc += array[i];
            }
            return desc;
        },
        getOrSplitDescriptionOnArray(array) {
            let desc = "";
            for (let i = 0; i < array.length; i++) {
                if (desc !== "") {
                    desc += " or ";
                }
                desc += array[i];
            }
            return desc;
        },
        getLifeTimeDescription() {
            let desc = "";
            let lifeTime = this.itemData.lifeTime;
            if (lifeTime !== null) {
                if (lifeTime.type === "OneShot") {
                    desc = "can use one time"
                } else if (lifeTime.type === "DurationInSeconds") {
                    desc = "break in " + lifeTime.limit + " seconds";
                } else if (lifeTime.type === "NumOfTaps") {
                    desc = "break in " + lifeTime.limit + " taps";
                } else if (lifeTime.type === "NumOfGotHits") {
                    desc = "break in " + lifeTime.limit + " hits";
                } else if (lifeTime.type === "Forever") {
                    desc = "never broken";
                } else if (lifeTime.type === "NumOfImpacts") {
                    desc = "break after impact " + lifeTime.limit + " times";
                }
            }
            return desc;
        },
        rulesNotEmpty (fieldName) {
            return [
                v => !!v || fieldName + ' is required!'
            ];
        },
        categoryByIconName(name){
            //console.log("Finding category matching Icon name : " + name)
            let cat = ItemCategoryNames.weapon;
            let weaponId = "";

            let foundInWeapon = this.refEquipmentData.find(ref => ref.weaponName === name);
            if (foundInWeapon) {
                //console.log("Found in weapon.")
                if (name.includes('Group-18') || name.includes('Claw')){
                    cat = ItemCategoryNames.glove;
                } else {
                    cat = ItemCategoryNames.weapon;
                }
                weaponId = foundInWeapon.weaponId;
            }
            else {
                let foundInShield = this.refShieldData.find(ref => ref.weaponName === name);
                if (foundInShield) {

                    weaponId = foundInShield.weaponId;

                    switch(foundInShield.weapon3DPackType){
                        case 6:
                        case 9:
                            cat = ItemCategoryNames.shield;
                            console.log("Found in shield.")
                            break;

                        case 7:
                        case 10:
                            cat = ItemCategoryNames.ensign;
                            console.log("Found in ensign.")
                            break;

                        case 8:
                        case 11:
                            cat = ItemCategoryNames.lantern;
                            console.log("Found in lantern.")
                            break;
                    }
                }
                else {
                    if (name.includes('potion')
                    || name.includes('inventory_00')){
                        cat = ItemCategoryNames.potion;
                    } else if (name.includes('ring')){
                        cat = ItemCategoryNames.ring;
                    } else if (name.includes('spell')){
                        cat = ItemCategoryNames.scroll;
                    } else if (name.includes('Group-18') || name.includes('Claw')){
                        cat = ItemCategoryNames.glove;
                    } else if (name.includes('Group-4') || name.toLowerCase().includes('sh2d') || name.toLowerCase().includes('shield')){
                        cat = ItemCategoryNames.shield;
                    } else if (name.includes('ensign')){
                        cat = ItemCategoryNames.ensign;
                    } else if (name.includes('lantern')){
                        cat = ItemCategoryNames.lantern;
                    }

                    //console.log("Found in custom tried.")
                }
            }

            this.itemData.equipmentCode = weaponId.toString();
            //console.log("Equipment Code : " + this.itemData.equipmentCode);
            
            return cat;
        },

        initLevelCardItems() {
            this.optimizedList = [];
            let rowCount = 1;
            let tempRow = [];
            let levelStart = this.itemData.gameLevel.levelStart;
            let levelEnd = this.itemData.gameLevel.levelEnd;
            for (let i = levelStart; i < levelEnd; i++) {
                tempRow.push(this.getLevelCardData(i));
                if (rowCount === 5 || i === levelEnd) {
                    this.optimizedList.push(tempRow);
                    rowCount = 0;
                    tempRow = [];
                }
                rowCount++;
            }
        },

        getLevelCardData(lv){
            let beh = this.itemData.behaviours[0];
            let refBonus = this.siblingUtils.getBonusTextOnLevelOfImpact(this.itemData, beh, lv);
            let valueType = beh.gamePlayImpact.valueType;
            let levelData = {
                level: lv,
                bonus: refBonus.bonus,
                bonusType: valueType,
                base: refBonus.base,
                percentage: refBonus.percentage,
                baseType: refBonus.baseType,
            };

            if(this.itemData.behaviours.length > 1){
                levelData.joinLevelDatas = [];
                for(let index = 1;index<this.itemData.behaviours.length;index++){
                    let beh = this.itemData.behaviours[index];
                    let refBonus = this.siblingUtils.getBonusTextOnLevelOfImpact(this.itemData, beh, lv);
                    let valueType = beh.gamePlayImpact.valueType;
                    levelData.joinLevelDatas.push({
                        bonus: refBonus.bonus,
                        bonusType: valueType,
                        base: refBonus.base,
                        percentage: refBonus.percentage,
                        baseType: refBonus.baseType,
                    });
                }
            }

            return levelData;
        },

        getHeightSiblingItem(){
            if(this.itemData.behaviours.length > 1){
                return 190;
            }
            return 130;
        },
    },
    computed: {

        supportedHeroes () {
            let heroeIds = [];
            if (this.itemData.equipmentCode.length > 0){
                this.refHeroAndWeaponData.forEach(hd => {
                    let code = parseInt(this.itemData.equipmentCode);
                    if (hd.weaponIds.includes(code)){
                        heroeIds.push(hd.heroId);
                    }
                });
                return heroeIds;
            }else {
                return heroeIds;
            }
        },

        computeRefLifeTime () {
            let refLifeTimes = [];
            let mainBehave = this.getMainBehaviour();
            if (mainBehave){
                refLifeTimes = LifeTimeUtils.getLifeTimeShortcutList(mainBehave, false);
            }
            return refLifeTimes;
        },

        computeWindowHeight() {
            const height = window.innerHeight || document.documentElement.clientHeight ||
                document.body.clientHeight;
            return height * 0.8;
        },
        getFormTitle() {
            let title = "SIBLING";
            if (this.isNewItemForm) {
                title += " CREATION";
            } else {
                title += " DETAIL";
            }
            return title;
        },
        getPossibleBoarderIcon() {
            return this.itemData.color;
        },
        behaviourNeedTargetSelect(){
            return this.refTargetSelects.length > 1;
        },
        shouldShowModeSelect(){
            if(this.isDisableInput) return false;
            if(!this.itemData.targetSelect) return true;
            if (lodash.includes(this.itemData.targetSelect, ItemTargets.menu)) {
                this.clearModeSelect();
                return false;
            }
            return true;
        },
        getGenericListTitle(){
            let lvStart = this.itemData.gameLevel.levelStart;
            let lvEnd = this.itemData.gameLevel.levelEnd;
            return `List of Generic from level ${lvStart} to level ${lvEnd}`;
        },
    },
    created() {
        this.siblingUtils = this.pSiblingUtils;
        this.initializeItemData();
        this.initLevelCardItems();
    },
    watch: {
        pItemData() {
            this.initializeItemData();
            this.itemData.category = this.categoryByIconName(this.itemData.iconName);
        }
    },
    async beforeMount() {
        this.initDefaultFormBehavior();
        this.refEquipmentData = await EquipmentDataLoader.loadData();
        this.refHeroAndWeaponData = await HeroAndWeaponDataLoader.loadData();
        this.refShieldData = await ShieldDataLoader.loadData();
        this.itemData.category = this.categoryByIconName(this.itemData.iconName);
    }
}
</script>

<style scoped>
   #helpInfo .col { max-width: 11%; }
   #helpInfo .v-input--selection-controls { margin-top: 0;}
</style>