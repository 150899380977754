<template>
  <v-app>
    <ItemEditorList/>

    <SmpDialog
            :should-show="this.showAddNewItem"
            p-ignore-close
            @close="showAddNewItem=false"
    >
      <NewItemForm
              v-if="this.showAddNewItem"
              :pItemGroupData="this.itemDataForItemForm"
              :pRenderId="this.renderId"
              @close="showAddNewItem=false" />
    </SmpDialog>

    <SmpDialog
            :should-show="this.showAutomateItem"
            p-ignore-close
            @close="showAutomateItem=false"
    >
      <AutomateItem
              v-if="this.showAutomateItem"
              @close="showAutomateItem=false" />
    </SmpDialog>

    <SmpDialog
        :should-show="this.showItemIconList"
        width="80%"
        @close="showItemIconList=false"
    >
      <ItemIconList
              v-if="this.showItemIconList"
              :pCurrentName="this.currentIconName"
      >
      </ItemIconList>
    </SmpDialog>

    <v-footer app>
      <!-- -->

      <v-row>

        <v-col align="right">
          <v-btn color="green darken-1" text @click="dispatchIconList()">Icon List</v-btn>
        </v-col>

        <v-col align="right">
          <v-btn color="red darken-1" text @click="dispatchNewItem()">New</v-btn>
        </v-col>

        <v-col align="right">
          <v-btn color="purple darken-1" text @click="dispatchAutomateItem()">Automate</v-btn>
        </v-col>

        <v-col>
          <DownloadUserGuid
                  :pdfUrl=pdfUrl
                  :pdfFileName=pdfFileName
          />
        </v-col>

        <v-col align="right">
          <router-link style="text-decoration: none; color: inherit;"
                       to='/'>
            <v-icon
                color=red>
              mdi-exit-to-app
            </v-icon>
          </router-link>
        </v-col>

      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import ItemEditorList from "../modules/ItemEditorList";
import SmpDialog from "../modules/SmpDialog.vue";
import ItemIconList from '../modules/ItemIconList.vue';
import {GlobalEvents,EVENT_TO_CHANGE_ICON_ITEM, EVENT_TO_UPDATE_ITEM, EVENT_TO_OPEN_ITEM_FORM, EVENT_TO_UPDATE_AUTOMATE_ITEM_FORM} from "@/item-editor/events/GlobalEvents";
import NewItemForm from "@/item-editor/modules/NewItemForm";
import GamePlayGroupItem from "@/item-editor/classes/GamePlayGroupItem";
import AutomateItem from "../modules/AutomateItem/screens/AutomateItem";
import DownloadUserGuid from "@/unity-service-dashboard/modules/DownloadUserGuid";

export default {
  name: "ItemEditor.vue",
  data() {
    return {
      showAddNewItem: false,
      showItemIconList: false,
      itemIconList: [],
      itemDataForItemForm: null,
      itemDataFromChangeIcon: null,
      currentIconName: '',
      showAutomateItem: false,
      renderId: '',

      pdfUrl: 'https://prod.hokmok.com/uploads/Front_End_Automate_Generate_Item_Guid_2f98494c19.pdf',//'/path/to/my/file.pdf',
      pdfFileName: 'Front_End_Automate_Generate_Item_Guid.pdf'
    }
  },

  components: {
    'ItemEditorList': ItemEditorList,
    'SmpDialog': SmpDialog,
    'ItemIconList': ItemIconList,
    'NewItemForm': NewItemForm,
    'AutomateItem': AutomateItem,
    DownloadUserGuid
  },
  methods: {
    dispatchNewItem() {
      this.itemDataForItemForm = this.getItemNewDataByIconData({
        name: "Wp_2001.png",//"sword_02.png",
        type: "sword",
        category: "weapon",
      });
      this.showAddNewItem = true;
    },
    dispatchAutomateItem() {
      this.showAutomateItem = true;
    },
    dispatchIconList() {
      this.showItemIconList = true;
    },
    toChangeItemIcon: function (itemGroupData) {
      //console.log("Change icon on root " + item.iconName);
      this.itemDataFromChangeIcon = itemGroupData;
      this.currentIconName = this.itemDataFromChangeIcon.item.iconName;
      this.showItemIconList = true;
    },
    toUpdateItemForm: function (itemGroupData) {
      this.itemDataForItemForm = itemGroupData;
      this.renderId = '';
      this.showAddNewItem = true;
    },
    toUpdateAutomateItemForm: function (itemGroupData, renderId) {
      this.itemDataForItemForm = itemGroupData;
      this.renderId = renderId;
      this.showAddNewItem = true;
    },
    onOpenItemForm: function (iconData) {
      if (this.showAddNewItem && this.itemDataFromChangeIcon !== null) {
        this.itemDataForItemForm = this.itemDataFromChangeIcon;
        this.itemDataForItemForm.items[0].iconName = this.getCleanIconName(iconData.name);
        this.itemDataForItemForm.items[0].category = iconData.category;
        this.itemDataFromChangeIcon = null;
      } else {
        this.itemDataForItemForm = this.getItemNewDataByIconData(iconData);
        this.showAddNewItem = true;
      }
      this.showItemIconList = false;
    },
    getItemNewDataByIconData(iconData) {
      let iconNameClean = iconData.name;
      if (iconNameClean.includes(".png")) {
        iconNameClean = iconNameClean.substring(0, iconNameClean.length - 4)
      }
      let newItemData = new GamePlayGroupItem({},{
        iconName: iconNameClean,
        category: iconData.category,
        status: "new",
      });
      return newItemData;
    },
    getCleanIconName(iconName) {
      let iconNameClean = iconName;
      if (iconNameClean.includes(".png")) {
        iconNameClean = iconNameClean.substring(0, iconNameClean.length - 4)
      }
      return iconNameClean;
    }
  },
  created() {
      GlobalEvents.$on(EVENT_TO_CHANGE_ICON_ITEM, (itemGroupData) => this.toChangeItemIcon(itemGroupData));
      GlobalEvents.$on(EVENT_TO_UPDATE_ITEM, (itemGroupData) => this.toUpdateItemForm(itemGroupData));
      GlobalEvents.$on(EVENT_TO_OPEN_ITEM_FORM, (iconData) => this.onOpenItemForm(iconData));
      GlobalEvents.$on(EVENT_TO_UPDATE_AUTOMATE_ITEM_FORM, (itemGroupData, renderId) => this.toUpdateAutomateItemForm(itemGroupData, renderId));
  }
}
</script>

<style scoped>

</style>