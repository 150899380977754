<template>
  <v-card>
    <v-card-title>Bag Slot Config for Automate Generation</v-card-title>
    <v-row>
        <v-card elevation="0" class="pa-2 mx-10" min-height="700">
            <v-row>
              <v-col cols="0" sm="0" md="0">
                  <v-data-table
                          :headers="headers"
                          :items="pBagSlotConfig.lstGeneric"
                          :options="{itemsPerPage:5}"
                          v-model="selected"
                          item-key="id"
                          show-select
                  >
                      <template v-slot:item.startLevel="{item}">
                          <AutomateConfigDataInputNumber :pItem="item" pKey="startLevel" :pIsEditable="true" />
                      </template>
                      <template v-slot:item.endLevel="{item}">
                          <AutomateConfigDataInputNumber :pItem="item" pKey="endLevel" :pIsEditable="true" />
                      </template>
                      <template v-slot:item.minBonus="{item}">
                          <AutomateConfigDataInputNumber :pItem="item" pKey="minBonus" :pIsEditable="true" />
                      </template>
                  </v-data-table>
                  <div>
                      <v-btn color="red" @click="deleteRow">Delete Selected</v-btn>
                  </div>
              </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-1">
                    <v-card max-width="450"
                            elevation="0">
                        <v-card-title>Add more bag slot</v-card-title>
                        <v-text-field
                                label="Start Level"
                                v-model="bagSlotAdd.startLevel"
                                type="number"
                        />
                        <v-text-field
                                label="End Level"
                                v-model="bagSlotAdd.endLevel"
                                type="number"
                        />
                        <v-text-field
                                label="Slot"
                                v-model="bagSlotAdd.slot"
                                type="number"
                        />
                        <div>
                            <v-btn color="green" @click="addBagSlot">Add</v-btn>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-row>
  </v-card>
</template>

<script>
    import AutomateConfigDataInputNumber from "./AutomateConfigDataInputNumber";
    import AutomateBagSlotPropsModel from "../class/AutomateBagSlotPropsModel";

  export default {
    name: "BagSlotOptionPopup",
    props: ["pBagSlotConfig"],
    components: {
        AutomateConfigDataInputNumber
    },
    data() {
      return {
          selected: [],
          headers: [
              {text: 'StartLevel', value: 'startLevel', width: 170},
              {text: 'EndLevel', value: 'endLevel', width: 170},
              {text: 'Slot', value: 'minBonus'},
          ],
          bagSlotAdd:{
              startLevel: 100,
              endLevel: 10000,
              slot: 1
          }
      }
    },
    methods:{
        deleteRow () {
            if (confirm('Are you sure you want to delete this item?')) {
                for (let i = 0; i < this.selected.length; i++) {
                    const index = this.pBagSlotConfig.lstGeneric.indexOf(this.selected[i]);
                    this.pBagSlotConfig.lstGeneric.splice(index, 1);
                }

                //update new id
                let count = this.pBagSlotConfig.lstGeneric.length;
                for(let i=0;i<count;i++){
                    this.pBagSlotConfig.lstGeneric[i].id = i+1;
                }

                this.selected = [];
                this.refreshGenerateCount();
            }
        },
        addBagSlot(){
            let slot = new AutomateBagSlotPropsModel();
            slot.id = this.pBagSlotConfig.lstGeneric.length + 1;
            slot.startLevel = this.bagSlotAdd.startLevel;
            slot.endLevel = this.bagSlotAdd.endLevel;
            slot.minBonus = this.bagSlotAdd.slot;
            slot.maxBonus = this.bagSlotAdd.slot;
            this.pBagSlotConfig.lstGeneric.push(slot);
            this.refreshGenerateCount();
        },
        refreshGenerateCount(){
            let count = this.pBagSlotConfig.lstGeneric.length;
            this.pBagSlotConfig.genItemCount = count;
            this.pBagSlotConfig.itemRootCount = count;
        },
    },
  }
</script>

<style scoped>

</style>