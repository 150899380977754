<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-card elevation="0" min-width="1000" light>
        <HeaderPopup
                @close="close()"
                :pCanSave="false"
        >
            SIBLING PREVIEW
        </HeaderPopup>

        <v-container class="grey lighten-5">
            <v-virtual-scroll
                    :item-height="getHeightSiblingItem()"
                    :height="computeWindowHeight"
                    :items="this.optimizedList"
            >
                <template v-slot:default="{ item }">
                    <v-row v-if="item.length > 0" dense no-gutters class="mt-3">
                        <v-col
                                :cols=1
                                v-for="it in item"
                                :key="it.renderId"
                                class="mx-16"
                        >
                            <SiblingCardItem :pItemData="it" :pLevelCardData="getLevelCardData(it)"></SiblingCardItem>
                        </v-col>
                    </v-row>
                </template>
            </v-virtual-scroll>
        </v-container>
        <SiblingItemUpdateForm :pSiblingUtils="this.siblingUtils" />
    </v-card>
</template>

<script>
    import HeaderPopup from "../../HeaderPopupComponent.vue";
    import SiblingCardItem from "../components/SiblingCardItem.vue";
    import SiblingItemUpdateForm from "../components/SiblingItemUpdateForm";
    import SiblingItemMultipleImpactUtils from "../../../Utils/SiblingItemMultipleImpactUtils";
    import UGSItemUtils from "../../../Utils/UGSItemUtils";
    import SMPNum from "@/SMPNum";
    import SMPItemImpactBatchHelperUtils from '@/kpi-editor/Utils/SMPItemImpactBatchHelperUtils';
    import GamePlayImpactType from "@/item-editor/constants/GamePlayImpactType";

    export default {
        name: "SiblingItemPreviewForm.vue",
        props: {
            'pItemGroupData':{
                type: Object,
                required: true
            },
        },
        components: {
            HeaderPopup,
            SiblingCardItem,
            SiblingItemUpdateForm,
        },
        data(){
            return {
                optimizedList:[],
                itemGroupData:null,
                snackbar: false,
                textSnackbar: '',
                colorSnackbar: 'success',
                timeoutSnackbar: 2000,
                isLoading: false,
                siblingUtils:null,
                gamePlayDataService: null,
                siblingItemsData: [],
            }
        },
        methods:{
            close(){
                this.$emit('close');
            },
            getHeightSiblingItem(){
                if(this.itemData && this.itemData.behaviours.length > 1){
                    return 330;
                }
                return 270;
            },
            getLevelCardData(item){
                //define cost
                let levelConfig = item.levelConfig;
                let lv = item.level;
                let goldDrop = this.gamePlayDataService.getCoinDrop(levelConfig.kpiLevel).round();
                let smpGem = UGSItemUtils.getSmpGemUnit(this.itemGroupData.config);
                let cost = SMPNum.multSmpNum(goldDrop, new SMPNum(smpGem));

                let beh = item.behaviours[0];
                let refBonus = {};
                let levelData = {
                    level: lv,
                    bonus: this.getValueBonus(beh),
                    bonusType: beh.gamePlayImpact.valueType,
                    base: beh.behaviourBean.des.title,
                    percentage: refBonus.percentage,
                    baseType: 'Type',
                    costInGold: cost,
                };

                if(item.behaviours.length > 1){
                    levelData.joinLevelDatas = [];
                    for(let index = 1;index<item.behaviours.length;index++){
                        let beh = item.behaviours[index];
                        let refBonus = {};
                        levelData.joinLevelDatas.push({
                            bonus: this.getValueBonus(beh),
                            bonusType: beh.gamePlayImpact.valueType,
                            base: beh.behaviourBean.des.title,
                            percentage: refBonus.percentage,
                            baseType: refBonus.baseType,
                            costInGold: cost,
                        });
                    }
                }

                return levelData;
            },

            getValueBonus(behaviour){
                let des = "";
                let bonus = behaviour.gamePlayImpact.value;

                let bean = behaviour.gamePlayImpact.bean;
                if(bean !== null && bean.valueType !== null && bean.valueType.value === "DirectDisplayValue"){
                    des += bean.valueType.text;
                } else if(behaviour.gamePlayImpact.valueType === "DirectValue"){
                    des = SMPNum.fromPowerString(bonus).ToReadableAlphabetV2();
                } else if(behaviour.gamePlayImpact.valueType === 'Percentage'){
                    des = bonus.toString();
                } else {
                    des = new SMPNum(bonus).ToReadableAlphabetV2();
                }
                des += " "
                    + GamePlayImpactType.valueTypeSymbols[behaviour.gamePlayImpact.valueType];
                return des;
            },

            initSiblingUtil(){
                this.gamePlayDataService = this.$store.state.gamePlayDataService;
                this.siblingUtils = new SiblingItemMultipleImpactUtils(this.gamePlayDataService);
                this.itemImpactBatchHelperUtils = new SMPItemImpactBatchHelperUtils(this.gamePlayDataService);
            },
            initSiblingItemCard: function (waitTime) {
                this.optimizedList = [];
                setTimeout(() => this.waitToShowList(this.siblingItemsData), waitTime);
            },

            waitToShowList(toDisplay){
                let rowCount = 1;
                let tempRow = [];
                for (let i = 0; i < toDisplay.length; i++) {
                    tempRow.push(toDisplay[i]);
                    if (rowCount === 4 || i === toDisplay.length - 1) {
                        this.optimizedList.push(tempRow);
                        rowCount = 0;
                        tempRow = [];
                    }
                    rowCount++;
                }
            },
            applyBatchImpactDirectValue() {
                this.siblingItemsData.forEach(data => {
                    //Apply batch impact direct value
                    data.behaviours.forEach(behaviour => {
                        this.itemImpactBatchHelperUtils.ApplyBatchImpactDirectValue(behaviour, data.gameLevel.levelStart);
                    });
                });
            },
        },
        computed:{
            computeWindowHeight() {
                const height = window.innerHeight || document.documentElement.clientHeight ||
                    document.body.clientHeight;
                return height * 0.55;
            },
        },
        created() {
            this.initSiblingUtil();

            this.itemGroupData = this.pItemGroupData;
            this.itemData = this.itemGroupData.item;
            this.siblingItemsData = UGSItemUtils.getSiblingItems(this.itemGroupData, this.itemGroupData.item, this.itemGroupData.item.gameLevel.levelStart);
            this.applyBatchImpactDirectValue();

            this.initSiblingItemCard();
        }
    }
</script>

<style scoped>

</style>