<template>
    <v-card
            class="mt-2 mb-2 ml-2 mr-2"
            elevation="5"
            min-width="200"
            max-width="200"
            min-height="260"
            max-height="260"
            @click="this.dispatchClick"
    >
        <v-row justify="center"
               class="spacing-playground pa-2"
        >
            <v-badge
                    offset-x="40"
                    offset-y="20"
                    :content="getCreatedStatus()"
            >
                  <v-img
                          :src="getBoarderIcon()"
                          max-width="90"
                  >
                      <v-row class="fill-height">
                          <v-row justify="center">
                              <v-card-title>
                                  <v-img
                                          :src="getItemIcon()"
                                          max-width="60px"
                                          max-height="60px"
                                          dark
                                          class="mt-3"
                                  >
                                  </v-img>
                              </v-card-title>
                          </v-row>
                      </v-row>

                  </v-img>
            </v-badge>
        </v-row>
        <div v-html="getItemTitleName"></div>
    </v-card>
</template>

<script>
    import ItemColorConstants from "../constants/ItemColorConstants.js";
    import GamePlayGroupItem from "@/item-editor/classes/GamePlayGroupItem";
    import {EVENT_TO_UPDATE_ITEM, GlobalEvents} from "@/item-editor/events/GlobalEvents";
    import { useTimeAgo } from '@vueuse/core'

    export default {
        name: "ItemEditorCardItem.vue",
        props: ["pItemGroupData","pFilterData"],
        data() {
            return {
                itemGroupData: null,
                itemData: null,
                siblingConfig: null,
                isSiblingClicked: false,
                timeAgo: null,
            }
        },
        created() {
            this.itemGroupData = this.pItemGroupData;
            this.itemData = this.pItemGroupData.item;
            this.siblingConfig = this.pItemGroupData.config;

            this.timeAgo = useTimeAgo(this.itemData.createdAt, { updateInterval: 30_000 });//// Update every half minute
        },
        methods: {
            getBoarderColor() {
                return ItemColorConstants.colors[this.itemData.color];
            },
            getCreatedStatus() {
                return this.timeAgo.value;
            },
            getBoarderIcon() {
                let color = this.itemData.color;
                if (!this.itemData.color) {
                    color = 'blue';
                }
                let iconName = ItemColorConstants.iconColorNames[color];
                return require('../assets/icons/itemboarders/' + iconName);
            },
            getItemIcon() {
                let iconName = this.itemData.iconName + ".png";
                let result = null;
                try {
                    result = require('../assets/weapons/textures/' + iconName);
                } catch(e){
                    console.log("Not found image name : " + iconName);
                    result = require('../assets/weapons/no-image.png');
                }

                return result;
                
            },
            siblingClick(){
                this.isSiblingClicked = true;
            },
            dispatchClick() {
                //this.showUpdateItem = true;
                GlobalEvents.$emit(EVENT_TO_UPDATE_ITEM, this.getItemDataForUpdate());
            },
            getItemDataForUpdate() {
                let groupItem = new GamePlayGroupItem(this.itemGroupData, this.itemData);
                let itemToEdit = groupItem.item;
                if(this.isSiblingClicked){
                    itemToEdit.status = "showListSibling";
                    this.isSiblingClicked = false;
                } else{
                    itemToEdit.status = "update";
                }
                return groupItem;
            },
            getSiblingCounter(){
                let siblingCounter = this.itemGroupData.itemCount;
                return "Sibling:"+ siblingCounter;
            },
            isPushState(){
                return this.itemData.pushStatus && this.itemData.pushStatus.length>0;
            }
        },
        computed: {
            getItemTitleName() {
                let title = this.itemData.title;
                let text = '';
                if (title !== undefined) {
                    text = '<br/><h4 style="text-align:center;">'+title.en+'</h4>';
                }
                if(this.pFilterData){
                    text+= '<div style="text-align:center;">Start GL: '+this.pFilterData.startGameLevel+'</div>';
                    text+= '<div style="text-align:center;">End GL: '+this.pFilterData.endGameLevel+'</div>';
                    text+= '<div style="text-align:center;">StartCost: '+this.pFilterData.cost+'</div>';
                    if(this.isPushState()){
                        let statusText = '';
                        this.itemData.pushStatus.forEach(status =>{
                            let env = this.$store.state.ugsSetting.environmentList.find(e => e.id === status);
                            if(statusText === ''){
                                statusText += env.name;
                            } else {
                                statusText += ', '+ env.name;
                            }
                        });
                        text+= '<div style="text-align:center;">Status: '+statusText+'</div>';
                    }
                }
                return text;
            },
        },
    }
</script>

<style scoped>
    .v-list--two-line .v-list-item,
    .v-list-item--two-line {
        min-height: 30px;
        height:30px;
    }
</style>