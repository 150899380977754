import UGSItemUtils from "../Utils/UGSItemUtils";

class UGSVirtualPurchaseModel {
    constructor(itemGroup, itemInventory){
        let item = itemGroup.item;
        this.name = item.title.en+' Shop';
        if(item.id !== undefined){
            this.id = item.id.toUpperCase()+"_SHOP";//this.name.toUpperCase().replace(/\s/g, '_');
        } else {
            this.id = undefined;
            console.log('id not exit on Shop name '+this.name);
        }
        this.type = 'VIRTUAL_PURCHASE';

        let smpGemCost = UGSItemUtils.getSmpGemUnit(itemGroup.config);

        this.costs = [
            {
                "resourceId": "CURRENCY_SMP_GEM_ID",
                "amount": smpGemCost
            }
        ];
        this.rewards = [
            {
                "resourceId": itemInventory.id,
                "amount": 1
            }
        ];
    }
}

export default UGSVirtualPurchaseModel;