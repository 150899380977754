import ItemTargets from "@/item-editor/constants/ItemTargets";
import ItemReceivers from "@/item-editor/constants/ItemReceivers";
import {BehaviourByNames} from "@/item-editor/constants/BehaviourTypes";
import EffectSlots from "@/item-editor/constants/EffectSlots";
import BehaviourCategories from "@/item-editor/constants/BehaviourCategories";
import ItemUsageRules from "@/item-editor/constants/ItemUsageRules";

export default [
     //inc-speed-support
     {
          type : BehaviourByNames.incSpeedSupport,
          cat : BehaviourCategories.attack,
          join : "equipment",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.allActiveSupports],
          effectSlots : [EffectSlots.support],
          des: {
               title: 'Increase speed',
               details : "Increase speed for all active supports",
               full_des: "Increase speed by percentage for all active supports when hero equip item.",
               details_km: "បង្កើនល្បឿនអ្នកគាំទ្រសកម្ម",
          }
     },
     {
          type : BehaviourByNames.incSpeedSupport,
          cat : BehaviourCategories.attack,
          join : "potion-team",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.allActiveSupports],
          effectSlots : [EffectSlots.support],
          des: {
               title: 'Increase speed',
               details : "Increase speed for all active supports",
               full_des: "Increase speed by percentage for all active supports when use item.",
               details_km: "បង្កើនល្បឿនអ្នកគាំទ្រសកម្ម",
          }
     },
     {
          type : BehaviourByNames.incSpeedSupport,
          cat : BehaviourCategories.attack,
          join : "potion-support",
          owners : [ItemTargets.support],
          receivers : [ItemReceivers.selectedActiveSupport],
          effectSlots : [EffectSlots.support],
          des: {
               title: 'Increase speed',
               details : "Increase speed for a single selected support",
               full_des: "Increase speed by percentage for a single support who use item.",
               details_km: "បង្កើនល្បឿនអ្នកគាំទ្រ",
          }
     },

     //fill_hp
     {
          type : BehaviourByNames.fillHp,
          cat : BehaviourCategories.health,
          join : "equipment-hp-hero",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Refill hp',
               details : "Refill hp to selected hero",
               full_des: "Refill hp by percentage to hero who equip item.",
               details_km: "បញ្ចូលឈាមវិរជន",
          }
     },
     {
          type : BehaviourByNames.fillHp,
          cat : BehaviourCategories.health,
          join : "equipment-hp-supports",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.allActiveSupports],
          effectSlots : [EffectSlots.support],
          des: {
               title: 'Refill hp',
               details : "Refill hp to all active supports",
               full_des: "Refill hp by percentage to all active supports when hero equip item.",
               details_km: "បញ្ចូលឈាមអ្នកគាំទ្រសកម្ម",
          }
     },
     {
          type : BehaviourByNames.fillHp,
          cat : BehaviourCategories.health,
          join : "equipment-hp-team",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero, ItemReceivers.allActiveSupports],
          effectSlots : [EffectSlots.hero, EffectSlots.support],
          des: {
               title: 'Refill hp',
               details : "Refill hp to the team",
               full_des: "Refill hp by percentage to the team when hero equip item.",
               details_km: "បញ្ចូលឈាមក្រុមទាំងមូល",
          }
     },
     {
          type : BehaviourByNames.fillHp,
          cat : BehaviourCategories.health,
          join : "potion-hp-hero",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          usageRules: [ItemUsageRules.OneToOneTarget, ItemUsageRules.ManyToOneTarget],
          des: {
               title: 'Refill hp',
               details : "Refill hp to selected hero",
               full_des: "Refill hp by percentage to hero who use item.",
               details_km: "បញ្ចូលឈាមវិរជន",
          }
     },
     {
          type : BehaviourByNames.fillHp,
          cat : BehaviourCategories.health,
          join : "potion-hp-support",
          owners : [ItemTargets.support],
          receivers : [ItemReceivers.selectedSupport],
          effectSlots : [EffectSlots.support],
          usageRules: [ItemUsageRules.OneToOneTarget, ItemUsageRules.ManyToOneTarget],
          des: {
               title: 'Refill hp',
               details : "Refill hp to selected support",
               full_des: "Refill hp by percentage to support who use item.",
               details_km: "បញ្ចូលឈាមអ្នកគាំទ្រ",
          }
     },
     {
          type : BehaviourByNames.fillHp,
          cat : BehaviourCategories.health,
          join : "potion-hp-team",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.selectedHero, ItemReceivers.allActiveSupports],
          effectSlots : [EffectSlots.hero, EffectSlots.support],
          des: {
               title: 'Refill hp (and)',
               details : "Refill hp to the team",
               full_des: "Refill hp by percentage to team when use item.",
               details_km: "បញ្ចូលឈាមក្រុម",
          }
     },
     {
          type : BehaviourByNames.fillHp,
          cat : BehaviourCategories.health,
          join : "potion-hp-hero-or-support",
          owners : [ItemTargets.hero, ItemTargets.support],
          receivers : [ItemReceivers.selectedHero, ItemReceivers.selectedSupport],
          effectSlots : [EffectSlots.hero, EffectSlots.support],
          usageRules: [ItemUsageRules.OneToOneTarget, ItemUsageRules.ManyToOneTarget],
          effectRelation: 'or',
          des: {
               title: 'Refill hp (or)',
               details : "Refill hp to selected hero or support",
               full_des: "Refill hp by percentage to hero or support who use item.",
               details_km: "បញ្ចូលឈាម",
          }
     },
     {
          type : BehaviourByNames.fillHp,
          cat : BehaviourCategories.health,
          join : "potion-hp-all-active-supports",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.allActiveSupports],
          effectSlots : [EffectSlots.support],
          des: {
               title: 'Refill hp',
               details : "Refill hp to all active supports",
               full_des: "Refill hp by percentage to all active supports when use item.",
               details_km: "បញ្ចូលឈាមអ្នកគាំទ្រសកម្មទាំងអស់",
          }
     },
     {
          type : BehaviourByNames.fillHp,
          cat : BehaviourCategories.health,
          join : "potion-hp-all-supports",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.allSupports],
          effectSlots : [EffectSlots.support],
          des: {
               title: 'Refill hp',
               details : "Refill hp to all supports",
               full_des: "Refill hp by percentage to all supports when use item.",
               details_km: "បញ្ចូលឈាមអ្នកគាំទ្រទាំងអស់",
          }
     },

     //inc_dps
     {
          type : BehaviourByNames.incDps,
          cat : BehaviourCategories.attack,
          join : "equipment-dps-team",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.team],
          effectSlots : [EffectSlots.hero, EffectSlots.support],
          des: {
               title: 'Increase dps',
               details : "Increase global dps to Team",
               full_des: "Increase global dps by percentage to team when hero equip item.",
               details_km: "បង្កើនកម្លាំងប្រយុទ្ធ",
          }
     },
     {
          type : BehaviourByNames.incDps,
          cat : BehaviourCategories.attack,
          join : "potion-dps-team",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.team],
          effectSlots : [EffectSlots.hero, EffectSlots.support],
          usageRules: [ItemUsageRules.OneToOneTarget, ItemUsageRules.ManyToOneTarget],
          des: {
               title: 'Increase dps',
               details : "Increase global dps to Team",
               full_des: "Increase global dps by percentage to team when use item.",
               details_km: "បង្កើនកម្លាំងប្រយុទ្ធ",
          }
     },

     //cure_freeze
     {
          type : BehaviourByNames.cureFreeze,
          cat : BehaviourCategories.defense,
          join : "equipment-cure-freeze-hero",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Cure freeze',
               details : "Protect Hero from being freeze",
               full_des: "Protect Hero from being freeze to hero who equip item.",
               details_km: "ព្យាបាលកកអោយវីរជន",
          }
     },
     {
          type : BehaviourByNames.cureFreeze,
          cat : BehaviourCategories.defense,
          join : "equipment-cure-freeze-team",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.team],
          effectSlots : [EffectSlots.hero, EffectSlots.support],
          effectRelation: 'and',
          des: {
               title: 'Cure freeze (and)',
               details : "Protect Team from being freeze",
               full_des: "Protect Team from being freeze when team equip item.",
               details_km: "ព្យាបាលកកអោយក្រុម",
          }
     },
     {
          type : BehaviourByNames.cureFreeze,
          cat : BehaviourCategories.defense,
          join : "potion-cure-freeze-team",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.team],
          effectSlots : [EffectSlots.hero, EffectSlots.support],
          effectRelation: 'and',
          des: {
               title: 'Cure freeze (and)',
               details : "Protect Team from being freeze",
               full_des: "Protect Team from being freeze when use item.",
               details_km: "ព្យាបាលកកអោយក្រុម",
          }
     },
     {
          type : BehaviourByNames.cureFreeze,
          cat : BehaviourCategories.defense,
          join : "potion-cure-freeze-all-active-supports",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.allActiveSupports],
          effectSlots : [EffectSlots.support],
          des: {
               title: 'Cure freeze',
               details : "Protect All active supports from being freeze",
               full_des: "Protect All active supports from being freeze when use item.",
               details_km: "ព្យាបាលកកអោយអ្នកគាំទ្រទាំងអស់",
          }
     },
     {
          type : BehaviourByNames.cureFreeze,
          cat : BehaviourCategories.defense,
          join : "potion-cure-freeze-hero-or-support",
          owners : [ItemTargets.hero, ItemTargets.support],
          receivers : [ItemReceivers.selectedHero, ItemReceivers.selectedActiveSupport],
          effectSlots : [EffectSlots.hero, EffectSlots.support],
          effectRelation: 'or',
          des: {
               title: 'Cure freeze (or)',
               details : "Protect Hero or Support from being freeze",
               full_des: "Protect Hero or Support from being freeze who use item.",
               details_km: "ព្យាបាលកក",
          }
     },
     {
          type : BehaviourByNames.cureFreeze,
          cat : BehaviourCategories.defense,
          join : "potion-cure-freeze-hero",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Cure freeze',
               details : "Protect Hero from being freeze",
               full_des: "Protect Hero from being freeze to hero who use item.",
               details_km: "ព្យាបាលកកអោយវិរជន",
          }
     },
     {
          type : BehaviourByNames.cureFreeze,
          cat : BehaviourCategories.defense,
          join : "potion-cure-freeze-support",
          owners : [ItemTargets.support],
          receivers : [ItemReceivers.selectedActiveSupport],
          effectSlots : [EffectSlots.support],
          des: {
               title: 'Cure freeze',
               details : "Protect Support from being freeze",
               full_des: "Protect Support from being freeze to support who use item.",
               details_km: "ព្យាបាលកកអោយអ្នកគាំទ្រស",
          }
     },

     //cure_poison
     {
          type : BehaviourByNames.curePoison,
          cat : BehaviourCategories.defense,
          join : "equipment-cure-poison-hero",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Cure poison',
               details : "Protect Hero from being poison",
               full_des: "Protect Hero from being poison to hero who equip item.",
               details_km: "បន្សាបជាតិពុលអោយវិរជន",
          }
     },
     {
          type : BehaviourByNames.curePoison,
          cat : BehaviourCategories.defense,
          join : "equipment-cure-poison-team",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.team],
          effectSlots : [EffectSlots.hero, EffectSlots.support],
          effectRelation: 'and',
          des: {
               title: 'Cure poison (and)',
               details : "Protect Team from being poison",
               full_des: "Protect Team from being poison when team equip item.",
               details_km: "បន្សាបជាតិពុលអោយក្រុម",
          }
     },
     {
          type : BehaviourByNames.curePoison,
          cat : BehaviourCategories.defense,
          join : "potion-cure-poison-team",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.team],
          effectSlots : [EffectSlots.hero, EffectSlots.support],
          effectRelation: 'and',
          des: {
               title: 'Cure poison (and)',
               details : "Protect Team from being poison",
               full_des: "Protect Team from being poison when use item.",
               details_km: "បន្សាបជាតិពុលក្រុម",
          }
     },
     {
          type : BehaviourByNames.curePoison,
          cat : BehaviourCategories.defense,
          join : "potion-cure-poison-all-active-supports",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.allActiveSupports],
          effectSlots : [EffectSlots.support],
          des: {
               title: 'Cure poison',
               details : "Protect All active supports from being poison",
               full_des: "Protect All active supports from being poison when use item.",
               details_km: "បន្សាបជាតិពុលអ្នកគាំទ្រសកម្ម",
          }
     },
     {
          type : BehaviourByNames.curePoison,
          cat : BehaviourCategories.defense,
          join : "potion-cure-poison-hero-or-support",
          owners : [ItemTargets.hero, ItemTargets.support],
          receivers : [ItemReceivers.selectedHero, ItemReceivers.selectedSupport],
          effectSlots : [EffectSlots.hero, EffectSlots.support],
          effectRelation: 'or',
          des: {
               title: 'Cure poison (or)',
               details : "Protect Hero or Support from being poison",
               full_des: "Protect Hero or Support from being poison to who use item.",
               details_km: "បន្សាបជាតិពុល",
          }
     },
     {
          type : BehaviourByNames.curePoison,
          cat : BehaviourCategories.defense,
          join : "potion-cure-poison-hero",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Cure poison',
               details : "Protect Hero from being poison",
               full_des: "Protect Hero from being poison to hero who use item.",
               details_km: "បន្សាបជាតិពុលអោយវិរជន",
          }
     },
     {
          type : BehaviourByNames.curePoison,
          cat : BehaviourCategories.defense,
          join : "potion-cure-poison-support",
          owners : [ItemTargets.support],
          receivers : [ItemReceivers.selectedSupport],
          effectSlots : [EffectSlots.support],
          des: {
               title: 'Cure poison',
               details : "Protect Support from being poison",
               full_des: "Protect Support from being poison to support who use item.",
               details_km: "បន្សាបជាតិពុលអ្នកគាំទ្រ",
          }
     },

     //cure_sheep
     {
          type : BehaviourByNames.cureSheep,
          cat : BehaviourCategories.defense,
          join : "equipment-cure-sheep-hero",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Cure sheep',
               details : "Protect Hero from being sheep",
               full_des: "Protect Hero from being sheep to hero who equip item.",
               details_km: "ការពារបន្លែងជាជាមអោយវិរជន",
          }
     },
     {
          type : BehaviourByNames.cureSheep,
          cat : BehaviourCategories.defense,
          join : "equipment-cure-sheep-team",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.team],
          effectSlots : [EffectSlots.hero, EffectSlots.support],
          effectRelation: 'and',
          des: {
               title: 'Cure sheep (and)',
               details : "Protect Team from being sheep",
               full_des: "Protect Team from being sheep when team equip item.",
               details_km: "ការពារបន្លែងជាជាមអោយក្រុម",
          }
     },
     {
          type : BehaviourByNames.cureSheep,
          cat : BehaviourCategories.defense,
          join : "potion-cure-sheep-all-active-supports",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.allActiveSupports],
          effectSlots : [EffectSlots.support],
          des: {
               title: 'Cure sheep',
               details : "Protect all active supports from being sheep",
               full_des: "Protect all active supports from being sheep when use item.",
               details_km: "ការពារបន្លែងជាជាមអ្នកគាំទ្រសកម្ម",
          }
     },
     {
          type : BehaviourByNames.cureSheep,
          cat : BehaviourCategories.defense,
          join : "potion-cure-sheep-team",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.team],
          effectSlots : [EffectSlots.hero, EffectSlots.support],
          effectRelation: 'and',
          des: {
               title: 'Cure sheep (and)',
               details : "Protect Team from being sheep",
               full_des: "Protect Team from being sheep when use item.",
               details_km: "ការពារបន្លែងជាជាមអោយក្រុម",
          }
     },
     {
          type : BehaviourByNames.cureSheep,
          cat : BehaviourCategories.defense,
          join : "potion-cure-sheep-hero-or-support",
          owners : [ItemTargets.hero, ItemTargets.support],
          receivers : [ItemReceivers.selectedHero, ItemReceivers.selectedSupport],
          effectSlots : [EffectSlots.hero, EffectSlots.support],
          effectRelation: 'or',
          des: {
               title: 'Cure sheep (or)',
               details : "Protect Hero or Support from being sheep",
               full_des: "Protect Hero or Support from being sheep to who use item.",
               details_km: "ការពារបន្លែងជាជាមអោយអ្នកគាំទ្រ",
          }
     },
     {
          type : BehaviourByNames.cureSheep,
          cat : BehaviourCategories.defense,
          join : "potion-cure-sheep-hero",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Cure sheep',
               details : "Protect Hero from being sheep",
               full_des: "Protect Hero from being sheep to hero who use item.",
               details_km: "ការពារបន្លែងជាជាមអោយវិរជន",
          }
     },
     {
          type : BehaviourByNames.cureSheep,
          cat : BehaviourCategories.defense,
          join : "potion-cure-sheep-support",
          owners : [ItemTargets.support],
          receivers : [ItemReceivers.selectedSupport],
          effectSlots : [EffectSlots.support],
          des: {
               title: 'Cure sheep',
               details : "Protect Support from being sheep",
               full_des: "Protect Support from being sheep to support who use item.",
               details_km: "ការពារបន្លែងជាជាមអោយអ្នកគាំទ្រ",
          }
     },

     //cure_fire
     {
          type : BehaviourByNames.cureFire,
          cat : BehaviourCategories.defense,
          join : "equipment-cure-fire-hero",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Cure fire',
               details : "Protect Hero from being fire",
               full_des: "Protect Hero from being fire to hero who equip item.",
               details_km: "ព្យាបាលខ្លួនឆេះអោយវិរជន",
          }
     },
     {
          type : BehaviourByNames.cureFire,
          cat : BehaviourCategories.defense,
          join : "equipment-cure-fire-team",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.team],
          effectSlots : [EffectSlots.hero, EffectSlots.support],
          effectRelation: 'and',
          des: {
               title: 'Cure fire (and)',
               details : "Protect Team from being fire",
               full_des: "Protect Team from being fire when team equip item.",
               details_km: "ព្យាបាលខ្លួនឆេះក្រុម",
          }
     },
     {
          type : BehaviourByNames.cureFire,
          cat : BehaviourCategories.defense,
          join : "potion-cure-fire-team",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.team],
          effectSlots : [EffectSlots.hero, EffectSlots.support],
          effectRelation: 'and',
          des: {
               title: 'Cure fire (and)',
               details : "Protect Team from being fire",
               full_des: "Protect Team from being fire when use item.",
               details_km: "ព្យាបាលខ្លួនឆេះក្រុម",
          }
     },
     {
          type : BehaviourByNames.cureFire,
          cat : BehaviourCategories.defense,
          join : "potion-cure-fire-all-active-supports",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.allActiveSupports],
          effectSlots : [EffectSlots.support],
          des: {
               title: 'Cure fire',
               details : "Protect All active supports from being fire",
               full_des: "Protect All active supports from being fire when use item.",
               details_km: "ព្យាបាលខ្លួនឆេះអ្នកគាំទ្រសកម្ម",
          }
     },
     {
          type : BehaviourByNames.cureFire,
          cat : BehaviourCategories.defense,
          join : "potion-cure-fire-hero-or-support",
          owners : [ItemTargets.hero, ItemTargets.support],
          receivers : [ItemReceivers.selectedHero, ItemReceivers.selectedSupport],
          effectSlots : [EffectSlots.hero, EffectSlots.support],
          effectRelation: 'or',
          des: {
               title: 'Cure fire (or)',
               details : "Protect Hero or Support from being fire",
               full_des: "Protect Hero or Support from being fire to who use item.",
               details_km: "ព្យាបាលខ្លួនឆេះ",
          }
     },
     {
          type : BehaviourByNames.cureFire,
          cat : BehaviourCategories.defense,
          join : "potion-cure-fire-hero",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Cure fire',
               details : "Protect Hero from being fire",
               full_des: "Protect Hero from being fire to hero who use item.",
               details_km: "ព្យាបាលខ្លួនឆេះវិរជន",
          }
     },
     {
          type : BehaviourByNames.cureFire,
          cat : BehaviourCategories.defense,
          join : "potion-cure-fire-support",
          owners : [ItemTargets.support],
          receivers : [ItemReceivers.selectedSupport],
          effectSlots : [EffectSlots.support],
          des: {
               title: 'Cure fire',
               details : "Protect Support from being fire",
               full_des: "Protect Support from being fire to support who use item.",
               details_km: "ព្យាបាលខ្លួនឆេះអ្នកគាំទ្រ",
          }
     },

     //damage_enemy
     {
          type : BehaviourByNames.damageEnemy,
          cat : BehaviourCategories.attack,
          join : "equipment-damage-enemy",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.enemy],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Damage enemy',
               details : "Deal damage to Enemy",
               full_des: "Deal damage to Enemy by percentage of his hp when hero equip item.",
               details_km: "កម្លាំងវាយបីសាច",
          }
     },
     {
          type : BehaviourByNames.damageEnemy,
          cat : BehaviourCategories.attack,
          join : "potion-damage-enemy",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.enemy],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Damage enemy',
               details : "Deal damage to Enemy",
               full_des: "Deal damage to Enemy by percentage of his hp when use item.",
               details_km: "កម្លាំងវាយបីសាច",
          }
     },

     //auto_tap
     {
          type : BehaviourByNames.autoTap,
          cat : BehaviourCategories.attack,
          join : "equipment-auto-tap",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Auto Tap',
               details : "Your Hero will fight automatically",
               full_des: "Hero will fight automatically when hero equip item.",
               details_km: "វិរជនវៃដោយខ្លួនឯង",
          }
     },
     {
          type : BehaviourByNames.autoTap,
          cat : BehaviourCategories.attack,
          join : "potion-auto-tap",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Auto Tap',
               details : "Your Hero will fight automatically",
               full_des: "Hero will fight automatically when use item.",
               details_km: "វិរជនវៃដោយខ្លួនឯង",
          }
     },

     //holding_tap
     {
          type : BehaviourByNames.holdingTap,
          cat : BehaviourCategories.attack,
          join : "equipment-holding-tap",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Holding Auto Tap',
               details : "Your Hero will fight on holding finger",
               full_des: "Hero will fight on holding finger without tap tap when hero equip item.",
               details_km: "វិរជនវៃដោយសង្កត់ដៃ",
          }
     },
     {
          type : BehaviourByNames.holdingTap,
          cat : BehaviourCategories.attack,
          join : "potion-holding-tap",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Holding Auto Tap',
               details : "Your Hero will fight on holding finger",
               full_des: "Hero will fight on holding finger without tap tap when use item.",
               details_km: "វិរជនវៃដោយសង្កត់ដៃ",
          }
     },

     //fill_mana
     {
          type : BehaviourByNames.fillMana,
          cat : BehaviourCategories.farm,
          join : "equipment-fill-mana",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Refill Mana',
               details : "Refill mana",
               full_des: "Refill mana by percentage to hero who equip item.",
               details_km: "បំពេញម៉ាណា",
          }
     },
     {
          type : BehaviourByNames.fillMana,
          cat : BehaviourCategories.farm,
          join : "potion-fill-mana",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Refill Mana',
               details : "Refill mana",
               full_des: "Refill mana by percentage to hero who use item.",
               details_km: "បំពេញម៉ាណា",
          }
     },

     //inc_mana
     {
          type : BehaviourByNames.incMana,
          cat : BehaviourCategories.farm,
          join : "equipment-inc-mana",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Mana capacity',
               details : "Increase Hero mana capacity",
               full_des: "Increase Hero mana capacity in percentage to hero who equip item.",
               details_km: "បង្កើនម៉ាណា",
          }
     },
     {
          type : BehaviourByNames.incMana,
          cat : BehaviourCategories.farm,
          join : "potion-inc-mana",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Mana capacity',
               details : "Increase Hero mana capacity",
               full_des: "Increase Hero mana capacity in percentage to hero who use item.",
               details_km: "បង្កើនម៉ាណា",
          }
     },

     //inc_mana_refill_speed
     {
          type : BehaviourByNames.incManaRefillSpeed,
          cat : BehaviourCategories.farm,
          join : "equipment-inc-mana-refill-speed",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Mana refill speed',
               details : "Increase mana refill speed",
               full_des: "Increase mana refill speed in percentage to hero who equip item.",
               details_km: "បង្កើនល្បឿនបំពេញម៉ាណា",
          }
     },
     {
          type : BehaviourByNames.incManaRefillSpeed,
          cat : BehaviourCategories.farm,
          join : "potion-inc-mana-refill-speed",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Mana refill speed',
               details : "Increase mana refill speed",
               full_des: "Increase mana refill speed in percentage to hero who use item.",
               details_km: "បង្កើនល្បឿនបំពេញម៉ាណា",
          }
     },

     //freeze_enemy
     {
          type : BehaviourByNames.freezeEnemy,
          cat : BehaviourCategories.attack,
          join : "equipment-freeze-enemy",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.enemy],
          effectSlots : [],//already have build-in effect
          des: {
               title: 'Freeze enemy',
               details : "Make enemy freeze",
               full_des: "Make enemy freeze when hero equip item.",
               details_km: "ជប់បីសាចកក",
          }
     },
     {
          type : BehaviourByNames.freezeEnemy,
          cat : BehaviourCategories.attack,
          join : "potion-freeze-enemy",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.enemy],
          effectSlots : [],//already have build-in effect
          des: {
               title: 'Freeze enemy',
               details : "Make enemy freeze",
               full_des: "Make enemy freeze when use item.",
               details_km: "ជប់បីសាចកក",
          }
     },

     //poison-enemy
     {
          type : BehaviourByNames.poisonEnemy,
          cat : BehaviourCategories.attack,
          join : "equipment-poison-enemy",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.enemy],
          effectSlots : [],//already have build-in effect
          des: {
               title: 'Poison enemy',
               details : "Make enemy poison",
               full_des: "Make enemy poison when hero equip item.",
               details_km: "ជះថ្នាំពុលបីសាច",
          }
     },
     {
          type : BehaviourByNames.poisonEnemy,
          cat : BehaviourCategories.attack,
          join : "potion-poison-enemy",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.enemy],
          effectSlots : [],//already have build-in effect
          des: {
               title: 'Poison enemy',
               details : "Make enemy poison",
               full_des: "Make enemy poison when use item.",
               details_km: "ជះថ្នាំពុលបីសាច",
          }
     },

     //sheep-enemy
     {
          type : BehaviourByNames.sheepEnemy,
          cat : BehaviourCategories.attack,
          join : "equipment-sheep-enemy-hero",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.enemy],
          effectSlots : [],//already have build-in effect
          des: {
               title: 'Sheep enemy',
               details : "Make enemy sheep",
               full_des: "Make enemy sheep when hero equip item.",
               details_km: "ជប់បីសាចជាសត្វជាម",
          }
     },
     {
          type : BehaviourByNames.sheepEnemy,
          cat : BehaviourCategories.attack,
          join : "potion-sheep-enemy-team",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.enemy],
          effectSlots : [],//already have build-in effect
          des: {
               title: 'Sheep enemy',
               details : "Make your enemy sheep",
               full_des: "Make enemy sheep when use item.",
               details_km: "ជប់បីសាចជាសត្វជាម",
          }
     },

     //inc_bag_slot
     {
          type : BehaviourByNames.incBagSlot,
          cat : BehaviourCategories.other,
          join : "potion-inc-slot-menu",
          owners : [ItemTargets.menu],
          receivers : [ItemReceivers.menu],
          effectSlots : [],//no effect for bag-pack slot
          des: {
               title: 'Increase slot',
               details : "Increase more slots menu for keeping items",
               full_des: "Increase more slots menu for keeping items.",
               details_km: "បង្កើនថត",
          }
     },

     //inc_critical_hit_ratio
     {
          type : BehaviourByNames.incCriticalHitRatio,
          cat : BehaviourCategories.attack,
          join : "equipment-inc-critical-hit-ratio",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          costConfig: {
               kpiLevel : -1,
               ratio : 1.0,
               ghostKill : 3
          },
          des: {
               title: 'Critical hit',
               details : "Increase hero critical hit chance",
               full_des: "Increase hero critical hit chance by percentage when hero equip item.",
               details_km: "បង្កើនអត្រាកម្លាំងមហិមា",
          }
     },
     {
          type : BehaviourByNames.incCriticalHitRatio,
          cat : BehaviourCategories.attack,
          join : "potion-inc-critical-hit-ratio",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          costConfig: {
               kpiLevel : -1,
               ratio : 1.0,
               ghostKill : 3
          },
          des: {
               title: 'Critical hit',
               details : "Increase hero critical hit chance",
               full_des: "Increase hero critical hit chance by percentage when use item.",
               details_km: "បង្កើនអត្រាកម្លាំងមហិមា",
          }
     },

     //inc_gold_enemy_drop
     {
          type : BehaviourByNames.incGoldEnemyDrop,
          cat : BehaviourCategories.farm,
          join : "equipment-inc-gold-enemy-drop",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.gold],
          effectSlots : [],
          des: {
               title: 'Enemy Gold drop',
               details : "Increase death enemy gold drop",
               full_des: "Increase death enemy gold drop amount in percentage when hero equip item.",
               details_km: "បង្កើនមាសទម្លាក់ពីបីសាច",
          }
     },
     {
          type : BehaviourByNames.incGoldEnemyDrop,
          cat : BehaviourCategories.farm,
          join : "potion-inc-gold-enemy-drop",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.gold],
          effectSlots : [],
          des: {
               title: 'Enemy Gold drop',
               details : "Increase death enemy gold drop",
               full_des: "Increase death enemy gold drop amount in percentage when use item.",
               details_km: "បង្កើនមាសទម្លាក់ពីបីសាច",
          }
     },



     //inc_hp
     {
          type : BehaviourByNames.incHp,
          cat : BehaviourCategories.health,
          join : "equipment-hp-cap-hero",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'HP Capacity',
               details : "Increase HP Cap to selected hero",
               full_des: "Increase HP Cap by percentage to hero who equip item.",
               details_km: "បង្កើនបន្ទុកឈាម",
          }
     },
     {
          type : BehaviourByNames.incHp,
          cat : BehaviourCategories.health,
          join : "equipment-hp-cap-supports",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.allActiveSupports],
          effectSlots : [EffectSlots.support],
          des: {
               title: 'HP Capacity',
               details : "Increase HP Cap to all active supports",
               full_des: "Increase HP Cap by percentage to all active supports when hero equip item.",
               details_km: "បង្កើនបន្ទុកឈាម",
          }
     },
     {
          type : BehaviourByNames.incHp,
          cat : BehaviourCategories.health,
          join : "equipment-hp-cap-team",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.team],
          effectSlots : [EffectSlots.hero, EffectSlots.support],
          effectRelation: 'and',
          des: {
               title: 'HP Capacity',
               details : "Increase HP Cap to the team",
               full_des: "Increase HP Cap by percentage to team when hero equip item.",
               details_km: "បង្កើនបន្ទុកឈាម",
          }
     },
     {
          type : BehaviourByNames.incHp,
          cat : BehaviourCategories.health,
          join : "potion-hp-cap-hero",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'HP Capacity',
               details : "Increase HP Cap to selected hero",
               full_des: "Increase HP Cap by percentage to hero who use item.",
               details_km: "បង្កើនបន្ទុកឈាម",
          }
     },
     {
          type : BehaviourByNames.incHp,
          cat : BehaviourCategories.health,
          join : "potion-hp-cap-support",
          owners : [ItemTargets.support],
          receivers : [ItemReceivers.selectedSupport],
          effectSlots : [EffectSlots.support],
          des: {
               title: 'HP Capacity',
               details : "Increase HP Cap to selected support",
               full_des: "Increase HP Cap by percentage to support who use item.",
               details_km: "បង្កើនបន្ទុកឈាមអោយអ្នកគាំទ្រ",
          }
     },
     {
          type : BehaviourByNames.incHp,
          cat : BehaviourCategories.health,
          join : "potion-hp-cap-team",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.team],
          effectSlots : [EffectSlots.hero, EffectSlots.support],
          effectRelation: 'and',
          des: {
               title: 'HP Capacity (and)',
               details : "Increase HP Cap to the team",
               full_des: "Increase HP Cap by percentage to the team when use item.",
               details_km: "បង្កើនបន្ទុកឈាមអោយក្រុមទាំងមូល",
          }
     },
     {
          type : BehaviourByNames.incHp,
          cat : BehaviourCategories.health,
          join : "potion-hp-cap-hero-or-support",
          owners : [ItemTargets.hero, ItemTargets.support],
          receivers : [ItemReceivers.selectedHero, ItemReceivers.selectedSupport],
          effectSlots : [EffectSlots.hero, EffectSlots.support],
          effectRelation: 'or',
          des: {
               title: 'HP Capacity (or)',
               details : "Increase HP Cap to selected hero or support",
               full_des: "Increase HP Cap by percentage to hero or support who use item.",
               details_km: "បង្កើនបន្ទុកឈាម",
          }
     },

     //inc_pet_active_time
     {
          type : BehaviourByNames.incPetActiveTime,
          cat : BehaviourCategories.attack,
          join : "equipment-inc-pet-active-time",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.allActivePets],
          effectSlots : [], //no effect for pets
          des: {
               title: 'Pet active time',
               details : "Increase All Pet active time(capacity)",
               full_des: "Increase All Pet active time(capacity) by percentage when hero equip item.",
               details_km: "បង្កើនរយះពេលអោយសត្វ",
          }
     },
     {
          type : BehaviourByNames.incPetActiveTime,
          cat : BehaviourCategories.attack,
          join : "potion-inc-all-pet-active-time",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.allActivePets],
          effectSlots : [], //no effect for pets
          des: {
               title: 'Pet active time',
               details : "Increase All Pets active time(capacity)",
               full_des: "Increase All Pets active time(capacity) by percentage when use item.",
               details_km: "បង្កើនរយះពេលអោយសត្វ",
          }
     },
     {
          type : BehaviourByNames.incPetActiveTime,
          cat : BehaviourCategories.attack,
          join : "potion-inc-pet-active-time",
          owners : [ItemTargets.pet],
          receivers : [ItemReceivers.selectedPet],
          effectSlots : [], //no effect for pets
          des: {
               title: 'Pet active time',
               details : "Increase selected pet active time(capacity)",
               full_des: "Increase pet active time(capacity) by percentage to pet who use item.",
               details_km: "បង្កើនរយះពេលអោយសត្វ",
          }
     },

     //inc_powerup_capacity
     {
          type : BehaviourByNames.incPowerUpCapacity,
          cat : BehaviourCategories.attack,
          join : "equipment-inc-power-up-capacity",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Power up capacity',
               details : "Increase Power up capacity",
               full_des: "Increase Power up capacity by percentage to hero who equip item.",
               details_km: "បង្កើនលទ្ធភាពជំនាញរបស់វិរជន",
          }
     },
     {
          type : BehaviourByNames.incPowerUpCapacity,
          cat : BehaviourCategories.attack,
          join : "potion-inc-power-up-capacity",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Power up capacity',
               details : "Increase Power up capacity",
               full_des: "Increase Power up capacity by percentage when use item.",
               details_km: "បង្កើនលទ្ធភាពជំនាញរបស់វិរជន",
          }
     },

     //inc_size_hero
     {
          type : BehaviourByNames.incHeroSize,
          cat : BehaviourCategories.attack,
          join : "equipment-inc-size-hero",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Hero size',
               details : "Hero will become bigger",
               full_des: "Hero will become bigger for period of time to hero who equip item.",
               details_km: "ពង្រីកខ្លួនវិរជន",
          }
     },
     {
          type : BehaviourByNames.incHeroSize,
          cat : BehaviourCategories.attack,
          join : "potion-inc-size-hero",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          des: {
               title: 'Hero size',
               details : "Hero will become bigger",
               full_des: "Hero will become bigger for period of time when use item.",
               details_km: "ពង្រីកខ្លួនវិរជន",
          }
     },

     //inc-gold-drop
     {
          type : BehaviourByNames.incGoldDrop,
          cat : BehaviourCategories.farm,
          join : "equipment-inc-gold-drop",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.gold],
          effectSlots : [],
          des: {
               title: 'Bird drop gold',
               details : "Increase bird gold drop amount",
               full_des: "Increase bird gold drop amount in percentage when hero equip item.",
               details_km: "យកមាសទម្លាក់ពីបក្សី",
          }
     },
     {
          type : BehaviourByNames.incGoldDrop,
          cat : BehaviourCategories.farm,
          join : "potion-inc-gold-drop",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.gold],
          effectSlots : [],
          des: {
               title: 'Bird drop gold',
               details : "Increase bird gold drop amount",
               full_des: "Increase bird gold drop amount in percentage when use item.",
               details_km: "យកមាសទម្លាក់ពីបក្សី",
          }
     },

     //revive_hero
     {
          type : BehaviourByNames.reviveHero,
          cat : BehaviourCategories.health,
          join : "potion-revive-hero",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.selectedHero],
          effectSlots : [EffectSlots.hero],
          costConfig: {
               kpiLevel : -1,
               ratio : 0.9,
               ghostKill : 20
          },
          des: {
               title: 'Revive a hero',
               details : "Revive selected hero",
               full_des: "Revive hero who use item.",
               details_km: "ប្រស់វីរជន",
          }
     },

     //revive_support
     {
          type : BehaviourByNames.reviveSupport,
          cat : BehaviourCategories.health,
          join : "equipment-revive-all-active-supports",
          owners : [ItemTargets.hero],
          receivers : [ItemReceivers.allActiveSupports],
          effectSlots : [EffectSlots.support],
          costConfig: {
               kpiLevel : -1,
               ratio : 0.8,
               ghostKill : 20
          },
          des: {
               title: 'Revive all active supports',
               details : "Revive all active supports",
               full_des: "Revive all active supports when hero equip item.",
               details_km: "ប្រស់អ្នកគាំទ្រសកម្ម",
          }
     },
     {
          type : BehaviourByNames.reviveSupport,
          cat : BehaviourCategories.health,
          join : "potion-revive-all-active-supports",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.allActiveSupports],
          effectSlots : [EffectSlots.support],
          costConfig: {
               kpiLevel : -1,
               ratio : 0.8,
               ghostKill : 20
          },
          des: {
               title: 'Revive all active supports',
               details : "Revive all active supports",
               full_des: "Revive all active supports when use item.",
               details_km: "ប្រស់អ្នកគាំទ្រសកម្ម",
          }
     },
     {
          type : BehaviourByNames.reviveSupport,
          cat : BehaviourCategories.health,
          join : "potion-revive-all-supports",
          owners : [ItemTargets.team],
          receivers : [ItemReceivers.allSupports],
          effectSlots : [EffectSlots.support],
          costConfig: {
               kpiLevel : -1,
               ratio : 0.9,
               ghostKill : 20
          },
          des: {
               title: 'Revive all supports',
               details : "Revive all supports",
               full_des: "Revive all supports when use item.",
               details_km: "ប្រស់អ្នកគាំទ្រទាំងអស់​",
          }
     },
     {
          type : BehaviourByNames.reviveSupport,
          cat : BehaviourCategories.health,
          join : "potion-revive-support",
          owners : [ItemTargets.support],
          receivers : [ItemReceivers.selectedSupport],
          effectSlots : [EffectSlots.support],
          costConfig: {
               kpiLevel : -1,
               ratio : 0.4,
               ghostKill : 20
          },
          des: {
               title: 'Revive a support',
               details : "Revive selected support",
               full_des: "Revive support who use item.",
               details_km: "ប្រស់អ្នកគាំទ្រសកម្ម",
          }
     },
]