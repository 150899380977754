<template>
    <v-input
            hide-details="auto"
            :rules="this.rules"
    >
       <slot></slot>
    </v-input>
</template>

<script>
    export default {
        name: "InputVilidateComponent",
        props:['rules'],
    }
</script>

<style scoped>

</style>