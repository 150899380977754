import {ItemLifeTimeNames, ItemLifeTimeShortcut} from "@/item-editor/constants/ItemLifeTime";
import BehaviourWithValueAndPeriod from "@/item-editor/constants/data-rules/BehaviourWithValueAndPeriod";
import {PeriodTypeNames} from "@/item-editor/constants/GamePlayImpactType";

export default {
     getDirectLifeTime(mainBehave){
          if (!mainBehave) return null;
          let rule = BehaviourWithValueAndPeriod[mainBehave.type];
          let notSupportLifeTimes = rule.lifeTimeNotSupport;
          let refDirectLifeTime = null;

          for (const itemLifeTimeShortcutKey in ItemLifeTimeShortcut) {
               let itemLifeTime = ItemLifeTimeShortcut[itemLifeTimeShortcutKey];

               //1. Check if lifetime support in the rules
               let lifeTimeSupported = !notSupportLifeTimes.includes(itemLifeTime.type);
               if (lifeTimeSupported) {
                    let acceptPeriodButNotAssign = rule.periodTypes === true
                        && mainBehave.gamePlayImpact.periodType === null;
                    if (acceptPeriodButNotAssign) {
                         //2 Check impact value type as duration and no period type
                         let isDurationButNotPeriod = itemLifeTimeShortcutKey === mainBehave.gamePlayImpact.bean.valueType.value;
                         if(isDurationButNotPeriod){
                              let duration = mainBehave.gamePlayImpact.value;
                              refDirectLifeTime = {
                                   bean:{
                                        text: duration+' '+itemLifeTime.unit,
                                        value: itemLifeTime.type,
                                   },
                                   type: itemLifeTime.type,
                                   value: mainBehave.gamePlayImpact.value
                              };
                              break;
                         }
                    }
               }
          }
          return refDirectLifeTime;
     },
     getLifeTimeShortcutList(mainBehave, isAutomate) {
          if (!mainBehave) return null;
          let rule = BehaviourWithValueAndPeriod[mainBehave.type];
          let notSupportLifeTimes = rule.lifeTimeNotSupport;
          let refLifeTimes = [];

          for (const itemLifeTimeShortcutKey in ItemLifeTimeShortcut) {
               let itemLifeTime = ItemLifeTimeShortcut[itemLifeTimeShortcutKey];

               if(itemLifeTime.type === ItemLifeTimeNames.Forever && isAutomate){
                    continue;
               }

               //1. Check if lifetime support in the rules
               let lifeTimeSupported = !notSupportLifeTimes.includes(itemLifeTime.type);
               if (lifeTimeSupported) {
                    //prepare item text
                    let text = '';
                    if (itemLifeTime.value > 0) {
                         text = itemLifeTime.value + ' ' + itemLifeTime.unit;
                    } else {
                         text = itemLifeTime.unit;
                    }

                    //2. Check if support period type, and it has been assigned or not
                    // if this game impact can have behaviour, but it isn't assign, so its lifetime need to be support only OneShot
                    let acceptPeriodButNotAssign = rule.periodTypes === true
                         && mainBehave.gamePlayImpact.periodType === null;
                    if (acceptPeriodButNotAssign) {
                         let isOneShot = itemLifeTime.type === ItemLifeTimeNames.OneShot;
                         if (isOneShot){
                              refLifeTimes.push({
                                   text: text,
                                   value: itemLifeTimeShortcutKey
                              })
                              break;
                         }
                    } else {
                         //3. Check if periodType has been assigned, lifetime need to be bigger than periodValue at lease 2 times
                         let periodType = mainBehave.gamePlayImpact.periodType;
                         let periodValue = mainBehave.gamePlayImpact.periodValue;
                         if (periodType !== null){
                              let lifeTimeMinValueExpected = -1;
                              let typeMappingOkay = true;

                              switch (periodType) {
                                   case PeriodTypeNames.DurationInSeconds:
                                        if (itemLifeTime.type === ItemLifeTimeNames.DurationInSeconds){
                                             lifeTimeMinValueExpected = periodValue * 2;
                                             if(isAutomate){
                                                  lifeTimeMinValueExpected = Math.max(40, lifeTimeMinValueExpected);
                                             }
                                        } else if (itemLifeTime.type === ItemLifeTimeNames.NumOfTaps){
                                             //Period duration cannot have lifetime as Number of tap
                                             typeMappingOkay = false;
                                        } else if (itemLifeTime.type === ItemLifeTimeNames.NumOfImpacts){
                                             //that's okay, NumOfImpacts will always adapt to periodValue
                                        } else if (itemLifeTime.type === ItemLifeTimeNames.Forever){
                                             //that's okay, Forever will always bigger than periodValue
                                        } else if (itemLifeTime.type === ItemLifeTimeNames.OneShot){
                                             //OneShot should go without period
                                             typeMappingOkay = false;
                                        }
                                        break;

                                   case PeriodTypeNames.NumOfTaps:
                                        if (itemLifeTime.type === ItemLifeTimeNames.DurationInSeconds){
                                             lifeTimeMinValueExpected = periodValue;
                                        } else if (itemLifeTime.type === ItemLifeTimeNames.NumOfTaps){
                                             lifeTimeMinValueExpected = periodValue * 2;
                                        } else if (itemLifeTime.type === ItemLifeTimeNames.NumOfImpacts){
                                             //that's okay, NumOfImpacts will always adapt to periodValue
                                        } else if (itemLifeTime.type === ItemLifeTimeNames.Forever){
                                             //that's okay, Forever will always bigger than periodValue
                                        } else if (itemLifeTime.type === ItemLifeTimeNames.OneShot){
                                             //OneShot should go without period
                                             typeMappingOkay = false;
                                        }
                                        break;

                                   case PeriodTypeNames.NumOfGotHits:
                                        if (itemLifeTime.type === ItemLifeTimeNames.DurationInSeconds){
                                             lifeTimeMinValueExpected = periodValue * 6;
                                        } else if (itemLifeTime.type === ItemLifeTimeNames.NumOfTaps){
                                             //Period NumOfGotHits cannot have lifetime as Number of tap
                                             typeMappingOkay = false;
                                        } else if (itemLifeTime.type === ItemLifeTimeNames.NumOfImpacts){
                                             //that's okay, NumOfImpacts will always adapt to periodValue
                                        } else if (itemLifeTime.type === ItemLifeTimeNames.Forever){
                                             //that's okay, Forever will always bigger than periodValue
                                        } else if (itemLifeTime.type === ItemLifeTimeNames.OneShot){
                                             //OneShot should go without period
                                             typeMappingOkay = false;
                                        }
                                        break;
                              }

                              if (typeMappingOkay
                                   && itemLifeTime.value >= lifeTimeMinValueExpected){
                                   refLifeTimes.push({
                                        text: text,
                                        value: itemLifeTimeShortcutKey
                                   })
                              }
                         }
                         else {
                              if (isAutomate && itemLifeTime.type === ItemLifeTimeNames.DurationInSeconds){
                                   if(itemLifeTime.value < 40 ){
                                        continue;
                                   }
                              }
                              refLifeTimes.push({
                                   text: text,
                                   value: itemLifeTimeShortcutKey
                              })
                         }

                    }
               }
          }
          return refLifeTimes;
     }
}