<template>
    <v-row>
        <v-col>
            <v-card-actions>
                <v-card-title>
                    Filter:
                </v-card-title>
                <v-col>
                    <v-card-actions>
                        <v-text-field
                                label="By description"
                                v-model="itemFilterTypeWord"
                                @input="onWordChange"></v-text-field>
                    </v-card-actions>
                </v-col>
                <v-col>
                    <v-text-field
                            label="By game level"
                            v-model="levelFilter"
                            type="number"

                            @input="onLevelFilterChange"
                    />
                </v-col>
                <v-col>
                    <v-combobox
                            v-model="environmentSelected"
                            :items="environmentRef"
                            label="By Environment Status"
                            prepend-icon="mdi-filter-variant"
                            solo
                            clearable
                            @change="onEnvironmentChange"
                    >
                        <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip
                                    v-bind="attrs"
                                    :input-value="selected"
                                    close
                                    @click="select"
                                    @click:close="removeEnvironmentFilterChosen(item)"
                            >
                                <strong>{{ item }}</strong>&nbsp;
                            </v-chip>
                        </template>
                    </v-combobox>
                </v-col>
                <v-col>
                    <v-combobox
                            v-model="heroFilterChosen"
                            :items="heroFilterSelect"
                            chips
                            clearable
                            label="By hero name"
                            prepend-icon="mdi-filter-variant"
                            solo
                            @change="onHeroFilterChange"
                    >
                        <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip
                                    v-bind="attrs"
                                    :input-value="selected"
                                    close
                                    @click="select"
                                    @click:close="removeHeroFilterChosen(item)"
                            >
                                <strong>{{ item }}</strong>&nbsp;
                            </v-chip>
                        </template>
                    </v-combobox>
                </v-col>
                <v-col>
                    <v-combobox
                            v-model="impactFilterChosen"
                            :items="impactFilterSelect"
                            chips
                            clearable
                            label="By impact"
                            prepend-icon="mdi-filter-variant"
                            solo
                            @change="onFilterChangeChange"
                    >
                        <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip
                                    v-bind="attrs"
                                    :input-value="selected"
                                    close
                                    @click="select"
                                    @click:close="removeImpactFilterChosen(item)"
                            >
                                <strong>{{ item }}</strong>&nbsp;
                            </v-chip>
                        </template>
                    </v-combobox>
                </v-col>
                <!-- disable filter by tag
                <v-col cols="3">
                    <v-combobox
                            v-model="itemFilterChosen"
                            :items="itemFilterSelect"
                            chips
                            clearable
                            label="By tags"
                            multiple
                            prepend-icon="mdi-filter-variant"
                            solo
                            @change="onFilterChangeChange"
                    >
                        <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip
                                    v-bind="attrs"
                                    :input-value="selected"
                                    close
                                    @click="select"
                                    @click:close="removeItemFilterChosen(item)"
                            >
                                <strong>{{ item }}</strong>&nbsp;
                            </v-chip>
                        </template>
                    </v-combobox>
                </v-col>
                -->
                <v-col>
                    <v-combobox
                            v-model="sortFilterChosen"
                            :items="sortFilterSelect"
                            chips
                            clearable
                            label="Sorting by"
                            prepend-icon="mdi-filter-variant"
                            solo
                            @change="onSortFilterChange"
                    >
                        <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip
                                    v-bind="attrs"
                                    :input-value="selected"
                                    close
                                    @click="select"
                                    @click:close="removeSortFilterChosen(item)"
                            >
                                <strong>{{ item }}</strong>&nbsp;
                            </v-chip>
                        </template>
                    </v-combobox>
                </v-col>
<!--                <v-col class="pt-0 pl-10 pr-5 pb-4">-->
<!--                    <v-checkbox-->
<!--                            v-model="isSortByDate"-->
<!--                            label="By descending created date"-->
<!--                            @change="onSortByDateChange"-->
<!--                    ></v-checkbox>-->
<!--                </v-col>-->
            </v-card-actions>
        </v-col>
    </v-row>
</template>

<script>
    import BehaviourJoinTargetOwnerList from "@/item-editor/constants/data-rules/BehaviourJoinTargetOwnerList";
    import {GlobalEvents, EVENT_UPDATED_EVIRONMENT_CONFIG} from "@/item-editor/events/GlobalEvents";

    const NONE_PUSH_STATUS = 'Not Push Yet';
    const SORT_BY_LEVEL = 'GameLevel appear';
    const SORT_BY_DATE = 'Created date';

    export default {
        name: "ItemFilterComponent",
        data(){
            return{
                itemFilterTypeWord: '',
                itemFilterChosen: '',
                itemFilterSelect:['equipment','potion', 'attack', 'health', 'hero', 'support'],
                heroFilterChosen: '',
                heroFilterSelect: ['1-Haknura', '2-Kasor', '3-Meora', '4-Reachny', '5-Visalabot', '6-Tida', '8-Soknarika', '9-Motharo'],
                impactFilterChosen: '',
                impactFilterSelect: [],
                isSortByDate: false,
                isSortByLevel: false,
                heroIdFilter: -1,
                levelFilter: "",
                maxLevelFilter: 10000000,
                environmentId: '',
                environmentSelected: '',
                environmentRef: [],
                sortFilterChosen: '',
                sortFilterSelect: [SORT_BY_DATE, SORT_BY_LEVEL],
            }
        },
        created() {
            this.impactFilterSelect = [];
            BehaviourJoinTargetOwnerList.forEach(ref => {
                this.impactFilterSelect.push(ref.type);
            });

            this.initEnvironmentRef();
            GlobalEvents.$on(EVENT_UPDATED_EVIRONMENT_CONFIG, () => this.initEnvironmentRef());
        },
        methods: {
            initEnvironmentRef(){
                let envList = this.$store.state.ugsSetting.environmentList;
                this.environmentRef = [];
                envList.forEach(e => {
                    this.environmentRef.push(e.name);
                });
                this.environmentRef.push(NONE_PUSH_STATUS);
            },
            getItemFilterByWord(){
                let filterConfig = {
                    itemFilterTypeWord:this.itemFilterTypeWord,
                    itemFilterChosen: this.itemFilterChosen,
                    isSortByDate: this.isSortByDate,
                    isSortByLevel: this.isSortByLevel,
                    heroIdFilter: this.heroIdFilter,
                    impactFilterChosen: this.impactFilterChosen,
                    levelFilter: this.levelFilter,
                    environmentId: this.environmentId,
                };
                this.$emit('didFilterByWord', filterConfig);
            },
            onWordChange(){
                this.getItemFilterByWord();
            },
            onFilterChangeChange(){
                this.getItemFilterByWord();
            },
            removeItemFilterChosen (item) {
                this.itemFilterChosen.splice(this.itemFilterChosen.indexOf(item), 1);
                this.getItemFilterByWord();
            },
            onSortByDateChange(){
                this.getItemFilterByWord();
            },

            onHeroFilterChange(){
                this.heroIdFilter = -1;
                if(this.heroFilterChosen !== ''){
                    if(this.heroFilterSelect.includes(this.heroFilterChosen)){
                        this.heroIdFilter = Number.parseInt(this.heroFilterChosen.split('-')[0]);
                    }
                }
                this.getItemFilterByWord();
            },
            onLevelFilterChange(){
                if(this.levelFilter !== ""){
                    let level = Number.parseInt(this.levelFilter);
                    level = Math.min(level, this.maxLevelFilter);
                    level = Math.max(level, 1);
                    this.levelFilter = level;
                }

                this.getItemFilterByWord();
            },
            removeHeroFilterChosen (item) {
                if(this.heroFilterChosen === item){
                    this.heroFilterChosen = '';
                }
                this.onHeroFilterChange();
            },
            removeImpactFilterChosen (item) {
                if(this.impactFilterChosen === item){
                    this.impactFilterChosen = '';
                }
                this.getItemFilterByWord();
            },
            onEnvironmentChange(){
                if(this.environmentSelected === NONE_PUSH_STATUS){
                    this.environmentId = 'NONE_PUSH_STATUS';
                } else {
                    let envList = this.$store.state.ugsSetting.environmentList;
                    let ref = envList.find(r => r.name === this.environmentSelected);
                    if(ref){
                        this.environmentId = ref.id;
                    } else {
                        this.environmentId = '';
                    }
                }
                this.getItemFilterByWord();
            },
            removeEnvironmentFilterChosen (item) {
                if(this.environmentSelected === item){
                    this.environmentSelected = '';
                    this.environmentId = '';
                }
                this.getItemFilterByWord();
            },
            onSortFilterChange(item){
                if(item === SORT_BY_DATE){
                    this.isSortByLevel = false;
                    this.isSortByDate = true;
                } else if (item === SORT_BY_LEVEL){
                    this.isSortByLevel = true;
                    this.isSortByDate = false;
                } else {
                    this.isSortByLevel = false;
                    this.isSortByDate = false;
                }
                this.getItemFilterByWord();
            },
            removeSortFilterChosen (item) {
                if(this.sortFilterChosen === item){
                    this.sortFilterChosen = '';
                }
                this.onSortFilterChange('');
            },
        },
    }
</script>