<template>
    <v-container>
        <ItemTextInfo>
            <v-row>
                <v-col cols="3">
                    Item Viewing: {{this.pCurrentItemCount}}
                </v-col>
                <v-col>
                    Total Created Item: {{this.countItemDev}}
                </v-col>
            </v-row>
        </ItemTextInfo>
    </v-container>
</template>

<script>
import ItemTextInfo from './ItemTextInfo'
import ItemApi from "@/item-editor/dao/ItemApi";
import {EVENT_ON_ITEM_SAVED, GlobalEvents} from "@/item-editor/events/GlobalEvents";
export default {
    name: "ItemCountStatusInfo",
    components: {ItemTextInfo},
    props:{
        pCurrentItemCount : {
            default:0,
        }
    },
    data () {
        return {
            countItemRelease:0,
            countItemDev:0
        }
    },
    methods : {
      reload(){
          ItemApi.getCountDev().then(count => {
              this.countItemDev = count;
          });
          ItemApi.getCountRelease().then(count => {
              this.countItemRelease = count;
          });
      },
    },
    created() {
        this.reload();
        GlobalEvents.$on(EVENT_ON_ITEM_SAVED,()=> this.reload());
    }
}
</script>

<style scoped>

</style>