import axios from "axios";
import NetworkUtils from "@/NetworkUtils";
import store from "../../store";
const authorize =  `Bearer ${store.getters.getToken}`;
const config = {
    headers: { Authorization: authorize }
};

export default {
    loadData: async function (environmentId) {

        let data = await axios.get(NetworkUtils.HOST + "/automate-item-create-data/getByEnvironmentId?environmentId="+environmentId, config);

        if (data.data.length > 0) {
            data = data.data[0].list;
        } else {
            data = [];
        }
        return data;
    },

    async pushAutomateItemCreateData(automateItemCreatedData, environmentId) {
        let config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: authorize
            },
        };
        let data = JSON.stringify(automateItemCreatedData)
        console.log('pushAutomateItemCreateData ', config);
        await axios.post(NetworkUtils.HOST+"/automate-item-create-data/insertOrUpdate?environmentId="+environmentId, data, config);
    },

}