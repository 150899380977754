<template>
    <v-row
            max-height="10px"
            padding-top="30px"
            padding-left="30px"
            padding-bottom="10px"
    >
        <slot></slot>
    </v-row>
</template>

<script>
    export default {
        name: "ItemTextInfo.vue"
    }
</script>