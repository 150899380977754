<template>
    <v-toolbar
            color="indigo"
            dark
            dense fixed>
        <v-card-title>
            <slot></slot>
        </v-card-title>
        <v-btn
                class="ma-2"
                color="success"
                @click="save"
        >
            <v-icon
                    left
            >
                mdi-content-save
            </v-icon>
            Save the item
        </v-btn>

        <v-btn
                class="ma-2"
                color="blue"
                @click="option()"
        >
            Options
        </v-btn>

        <v-btn
                class="ma-2"
                color="blue"
                @click="bagSlotOption()"
        >
            BagSlot
        </v-btn>

        <v-btn
                color="cancel"
                @click="close()"
        >
            Cancel
        </v-btn>

        <v-spacer></v-spacer>
    </v-toolbar>
</template>

<script>
    export default {
        name: "AutomateHeaderPopupComponent.vue",
        methods: {
            close: function () {
                this.$emit('close');
            },
            save: function () {
                this.$emit('save');
            },
            reset: function () {
                this.$emit('reset');
            },
            option: function () {
                this.$emit('option');
            },
            bagSlotOption: function (){
                this.$emit('bagSlotOption');
            }
        },
    }
</script>

<style scoped>

</style>