class AutomateBagSlotPropsModel {
    constructor() {
        this.id = 1;
        this.startLevel = 100;
        this.endLevel = 10000;
        this.minBonus = 1;
        this.maxBonus = 2;
    }
}

export default AutomateBagSlotPropsModel;