import UnityServiceApi from "@/unity-service-dashboard/api/UnityServiceApi";
import UGSVirtualPurchaseModel from "../classes/UGSVirtualPurchaseModel";
import UGSItemInventoryModel from "../classes/UGSItemInventoryModel";

export default {
    initDataStore(store){
        //assign from $store
        this.store = store;
    },

    doPushing(itemGroupData, onSuccess, onFailed){
        let itemInventory = new UGSItemInventoryModel(itemGroupData);
        let shopItem = new UGSVirtualPurchaseModel(itemGroupData, itemInventory);
        let ugsSetting = this.store.state.ugsSetting;
        UnityServiceApi.pushAddResource(itemInventory, shopItem, ugsSetting.environmentId,ugsSetting.isAutoPublish)
            .then(resultItem => {
                console.log("Result :", resultItem);
                onSuccess();
            })
            .catch(error => {
                onFailed(error);
            });
    },

    doPushingItems(itemGroupDatas, onSuccess, onFailed){
        let inventoryItems = [];
        let inventoryShops = [];
        itemGroupDatas.forEach(itemGroupData => {
            let itemInventory = new UGSItemInventoryModel(itemGroupData);
            inventoryItems.push(itemInventory);
            inventoryShops.push(new UGSVirtualPurchaseModel(itemGroupData, itemInventory));
        });

        let ugsSetting = this.store.state.ugsSetting;
        UnityServiceApi.pushAddResources(inventoryItems, inventoryShops, ugsSetting.environmentId,ugsSetting.isAutoPublish)
            .then(resultItem => {
                console.log("Result :", resultItem);
                onSuccess();
            })
            .catch(error => {
                onFailed(error);
            });
    },
}