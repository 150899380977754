import GamePlayItem from "./GamePlayItem";

class GamePlayGroupItem {
     constructor(original, sampleItem){
          this.id = original?.id;
          this.config = {};
          if(original.config){
               this.config = {
                    startLevel: original.config.startLevel,
                    endLevel: original.config.endLevel,
                    startBonusPercentage: original.config.startBonusPercentage,
                    untilBonusPercentage: original.config.untilBonusPercentage,
                    levelRangeSelect: original.config.levelRangeSelect,
                    durationBonus: original.config.durationBonus,
                    genSiblingReady: original.config.genSiblingReady,
                    impactType: original.config.impactType,
               }

               if(original.config.configOnMultiple){
                    this.config.configOnMultiple = [];
                    original.config.configOnMultiple.forEach(cfm =>{
                         this.config.configOnMultiple.push({
                              durationBonus: cfm.durationBonus,
                              startBonusPercentage: cfm.startBonusPercentage,
                              isDurationType: cfm.isDurationType,
                         });
                    });
               }
          } else {
               this.config = {
                    startLevel:20,
                    endLevel:1000,
                    startBonusPercentage: 40,
                    untilBonusPercentage: 2,
                    levelRangeSelect: 10,
                    durationBonus: 4,
                    genSiblingReady: false,
               };
          }

          this.items = [];
          if(original.items){
               original.items.forEach(item =>{
                    this.items.push(new GamePlayItem(item));
               });
          } else {
               this.items.push(new GamePlayItem(sampleItem));
          }

          this.item = this.items[0];
          this.itemCount = this.items.length;
     }
}

export default GamePlayGroupItem