var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.automateItemCreatedData,"options":{itemsPerPage:5}},scopedSlots:_vm._u([{key:"item.startLevel",fn:function(ref){
var item = ref.item;
return [_c('AutomateConfigDataInputNumber',{attrs:{"pItem":item,"pKey":"startLevel","pIsEditable":!item.isApplySimulator}})]}},{key:"item.endLevel",fn:function(ref){
var item = ref.item;
return [_c('AutomateConfigDataInputNumber',{attrs:{"pItem":item,"pKey":"endLevel","pIsEditable":!item.isApplySimulator}})]}},{key:"item.minBonus",fn:function(ref){
var item = ref.item;
return [_c('AutomateConfigDataInputNumber',{attrs:{"pItem":item,"pKey":"minBonus","pIsEditable":!item.isApplySimulator}})]}},{key:"item.maxBonus",fn:function(ref){
var item = ref.item;
return [_c('AutomateConfigDataInputNumber',{attrs:{"pItem":item,"pKey":"maxBonus","pIsEditable":!item.isApplySimulator}})]}},{key:"item.itemRootCount",fn:function(ref){
var item = ref.item;
return [_c('AutomateConfigDataInputNumber',{attrs:{"pItem":item,"pKey":"itemRootCount","pIsEditable":true}})]}},{key:"item.nextItemLvRatio",fn:function(ref){
var item = ref.item;
return [_c('AutomateConfigDataInputNumber',{attrs:{"pItem":item,"pKey":"nextItemLvRatio","pIsEditable":true}})]}},{key:"item.categoriesSelected",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.getTextDisplay(item.categoriesSelected))+" ")])]}}])}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-select',{attrs:{"items":_vm.categorySmallRef,"label":"choose icon category","multiple":"","menu-props":{ closeOnContentClick: true }},model:{value:(_vm.categoriesSelected),callback:function ($$v) {_vm.categoriesSelected=$$v},expression:"categoriesSelected"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue-darken-1","variant":"text"},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue-darken-1","variant":"text"},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }