export default [
     'orange', 'purple', 'rouge', 'blue', 'green', 'brown'
]
export let ItemColorNames = {
     'orange':'orange',
     'purple':'purple',
     'rouge':'rouge',
     'blue':'blue',
     'green':'green',
     'brown':'brown'
}
export let ItemColorCodes = {
     'orange':'orange',
     'purple':'purple',
     'rouge':'#ab1239',
     'blue':'blue',
     'green':'green',
     'brown':'brown'
}

