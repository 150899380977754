<template>
  <v-card>
    <v-card-title>Apply Config From Simulator OPTIONS</v-card-title>
    <v-row>

      <v-card elevation="0" class="pa-2 mx-10" min-height="350" max-height="350">
<!--        <v-card-title>SIMULATOR APPLIED OPTION</v-card-title>-->
        <v-row>
          <v-col cols="0" sm="0" md="0">
            <v-card
                    min-width="450"
                    max-width="450"
                    class="pa-4 my-0"
                    elevation="0"
                    outlined
            >
              <v-row>
                <v-col cols="1">
                  <v-checkbox
                          v-model="pAutomateConfig.isIncludeOptional"
                          class="pt-0 mt-2"
                  >
                  </v-checkbox>
                </v-col>
                <v-col>
                  <div class="font-weight-black pl-2 pr-3 pt-2">Include auto apply counter to optional item types</div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-1">
                  <v-card max-width="450"
                          elevation="0">
                    <v-text-field
                            label="Optional bonus ratio from dmg"
                            v-model="pAutomateConfig.optionalRatioItem"
                            type="number"
                            :disabled="!pAutomateConfig.isIncludeOptional"
                            :step="0.01"
                    />
                    <v-text-field
                            label="gold bonus ratio from dmg"
                            v-model="pAutomateConfig.optionalRatioGoldItem"
                            type="number"
                            :disabled="!pAutomateConfig.isIncludeOptional"
                            :step="0.01"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="pt-6 pl-1">
          <v-col>
            <v-btn
                    class="ma-2"
                    color="red"
                    @click="resetCounter()"
            >
              Reset Counter
            </v-btn>
            <v-btn
                    class="mr-2"
                    color="orange"
                    @click="applyConfig()"
            >
              Apply Config Simulator
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
<!--      <UnityEnvironmentConfig :pIsEnvSelectOnly="true"/>-->
    </v-row>
  </v-card>
</template>

<script>
  //import UnityEnvironmentConfig from '@/unity-service-dashboard/modules/UnityEnvironmentConfig';

  export default {
    name: "AutomateOptionPopup",
    props: ["pAutomateConfig"],
    components: {
      //UnityEnvironmentConfig
    },
    methods:{
      applyConfig(){
        this.$emit('applyConfig');
      },
      resetCounter(){
        this.$emit('resetCounter');
      },
    },
  }
</script>

<style scoped>

</style>