import axios from "axios";
import store from "../../store";
import NetworkUtils from "@/NetworkUtils";
const authorize =  `Bearer ${store.getters.getToken}`;
const config = {
    headers: { Authorization: authorize }
};


export default {

    async getLimonFromUnicode(inputText) {
        let url = NetworkUtils.HOST + '/items/limon_from_unicode?inputText='+inputText;
        let response = await axios.get(url, config);
        return response.data;
    },

    /*not working on localhost
    async getLimonFromUnicode(inputText) {
        let url = 'http://www.khmerfonts.info/transcode.php';
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Origin':'http://localhost:8080'
            }
        };

        let jSonData = {
            "id": 1287,
            "to": "transcode2l",
            "str": inputText
        };
        let response = await axios.post(url, jSonData, axiosConfig);
        return response.data;
    },*/
}