export default[
     'SWORD',
    'SPEAR',
    'TWICE',
    'SHORT_WEAPON',
    'BOW',
    'CARRIER',
    'SHIELD',
    'ENSIGN',
    'LANTERN',
    'SHIELD2D',
    'ENSIGN2D',
    'LANTERN2D',
    'GLOVE',
    'POTION',
    'RING',
    'SCROLL'
]
export let Weapon3DPackTypeName = {
     'SWORD':'weapon',
     'SPEAR':'weapon',
     'TWICE':'weapon',
     'SHORT_WEAPON':'weapon',
     'BOW':'weapon',
     'CARRIER':'weapon',
     'SHIELD': 'shield',
     'ENSIGN':'ensign',
     'LANTERN':'lantern',
     'SHIELD2D':'shield',
     'ENSIGN2D':'ensign',
     'LANTERN2D':'lantern',
     'GLOVE':'glove',
     'POTION':'potion',
     'RING': 'ring',
     'SCROLL': 'scroll'
};

export let PackTypeRemoved = [
    'ENSIGN2D',
    'LANTERN2D',
];

