<template>
    <v-card
            class="mt-2 mb-2 ml-2 mr-2"
            elevation="5"
            min-width="140"
            max-height="250"
            @click="this.dispatchClick"
    >
        <v-row justify="center"
               class="spacing-playground pa-2"
        >
          <v-img
                  :src="getBoarderIcon()"
                  max-width="90"
          >
              <v-row class="fill-height">
                  <v-row justify="center">
                      <v-card-title>
                          <v-img
                                  :src="getItemIcon()"
                                  max-width="60px"
                                  max-height="60px"
                                  dark
                          >
                          </v-img>
                      </v-card-title>
                  </v-row>
              </v-row>

          </v-img>
        </v-row>
        <v-row class="pb-1 flex-nowrap text-truncate">
            <v-card-text>
                <v-row justify="center" :id="itemData.category" :category="itemData.category" name="item-card">
                    {{getItemTitleName}}
                </v-row>
                <v-row justify="center" class="pt-2">
                    {{getFirstAppear()}}
                </v-row>
                <v-row justify="center" class="pt-2">
                    {{getEndLevel()}}
                </v-row>
                <v-row justify="center" class="pt-2">
                    <v-btn elevation="1"
                           small
                           @click="this.siblingClick">
                        {{this.getSiblingCounter()}}
                    </v-btn>
                </v-row>
            </v-card-text>
        </v-row>
    </v-card>
</template>

<script>
    import ItemColorConstants from "../../../constants/ItemColorConstants.js";
    import GamePlayGroupItem from "@/item-editor/classes/GamePlayGroupItem";
    import {EVENT_TO_UPDATE_AUTOMATE_ITEM_FORM, GlobalEvents} from "@/item-editor/events/GlobalEvents";

    export default {
        name: "AutomateCard.vue",
        props: {
            'pItemGroupData':{
                type: Object,
                required: true
            },
            'pRenderId':{
                pRenderId: String,
                default: '',
                required: false
            },
        },
        data() {
            return {
                itemGroupData: null,
                itemData: null,
                siblingConfig: null,
                isSiblingClicked: false,
            }
        },
        created() {
            this.itemGroupData = this.pItemGroupData;
            this.itemData = this.pItemGroupData.item;
            this.siblingConfig = this.pItemGroupData.config;
        },
        methods: {
            getBoarderColor() {
                return ItemColorConstants.colors[this.itemData.color];
            },
            getBoarderIcon() {
                let color = this.itemData.color;
                if (!this.itemData.color) {
                    color = 'blue';
                }
                let iconName = ItemColorConstants.iconColorNames[color];
                return require('@/item-editor/assets/icons/itemboarders/' + iconName);
            },
            getItemIcon() {
                let iconName = this.itemData.iconName + ".png";
                let result = null;
                try {
                    result = require('@/item-editor/assets/weapons/textures/' + iconName);
                } catch(e){
                    console.log("Not found image name : " + iconName);
                    result = require('@/item-editor/assets/weapons/no-image.png');
                }

                return result;
                
            },
            siblingClick(){
                this.isSiblingClicked = true;
            },
            dispatchClick() {
                //this.showUpdateItem = true;
                GlobalEvents.$emit(EVENT_TO_UPDATE_AUTOMATE_ITEM_FORM, this.getItemDataForUpdate(), this.pRenderId);
            },
            getItemDataForUpdate() {
                let groupItem = new GamePlayGroupItem(this.itemGroupData, this.itemData);
                let itemToEdit = groupItem.item;
                if(this.isSiblingClicked){
                    itemToEdit.status = "automateShowListSibling";
                    this.isSiblingClicked = false;
                } else{
                    itemToEdit.status = "automate";
                }
                return groupItem;
            },
            getSiblingCounter(){
                let siblingCounter = this.itemGroupData.itemCount;
                return "Sibling:"+ siblingCounter;
            },
            getFirstAppear(){
                let levelStart = this.itemGroupData.item.gameLevel.levelStart;
                return "Appear GLv.:"+ levelStart;
            },
            getEndLevel(){
                let count = this.itemGroupData.items.length;
                let levelStart = this.itemGroupData.items[count-1].gameLevel.levelEnd;
                return "Finish GLv.:"+ levelStart;
            }
        },
        computed: {
            getItemTitleName() {
                let title = this.itemData.title;
                if (title !== undefined) {
                    return title.en;
                }
                return "N/A";
            },
        },
    }
</script>

<style scoped>
    .v-list--two-line .v-list-item,
    .v-list-item--two-line {
        min-height: 30px;
        height:30px;
    }
</style>