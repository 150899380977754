<template>
    <v-row>
        <v-card
                max-width="650px"
                min-width="100px"
        >
            <v-tabs v-model="tab">
                <v-tab v-for="item in items" :key="item.id" @click="onClickMenuItem(item)">

                    <v-col v-if="shouldAppearIcon(item.itemIcon)">
                        <v-img :src="getIcon(item.boarderIcon)"
                               max-width="30"
                        >
                            <v-row
                                    justify="center"
                                    class="pt-sm-1"
                            >
                                <v-card-title>
                                    <v-img
                                            :src="getIcon(item.itemIcon)"
                                            max-width="18px"
                                            max-height="18px"
                                            dark
                                    ></v-img>
                                </v-card-title>
                            </v-row>
                        </v-img>
                    </v-col>
                    <v-col
                            class="pl-1"
                    >
                        {{ item.title}}
                    </v-col>


                </v-tab>
            </v-tabs>
        </v-card>
    </v-row>
</template>


<script>
    export default {
        name: "ItemTabFilter",
        props: ["pItems", "pTab"],
        data() {
            return {
                tab: null,
                lastTab: null,
                items: [],
                title: '',
            }
        },
        created() {
            this.title = this.pTitle;
            this.items = this.pItems;
        },
        methods: {
            onClickMenuItem(item) {
                this.lastTab = item;
                this.$emit('filterChange', item);
            },
            getIcon(iconName) {
                return require("../assets/icons/battleicons/" + iconName);
            },
            shouldAppearIcon(itemIcon) {
              return (!itemIcon) ? false : true;
            },
        },
        watch: {
            pItems (value){
              this.items = value;
            },
            pTab(value) {
                if (this.lastTab != value) {
                    this.tab = value;
                }
            }
        }
    }
</script>

<style scoped>
</style>