<template v-slot:pItem[pKey]>
    <v-edit-dialog
            :return-value.sync="pItem[pKey]"
    >
        <h4 v-if="pIsEditable">
            {{ pItem[pKey] }}
        </h4>
        <v-label v-if="!pIsEditable">
            {{ pItem[pKey] }}
        </v-label>
        <template v-if="pIsEditable" v-slot:input>
            <v-text-field
                    v-model="pItem[pKey]"
                    label="Edit"
                    single-line
                    :disabled="!pIsEditable"
                    @focus="$event.target.select()"
            ></v-text-field>
        </template>
    </v-edit-dialog>
</template>

<script>
    export default {
        name: "AutomateConfigDataInputNumber",
        props: ["pItem","pKey", "pIsEditable"],
    }
</script>