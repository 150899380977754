let ItemCategoryType = {
    "weapon":1,
    "accessories":2,
    "ring":3,
    "potion":4,
    "scroll":5,
    //shield=6;
    //boots=7;
}

let ItemColorType = {
    "orange":0,
    "rouge":1,
    "purple":2,
    "blue":3,
    "green":4,
    "brown":5,
}

let ItemBattleShortcutType = {
    "defense":0,
    "attack":1,
    "health":2,
}

let ItemBattleShortcutAndColors = {
    "defense": ["orange","purple"],
    "attack": ["orange","rouge"],
    "health": ["blue","green","brown"],
}

let ItemStatus = {
    "NEW":"new",
    "RELEASE": "release",
    "DELETE":"delete"
}

export default { ItemCategoryType, ItemColorType, ItemBattleShortcutType, ItemBattleShortcutAndColors, ItemStatus };