import UGSItemUtils from "../Utils/UGSItemUtils";

class UGSItemInventoryCustomData {
    constructor(itemGroup){
        let item = itemGroup.item;
        this.title = item.title;
        this.description = item.description;
        this.category = item.category;
        this.color = item.color;
        this.iconName = item.iconName;
        this.equipmentCode = item.equipmentCode;
        this.targetSelect = item.targetSelect;
        this.modes = item.modes;
        this.lifeTime = {
            'type': item.lifeTime.type,
            'limit': item.lifeTime.limit
        };

        this.levelConfig = UGSItemUtils.getItemLevelConfig(item.gameLevel, itemGroup.config.levelRangeSelect);

        this.behaviours = [];
        item.behaviours.forEach(beh => {
            this.behaviours.push({
                'gamePlayImpact':beh.gamePlayImpact,
                'type': beh.type
            });
        });
    }
}

class UGSItemInventoryModel {
    constructor(itemGroup){
        let item = itemGroup.item;
        this.name = "" + item.title.en;
        if(item.id !== undefined){
            this.id = item.id.toUpperCase();//this.name.toUpperCase().replace(/\s/g, '_')+'_ITEM';
        } else {
            this.id = undefined;
            console.log('id not exit on item name '+this.name);
        }
        this.type = 'INVENTORY_ITEM';
        this.customData = new UGSItemInventoryCustomData(itemGroup);
    }
}

export default UGSItemInventoryModel;