export default {
    async loadIconRefData() {
        return require('../assets/json/icons-ref-v2.json');
    },
    async loadIconNames() {
        let iconNames = [];

        const fs = require('fs')

        const dir = '../assets/icons/itemicons'
        const files = fs.readdirSync(dir)

        for (const file of files) {
            if (file.includes(".png")) {
                iconNames.push(file);
            }
        }
        return iconNames;
    },
}