<template>
    <v-card min-width="1200" elevation="0" light>
        <v-row no-gutters>
            <v-col cols="3" align-self="center">
                <v-card-title class="font-weight-medium text-sm-h6">
                    Chose a behaviour
                </v-card-title>
            </v-col>
            <v-col>
                <v-card-actions>
                    <v-text-field placeholder="filter by description" v-model="behaviourTypeWord"></v-text-field>
                    <v-btn icon>
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-col>

            <v-col cols="5">
                <v-card-actions>
                    <v-combobox
                        v-model="itemFilterChosen"
                        :items="itemFilterSelect"
                        chips
                        clearable
                        label="Filter by tags"
                        multiple
                        prepend-icon="mdi-filter-variant"
                        solo
                    >
                        <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip
                                v-bind="attrs"
                                :input-value="selected"
                                close
                                @click="select"
                                @click:close="removeItemFilterChosen(item)"
                            >
                                <strong>{{ item }}</strong>&nbsp;
                            </v-chip>
                        </template>
                    </v-combobox>
                </v-card-actions>
            </v-col>

        </v-row>
        <v-divider></v-divider>
        <v-card elevation="0"
                class="grey lighten-5 overflow-auto"
                :max-height="computeWindowHeight - 140">
            <v-virtual-scroll
                item-height="180"
                :height="computeWindowHeight - 140"
                :items="itemToDisplays"
            >
                <template v-slot:default="{ item }">

                    <v-row v-if="item.length > 0" dense no-gutters class="mt-2 ml-5">
                        <v-col
                            cols="auto"
                            md="2"
                            v-for="it in item"
                            :key="it.join"
                            class="mx-9"
                        >
                            <v-card min-width="260"
                                    max-width="260"
                                    min-height="170"
                                    max-height="170"
                                    class="pa-1 overflow-auto"
                                    @click="didSelectBehaviour(it)"
                            >
                                <v-card-title class="font-weight-regular text-sm-body-1">
                                    {{it.des.title}}
                                </v-card-title>
                                <v-card-subtitle>
                                    <div v-for="re in it.receivers" :key="re.value">
                                        <v-chip class="mt-1" label small>
                                            #{{re.text}}
                                        </v-chip>
                                    </div>
                                    <div>
                                        {{it.des.full_des}}
                                    </div>
                                </v-card-subtitle>

                            </v-card>

                        </v-col>
                    </v-row>

                    <v-row v-else no-gutters align="center" class="header-height ml-10">
                        <v-card
                            width="40"
                            height="40"
                            :color="renderColor(item.headerTitle)"
                        />

                        <v-card-title class="font-weight-bold text-h3">
                            {{item.headerTitle}}
                        </v-card-title>
                    </v-row>

                </template>
            </v-virtual-scroll>
        </v-card>


        <v-snackbar
            v-model="snackbar"
            :timeout="timeoutSnackbar"
            centered
        >
            <v-card-title class="font-weight-regular text-sm-body-1">
                {{ titleSnackbar }}
            </v-card-title>
            <v-card-subtitle>
                {{ subtitleSnackbar }}
            </v-card-subtitle>

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="red darken-2"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>

    </v-card>
</template>

<script>

import BehaviourUtils from "@/item-editor/Utils/BehaviourUtils";
import BehaviourJoinTargetOwnerList from "@/item-editor/constants/data-rules/BehaviourJoinTargetOwnerList";
import {ItemCategoryNames} from "@/item-editor/constants/ItemCategories";

export default {
    name: "BehaviourTypeSelector",
    props: ['pItemCategory'],
    data(){
        return {
            snackbar: false,
            titleSnackbar: '',
            subtitleSnackbar: '',
            colorSnackbar: 'success',
            timeoutSnackbar: 6000,

            refBehaviourTypes: BehaviourJoinTargetOwnerList,
            behaviourTypeWord:"",
            itemFilterChosen: [],
            itemFilterSelect: ['equipment','potion', 'attack', 'health','hero', 'support'],
        }
    },
    methods: {
        renderColor(cat){
            return BehaviourUtils.getColorCodeByCat(cat);
        },
        renderReceivers(item){
            let text = '';
            for (let i = 0; i < item.receivers.length; i++) {
                text += item.receivers[i].text;
                if (i < item.receivers.length - 1) {
                    text += ' or \n'
                }
            }
            return text;
        },

        getMainItemCategory () {
            let isPotion = this.pItemCategory === ItemCategoryNames.potion;
            if (isPotion) {
                return 'potion';
            }else {
                return 'equipment';
            }
        },

        removeItemFilterChosen (item) {
            this.itemFilterChosen.splice(this.itemFilterChosen.indexOf(item), 1)
        },

        didSelectBehaviour(item){
            if (!item.join.includes(this.getMainItemCategory())) {
                this.snackbar = true;
                this.titleSnackbar = "This behaviour not for " + this.getMainItemCategory();
                this.subtitleSnackbar = "Please do filter by : " + this.getMainItemCategory();
                this.colorSnackbar = 'red';
                this.itemFilterChosen = [];
                this.itemFilterChosen.push(this.getMainItemCategory());
                return;
            }
            this.$emit('didSelectBehaviour', item);
        },

    },
    computed: {
        computeWindowHeight () {
            const height = window.innerHeight|| document.documentElement.clientHeight||
                document.body.clientHeight;
            return height-114;
        },

        itemToDisplays () {
            let toDisplay = this.refBehaviourTypes.filter((behaviour) => {

                let isOkay = true;

                for (const id in this.itemFilterChosen) {
                    let item = this.itemFilterChosen[id];
                    isOkay &= behaviour.des.details.toUpperCase().includes(item.toUpperCase()) ||
                        behaviour.des.title.toUpperCase().includes(item.toUpperCase()) ||
                        behaviour.join.toUpperCase().includes(item.toUpperCase()) ||
                        behaviour.cat.toUpperCase().includes(item.toUpperCase());

                    // if (isOkay) break;
                }

                if (!isOkay) return false;

                if (this.behaviourTypeWord) {
                    return behaviour.des.details.toUpperCase().includes(this.behaviourTypeWord.toUpperCase()) ||
                        behaviour.des.title.toUpperCase().includes(this.behaviourTypeWord.toUpperCase()) ||
                        behaviour.join.toUpperCase().includes(this.behaviourTypeWord.toUpperCase()) ||
                        behaviour.cat.toUpperCase().includes(this.behaviourTypeWord.toUpperCase());
                } else {
                    return true;
                }
            })

            toDisplay.sort((a, b) => {
                if (a.cat > b.cat) return 1;
                else return -1;
            });
            let optimizedList = [];
            let colCount = 0;

            let tempRow = [];
            let category = '';
            if (toDisplay.length > 0) {
                category = toDisplay[0].cat;
                tempRow.headerTitle = category;
                optimizedList.push(tempRow);
                tempRow = [];

                for (let i = 0; i < toDisplay.length; i++) {
                    if (toDisplay[i].cat === category) {
                        tempRow.push(toDisplay[i]);
                        colCount++;

                        //if reach col count or reach the end of display
                        if (colCount === 4 || i === toDisplay.length - 1) {
                            optimizedList.push(tempRow);
                            tempRow = []
                            colCount = 0;
                        }

                        //if the next item is in different category
                        let nextIndex = i + 1;
                        if (nextIndex < toDisplay.length
                            && toDisplay[nextIndex].cat !== category) {

                            if (tempRow.length > 0) {
                                optimizedList.push(tempRow);
                            }

                            tempRow = []

                            category = toDisplay[nextIndex].cat;
                            tempRow.headerTitle = category;
                            optimizedList.push(tempRow);
                            tempRow = [];
                            colCount = 0;
                        }
                    }
                }
            }

            return optimizedList;
        },
    },
    created() {
        this.itemFilterChosen.push(this.getMainItemCategory());
    },
}
</script>

<style scoped>
.header-height {
    height: 130px;
}
</style>