import { render, staticRenderFns } from "./AutomateConfigDataInputNumber.vue?vue&type=template&id=afed55d6&v-slot%3ApItem%5BpKey%5D=true&"
import script from "./AutomateConfigDataInputNumber.vue?vue&type=script&lang=js&"
export * from "./AutomateConfigDataInputNumber.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports