<template>
    <div>
        <SmpDialog
                :should-show="this.showAddNewItem"
                p-ignore-close
                @close="showAddNewItem=false"
        >
            <NewItemForm
                    v-if="this.showAddNewItem"
                    :pItemData="this.itemDataForItemForm"
                    :pSiblingUtils="this.pSiblingUtils"
                    @close="showAddNewItem=false" />
        </SmpDialog>
        <SmpDialog
                :should-show="this.showItemIconList"
                width="80%"
                @close="showItemIconList=false"
        >
            <ItemIconList
                    v-if="this.showItemIconList"
                    :pFromSibling="true"
                    :pCurrentName="this.itemDataForItemForm.iconName"
            >
            </ItemIconList>
        </SmpDialog>
    </div>
</template>

<script>
import SmpDialog from "../../SmpDialog.vue";
import ItemIconList from '../../ItemIconList.vue';
import {GlobalEvents,EVENT_TO_CHANGE_ICON_SIBLING_ITEM, EVENT_TO_UPDATE_SIBLING_ITEM, EVENT_TO_OPEN_SIBLING_ITEM_FORM} from "@/item-editor/events/GlobalEvents";
import NewItemForm from "./NewSiblingItemForm";

export default {
    name: "SiblingItemUpdateForm.vue",
    props:["pSiblingUtils"],
    data() {
        return {
            showAddNewItem: false,
            showItemIconList: false,
            itemIconList: [],
            itemDataForItemForm: null,
            itemDataFromChangeIcon: null,
        }
    },

    components: {
        SmpDialog,
        ItemIconList,
        NewItemForm,
    },
    methods: {
        dispatchIconList() {
            this.showItemIconList = true;
        },
        toChangeItemIcon: function (itemData) {
            this.itemDataFromChangeIcon = itemData;
            this.showItemIconList = true;
        },
        toUpdateItemForm: function (itemData) {
            this.itemDataForItemForm = itemData;
            this.showAddNewItem = true;
        },
        toOpenSiblingItemForm: function (iconData) {
            if (this.showAddNewItem && this.itemDataFromChangeIcon !== null) {
                this.itemDataForItemForm = this.itemDataFromChangeIcon;
                this.itemDataForItemForm.iconName = this.getCleanIconName(iconData.name);
                this.itemDataFromChangeIcon = null;
            } else {
                console.log("no new condition on Sibling");
            }
            this.showItemIconList = false;
        },
        getCleanIconName(iconName) {
            let iconNameClean = iconName;
            if (iconNameClean.includes(".png")) {
                iconNameClean = iconNameClean.substring(0, iconNameClean.length - 4)
            }
            return iconNameClean;
        }
    },
    created() {
        GlobalEvents.$on(EVENT_TO_CHANGE_ICON_SIBLING_ITEM, (itemData) => this.toChangeItemIcon(itemData));
        GlobalEvents.$on(EVENT_TO_UPDATE_SIBLING_ITEM, (itemData) => this.toUpdateItemForm(itemData));
        GlobalEvents.$on(EVENT_TO_OPEN_SIBLING_ITEM_FORM, (iconData) => this.toOpenSiblingItemForm(iconData));
    }
}
</script>

<style scoped>

</style>