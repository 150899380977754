<template>
    <v-row>
        <UnityEnvironmentConfig/>
        <UnityEconomyConfig/>
    </v-row>
</template>

<script>
    import UnityEnvironmentConfig from '../modules/UnityEnvironmentConfig';
    import UnityEconomyConfig from '../modules/UnityEconomyConfig';

    export default {
        name: "UnityServiceDashboardForPopup",
        components: {
            UnityEnvironmentConfig,
            UnityEconomyConfig
        }
    }
</script>

<style scoped>

</style>