import AutomateBagSlotPropsModel from "./AutomateBagSlotPropsModel";

class AutomateBagSlotCreatedModel {
    constructor() {
        this.impactType = "inc_bag_slot";
        this.itemRootCount = 1;
        this.categorySelected = "POTION";
        this.items = [];
        this.groupsIds = [];
        this.genItemCount = 1;
        this.lstGeneric = [new AutomateBagSlotPropsModel()];
        this.levelRangePeriod = 0;
        this.nextItemLvRatio = 5;

        /*Test
        for(let i =1;i<=10;i++){
            let slot = new AutomateBagSlotPropsModel();
            slot.id += this.lstGeneric[i-1].id;
            this.lstGeneric.push(slot);
        }*/
    }
}

export default AutomateBagSlotCreatedModel;