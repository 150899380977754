<template>
    <v-card>
        <v-data-table
                :headers="headers"
                :items="automateItemCreatedData"
                :options="{itemsPerPage:5}"
        >
            <template v-slot:item.startLevel="{item}">
                <AutomateConfigDataInputNumber :pItem="item" pKey="startLevel" :pIsEditable="!item.isApplySimulator" />
            </template>
            <template v-slot:item.endLevel="{item}">
                <AutomateConfigDataInputNumber :pItem="item" pKey="endLevel" :pIsEditable="!item.isApplySimulator" />
            </template>
            <template v-slot:item.minBonus="{item}">
                <AutomateConfigDataInputNumber :pItem="item" pKey="minBonus" :pIsEditable="!item.isApplySimulator" />
            </template>
            <template v-slot:item.maxBonus="{item}">
                <AutomateConfigDataInputNumber :pItem="item" pKey="maxBonus" :pIsEditable="!item.isApplySimulator" />
            </template>
            <template v-slot:item.itemRootCount="{item}">
                <AutomateConfigDataInputNumber :pItem="item" pKey="itemRootCount" :pIsEditable="true" />
            </template>
            <template v-slot:item.nextItemLvRatio="{item}">
                <AutomateConfigDataInputNumber :pItem="item" pKey="nextItemLvRatio" :pIsEditable="true" />
            </template>
            <template v-slot:item.categoriesSelected="{item}">
                <v-btn  @click="editItem(item)">
                    {{getTextDisplay(item.categoriesSelected)}}
                </v-btn>
            </template>
        </v-data-table>

        <v-dialog
                v-model="dialog"
                max-width="500px"
        >
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-select
                                v-model="categoriesSelected"
                                :items="categorySmallRef"
                                label="choose icon category"
                                multiple
                                :menu-props="{ closeOnContentClick: true }"
                        ></v-select>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue-darken-1"
                            variant="text"
                            @click="close"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                            color="blue-darken-1"
                            variant="text"
                            @click="save"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import AutomateConfigDataInputNumber from "./AutomateConfigDataInputNumber";

    export default {
        name: "AutomateConfigDataList",
        props: ['automateItemCreatedData', 'categorySmallRef'],
        components: {
            AutomateConfigDataInputNumber,
        },
        data() {
            return {
                headers: [
                    {text: 'ImpactType', value: 'impactType', width: 170},
                    {text: 'StartLevel', value: 'startLevel'},
                    {text: 'EndLevel', value: 'endLevel'},
                    {text: 'MinBonus', value: 'minBonus'},
                    {text: 'MaxBonus', value: 'maxBonus'},
                    {text: 'ItemRootCount', value: 'itemRootCount'},
                    {text: 'RatioGenerateNextItemLvAppear', value: 'nextItemLvRatio'},
                    {text: 'CategorySelected', value: 'categoriesSelected', width: 150},
                ],
                dialog: false,
                categoriesSelected: [],
                editedIndex: -1,
            }
        },
        methods:{
            editItem (item) {
                this.editedIndex = this.automateItemCreatedData.indexOf(item);
                this.categoriesSelected = this.automateItemCreatedData[this.editedIndex].categoriesSelected;
                this.dialog = true
            },

            close(){
                this.dialog = false;
            },

            save(){
                if(this.categoriesSelected.length === 0){
                    this.categoriesSelected.push("ALL");
                } else if(this.categoriesSelected.length >= 2 && this.categoriesSelected.includes("ALL")){
                    this.categoriesSelected = this.categoriesSelected.filter(cat => cat !== "ALL");
                }
                this.automateItemCreatedData[this.editedIndex].categoriesSelected = this.categoriesSelected;
                this.close();
            },
            getTextDisplay(categoriesSelected){
                let txt = "";
                categoriesSelected.forEach(v => {
                    if(txt === ""){
                        txt = v;
                    } else {
                        txt += ", " + v;
                    }
                });
                return txt;
            },
        },
    }
</script>