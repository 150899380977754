<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-card
            elevation="0"
            color="black"

    >
      <v-row dense justify="center">
        <div
                class="pt-5"
        >
          <v-row>
            <v-col class="pl-0 pt-0 pr-0" cols="2">
              <v-card  color="white" height="50px">
                <v-card-actions
                        style="height: 60px"
                >
                   <v-select
                           v-model="categorySelected"
                           v-on:change="updateSelectedCategory"
                           variant="solo"
                           label="Categorize"
                           :items="categorySmallRef"
                   ></v-select>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col class="pl-2 pr-10 pt-0" cols="2">
              <v-card  color="white" height="50px" width="200">
                <v-card-actions
                        style="height: 60px"
                >
                  <v-select
                          v-model="heroFilterChosen"
                          v-on:change="onHeroFilterChange"
                          variant="solo"
                          label="By hero name"
                          :items="heroFilterSelect"
                  ></v-select>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col>
              <ItemTabFilter
                      :pItems="tabRefs"
                      @filterChange="onTabChanged"
              >
              </ItemTabFilter>
            </v-col>
            <span class="pl-10 pb-0 pt-0 mb-3">
              <v-card  color="white" height="50px">
                <v-card-actions
                        style="height: 50px"
                >
                  <v-text-field
                          placeholder="search icon name"
                          v-model="iconSearch"
                          @input="resetOptimizeList"
                  ></v-text-field>
                  <v-btn icon>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </span>
          </v-row>
        </div>
      </v-row>

      <v-virtual-scroll
          :height="this.computeWindowHeight"
          item-height="180"
          :items="this.optimizedList"
          id="item-scroller"
      >
        <template v-slot:default="{ item }">
          <div class="pt-5">
            <v-row dense justify="center">
              <v-col
                  :cols=1
                  v-for="it in item"
                  :key="it.name"
              >
                <ItemIconListCardItem
                    :pIconData="it"
                    :pFromSibling="pFromSibling"
                >
                </ItemIconListCardItem>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-virtual-scroll>
    </v-card>
</template>

<script>
    import ItemIconListCardItem from './ItemIconListCardItem';
    import ItemTabFilter from "./ItemTabFilter.vue";
    import ItemIconFileLoader from "@/item-editor/dao/ItemIconsFileLoader";
    import ItemApi from "@/item-editor/dao/ItemApi";
    import {GlobalEvents, EVENT_ON_ITEM_SAVED} from "@/item-editor/events/GlobalEvents";
    import {ItemCategoryNames} from "@/item-editor/constants/ItemCategories";
    import Weapon3DPackType, {Weapon3DPackTypeName, PackTypeRemoved} from "@/item-editor/constants/Weapon3DPackType";
    import WeaponAndTypeLoader from "@/item-editor/dao/WeaponAndTypeLoader";
    import HeroAndWeaponDataLoader from "@/item-editor/dao/HeroAndWeaponDataLoader";
    import EquipmentDataLoader from "@/item-editor/dao/EquipmentDataLoader";
    import ShieldDataLoader from "@/item-editor/dao/ShieldDataLoader";

    const TabIdentifier = {
      All: "Total",
      AlreadyUsed: "Already used",
      NeverUsed: "Never used"
    }
    export default {
        name: "ItemIconList",
        props:{
          'pFromSibling':{
            type: Boolean,
            default: false,
            required: false
          },
          'pCurrentName':{
            type: String,
            default: "not set",
            required: false,
          }
        },
        components: {
            ItemIconListCardItem,
            ItemTabFilter
        },
        data(){
          return{
              selectedTab:null,
              totalIconList:[],
              iconListByTotalUsed: [],
              optimizedList: [],
              tabRefs: null,
              iconSearch:"",
              currentSelectName: "",
              categorySmallRef: [],
              categorySelected: "",
              refWeaponAndTypeData: null,

              heroIdFilter: -1,
              heroFilterChosen: 'ALL',
              heroFilterSelect: ['ALL', '1-Haknura', '2-Kasor', '3-Meora', '4-Reachny', '5-Visalabot', '6-Tida', '8-Soknarika', '9-Motharo'],
              refHeroAndWeaponData: [],
              refEquipmentData:[],
              refShieldData: [],
          }
        },
        computed: {
          totalItem() {
            return this.iconListByTotalUsed.length;
          },
          computeWindowHeight() {
            const height = window.innerHeight || document.documentElement.clientHeight ||
                    document.body.clientHeight;
            return height * 0.8;
          },
        },
        methods: {
          onTabChanged(tab) {
            this.iconListByTotalUsed = [];
            if (tab.id === TabIdentifier.All){
              this.totalIconList.forEach(icon =>{
                if(this.getPassCategorize(icon)){
                  this.iconListByTotalUsed.push(icon);
                }
              });
              //this.iconListByTotalUsed = this.totalIconList;
            }else if (tab.id === TabIdentifier.AlreadyUsed){
              this.totalIconList.forEach(icon =>{
                if(this.getPassCategorize(icon)){
                  if(icon.totalUsed > 0){
                    this.iconListByTotalUsed.push(icon);
                  }
                }
              });
              //this.iconListByTotalUsed = this.totalIconList.filter(icon => icon.totalUsed > 0);
            }else if (tab.id === TabIdentifier.NeverUsed){
              this.totalIconList.forEach(icon =>{
                if(this.getPassCategorize(icon)){
                  if(!icon.totalUsed || icon.totalUsed <= 0){
                    this.iconListByTotalUsed.push(icon);
                  }
                }
              });
              //this.iconListByTotalUsed = this.totalIconList.filter(icon => !icon.totalUsed || icon.totalUsed <= 0);
            }

            //filter by hero id
            if(this.heroIdFilter > -1){
              this.iconListByTotalUsed = this.iconListByTotalUsed.filter((icon) => {
                return this.supportedHeroes(this.heroIdFilter, icon)
              });
            }

            this.refreshTabTitle();
            this.selectedTab = tab;

            this.resetOptimizeList();
          },
          resetOptimizeList(){
            this.optimizedList = [];
            setTimeout(() => {
              this.generateOptimizeList();
            }, 300);
          },
          getPassCategorize(icon){
            if(this.categorySelected !== this.categorySmallRef[0]){
              icon.showOnCategory = icon.categoryRefType === this.categorySelected;
              return icon.showOnCategory;
            }
            icon.showOnCategory = this.categorySelected === this.categorySmallRef[0];
            return icon.showOnCategory;
          },
          async refreshTotalUsed() {
            let items = await ItemApi.getIconTotalUsed();
            items.forEach(iconUsed => {
              let found = this.totalIconList.find(item => {
                return item.name === iconUsed.iconName + '.png';
              });
              if (found){
                found.totalUsed = iconUsed.count;
              }
            });
          },
          setCurrentSelect(index){
            setTimeout(() => {
              let itemHeight = 180; // set as appropriate
              let scrollH = itemHeight * index;
              document.getElementById("item-scroller").scrollTop = scrollH;
            }, 300);

          },
          initTabRef () {
            this.iconListByTotalUsed = [];
            this.totalIconList.forEach(icon =>{
              icon.showOnCategory = true;
              this.iconListByTotalUsed.push(icon);
            });
            this.refreshTabTitle();
            this.selectedTab = this.tabRefs[0];
            this.generateOptimizeList(true);
          },
          refreshTabTitle () {
            let iconList = [];
            this.totalIconList.forEach(icon =>{
              if(icon.showOnCategory){
                iconList.push(icon);
              }
            });
            let alreadyUsed = iconList.filter(icon => icon.totalUsed > 0);
            let neverUsed = iconList.filter(icon => !icon.totalUsed || icon.totalUsed <= 0);
            this.tabRefs = [
              {"title":TabIdentifier.All + ` (${iconList.length})`, "id":TabIdentifier.All},
              {"title":TabIdentifier.AlreadyUsed + ` (${alreadyUsed.length})`, "id":TabIdentifier.AlreadyUsed},
              {"title":TabIdentifier.NeverUsed + ` (${neverUsed.length})`, "id":TabIdentifier.NeverUsed},
            ];
          },
          isIconNameOnCategory(name, cat) {
            let passCount = 0;
            if (ItemCategoryNames.potion.includes(cat)) {
              passCount += (name.includes('potion') || name.includes('inventory_00')) ? 1 : 0;
            }
            if (ItemCategoryNames.ring.includes(cat)) {
              passCount += (name.includes('ring')) ? 1 : 0;
            }
            if (ItemCategoryNames.scroll.includes(cat)) {
              passCount += (name.includes('spell')) ? 1 : 0;
            }
            if (ItemCategoryNames.glove.includes(cat)) {
              passCount += (name.includes('group-18') || name.includes('claw'))? 1 : 0;
            }
            if (ItemCategoryNames.shield.includes(cat)) {
              passCount += (name.includes('group-4')) || name.includes('sh2d') ? 1 : 0;
            }
            return passCount > 0;
          },
          updateSelectedCategory(){
            this.onTabChanged(this.selectedTab);
          },
          onHeroFilterChange() {
            this.heroIdFilter = -1;
            if (this.heroFilterChosen !== '' && this.heroFilterChosen !== 'ALL') {
              if (this.heroFilterSelect.includes(this.heroFilterChosen)) {
                this.heroIdFilter = Number.parseInt(this.heroFilterChosen.split('-')[0]);
              }
            }
            this.onTabChanged(this.selectedTab);
          },
          removeHeroFilterChosen (item) {
            if(this.heroFilterChosen === item){
              this.heroFilterChosen = '';
            }
            this.onHeroFilterChange();
          },
          supportedHeroes (heroId, iconData) {
            if (iconData.category === 'weapon' || iconData.category === 'glove') {
              let equipmentCode = this.getEquipmentCodeByWeaponName(iconData.name);
              if (equipmentCode > -1) {
                let refHero = this.refHeroAndWeaponData.find(ref => ref.heroId === heroId);
                let code = parseInt(equipmentCode);
                if (refHero.weaponIds.includes(code)) {
                  return true;
                }
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          },
          getEquipmentCodeByWeaponName(weaponName){
            let weaponId = -1;
            let foundInWeapon = this.refEquipmentData.find(ref => ref.weaponName+".png" === weaponName);
            if (foundInWeapon) {
              weaponId = foundInWeapon.weaponId;
            }
            else {
              let foundInShield = this.refShieldData.find(ref => ref.weaponName+".png" === weaponName);
              if (foundInShield) {
                weaponId = foundInShield.weaponId;
              }
            }
            return weaponId;
          },

          generateOptimizeList(isMoveToSelect){
            let toDisplay = this.iconListByTotalUsed;

            if(this.iconSearch){
              let iconSearchLowerCase = this.iconSearch.toLowerCase();
              toDisplay = toDisplay.filter((icon) => {
                let iconNameLowerCase = icon.name.toLowerCase();
                let isPassCategory = this.isIconNameOnCategory(iconNameLowerCase, iconSearchLowerCase);
                let isPassName = iconNameLowerCase.includes(iconSearchLowerCase);
                return isPassCategory || isPassName;
              });
            }

            toDisplay.sort((a, b) => {

              if (!a.totalUsed && !b.totalUsed) {
                if (a.name > b.name) return 1;
              }

              if (!a.totalUsed) return -1;
              if (!b.totalUsed) return 1;

              if (a.totalUsed > b.totalUsed) return 1;
              else return -1;
            });
            let optimizedList = [];
            let rowCount = 1;

            let tempRow = [];
            for (let i = 0; i < toDisplay.length; i++) {
              tempRow.push(toDisplay[i]);

              toDisplay[i].isCurrentSelect = this.currentSelectName === toDisplay[i].name;
              if(toDisplay[i].isCurrentSelect && isMoveToSelect){
                this.setCurrentSelect(optimizedList.length);
              }

              if (rowCount === 10 || i === toDisplay.length - 1) {
                optimizedList.push(tempRow);
                rowCount = 0;
                tempRow = [];
              }
              rowCount++;
            }

            this.optimizedList = optimizedList;
          },
        },

        created() {
          GlobalEvents.$on(EVENT_ON_ITEM_SAVED, ()=> {
            this.refreshTotalUsed().then(()=>{
              this.iconListByTotalUsed = [];
              this.onTabChanged(this.selectedTab);
            });

          });
        },

        async beforeCreate() {
          this.totalIconList= await ItemIconFileLoader.loadIconRefData();

          this.refWeaponAndTypeData = await WeaponAndTypeLoader.loadData();

          this.totalIconList.forEach(icon => {
            let weaponData = this.refWeaponAndTypeData.find(r => {
              return r.weaponName+".png" === icon.name;
            });
            if(weaponData) {
              icon.categoryRefType = Weapon3DPackType[weaponData.weapon3DPackType];
              icon.category = Weapon3DPackTypeName[icon.categoryRefType];
            }
          });

          this.currentSelectName = this.pCurrentName+".png";

          this.categorySmallRef = [];
          this.categorySmallRef.push('ALL');
          Weapon3DPackType.forEach(ref =>{
            if(!PackTypeRemoved.includes(ref)){
              this.categorySmallRef.push(ref);
            }
          });
          this.categorySelected = this.categorySmallRef[0];

          this.refEquipmentData = await EquipmentDataLoader.loadData();
          this.refHeroAndWeaponData = await HeroAndWeaponDataLoader.loadData();
          this.refShieldData = await ShieldDataLoader.loadData();

          await this.refreshTotalUsed();
          this.initTabRef();
        },
    }
</script>