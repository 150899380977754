<template>
  <v-container>
    <v-card>
      <v-toolbar
          color="indigo"
          dark
          dense fixed
          min-width="800">

        <v-btn
            class="ma-2"
            @click="saveTheVisualAndTargets()"
            color="success"
        >
          <v-icon
              left
          >
            mdi-content-save
          </v-icon>
          Save the visual
        </v-btn>

        <v-btn
            color="cancel"
            @click="close()"
        >
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-row no-gutters>
        <v-col>
          <v-card-actions>
            <v-text-field placeholder="filter by name" v-model="visualEffectWord"></v-text-field>
            <v-btn icon>
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>    
        </v-col>

        <v-col>
          <v-card-actions>
            <v-combobox
                v-model="itemFilterChosen"
                :items="itemFilterSelect"
                chips
                clearable
                label="Filter by tags"
                multiple
                prepend-icon="mdi-filter-variant"
                solo
            >
                <template v-slot:selection="{ attrs, item, select, selected }">
                    <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeItemFilterChosen(item)"
                    >
                        <strong>{{ item }}</strong>&nbsp;
                    </v-chip>
                </template>
            </v-combobox>
          </v-card-actions>
        </v-col>
      </v-row>

      
      

      <v-card

          max-width="800"
          style="max-height: 500px"
          class="overflow-y-auto"
          elevation="0"

      >
        <v-container fluid>
          <v-row dense>
            <v-col
                v-for="effect in this.listOfVisibleVisualEffects"
                :key="effect.value"
                :cols=4
            >
              <v-card>
                <v-img
                    :src="renderVisualEffectIcon(effect.value)"
                    class="white--text align-end"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    height="200px"
                >
                  <!-- <v-btn icon>
                    <v-icon :color="renderVisualEffectBookmark(effect)"
                            @click="didSelectVisualEffect(effect)">mdi-bookmark
                    </v-icon>

                  </v-btn> -->
                  <v-chip
                    class="ma-2"
                    color="green darken-2"
                    text-color="white"
                >
                  <div class="text-lg-button">
                    {{getTotalUsed(effect.value)}}
                  </div>
                </v-chip>

                  <br/>
                  <v-chip
                    class="mb-1"
                    color="black"
                    @click="didSelectVisualEffect(effect)"
                  >
                  <v-avatar left v-if="renderVisualEffectBookmark(effect) === 'green'">
                    <v-icon>mdi-checkbox-marked</v-icon>
                  </v-avatar>
                  {{ effect.value }}({{effect.duration}}s)
                  </v-chip>

                  
                  
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card elevation="0">
        <v-btn
            v-model="toggleShowOnlySelected"
            class="ma-2"
            text
            icon

            @click="toggleShowOnlySelected = !toggleShowOnlySelected"
        >
          <v-icon color="green lighten-2" v-if="toggleShowOnlySelected">mdi-select-off</v-icon>
          <v-icon color="red lighten-2" v-if="!toggleShowOnlySelected">mdi-select-all</v-icon>
        </v-btn>
      </v-card>

    </v-card>
  </v-container>
</template>

<script>

import lodash from "lodash";
import visualEffectDataLoader from '@/item-editor/dao/VisualEffectDataLoader.js';
import ItemApi from "@/item-editor/dao/ItemApi";
// import result from "../../../../assets/references/items/BehaviourTypes";

// let visualEffectCodes = []
// VisualEffectCodesRough.forEach(v => {
//   visualEffectCodes.push({
//     "text": v.codename,
//     "value": v.codename,
//   })
// });

// visualEffectCodes.sort(function (a, b) {
//   if (a.codename > b.codename) {
//     return -1;
//   } else if (a.codename < b.codename) {
//     return 1;
//   } else {
//     return 0;
//   }
// })
export default {
  name: "VisualEffectSelector",
  props: ['pVisualEffectOfTargets', 'pBehaviourBean'],
  data() {
    return {
      visualEffectWord: "",
      refVisualEffects: [],
      toggleShowOnlySelected: false,
      visualEffectOfTargets:[],
      itemFilterChosen: [],
      itemFilterSelect: ['attack','bonus'],
      refEffectTotalUsed: [],
    }
  },
  methods: {


    renderTargetBookmark(effect, target) {
      let color = 'grey';
        if (!effect.targets) return color;

        effect.targets.forEach(v => {
        if (v === target) {
          color = 'green';
        }
      })
      return color;
    },
    renderVisualEffectIcon: function (visualEffectCode) {
      return require('../../../assets/visual_effects/textures/' + visualEffectCode + '.png');
    },
    renderVisualEffectBookmark(visualEffect) {
      let color = 'white';
      this.visualEffectOfTargets.forEach(v => {
        if (v.value === visualEffect.value) {
          color = 'green';
        }
      })
      return color;
    },

    getTotalUsed(effectName) {
      let found = this.refEffectTotalUsed.find(t => t.effect === effectName);
      if (found) {
        return found.count;
      } else {
        return 0;
      }
    },

    removeItemFilterChosen (item) {
        this.itemFilterChosen.splice(this.itemFilterChosen.indexOf(item), 1)
    },

      didSelectVisualEffect: function (visualEffect) {

          let alreadyExist = this.visualEffectOfTargets.find(v => v.value === visualEffect.value);
          if (alreadyExist) {
              this.visualEffectOfTargets = this.visualEffectOfTargets.filter(v => v.value !== visualEffect.value);
          } else {
              this.visualEffectOfTargets.push({
                value: visualEffect.value,
                duration: visualEffect.duration,
              });
          }
      },

    saveTheVisualAndTargets () {
      this.$emit('saveTheVisualAndTargets', this.visualEffectOfTargets);
      this.$emit('close');
    },
    close: function () {
      this.$emit('close');
    }
  },
  computed: {
    listOfVisibleVisualEffects: function () {
      let visualEffectList = []//= this.refVisualEffects;

      if (this.toggleShowOnlySelected) {
        visualEffectList = this.visualEffectOfTargets;
      }else {
        visualEffectList = this.refVisualEffects.filter(ref => {
          let alreadySelected = this.visualEffectOfTargets.find(vs => vs.value === ref.value);
          if (alreadySelected) return true;

          if (this.itemFilterChosen.length == 0) return true;

          let isOkay = true;

          for (const id in this.itemFilterChosen) {
            let item = this.itemFilterChosen[id];
            isOkay &= ref.effectUseTypes.includes(item);
          }

          return isOkay;

        });
      }

      return visualEffectList.filter((visualEffect) => {
          if (this.visualEffectWord !== "") {
              return visualEffect.value.toLowerCase().includes(this.visualEffectWord.toLowerCase());
          } else {
              return true;
          }
      });

    },
  },
    async beforeMount() {
      
        this.refEffectTotalUsed = await ItemApi.getEffectTotalUsed();
        
        this.visualEffectOfTargets = [];
        let clone = lodash.cloneDeep(this.pVisualEffectOfTargets);
        clone.forEach(vs => {
            this.visualEffectOfTargets.push({
              value: vs.effect,
              duration: vs.duration,
            });
        });

        if (this.visualEffectOfTargets.length > 0) {
            this.toggleShowOnlySelected = true;
        }

        let visualEffects = await visualEffectDataLoader.loadData();
        visualEffects.forEach(v => {
          this.refVisualEffects.push(
            {
              "value": v.codeId,
              "effectUseTypes": v.effectUseTypes,
              "duration": v.duration
            }
          );
        });

        this.refVisualEffects.sort(function (a, b) {
          if (a.codeId > b.codeId) {
            return -1;
          } else if (a.codeId < b.codeId) {
            return 1;
          } else {
            return 0;
          }
        })

        if (this.pBehaviourBean.cat == 'attack') {
          this.itemFilterChosen = ['attack'];
        } else {
          this.itemFilterChosen = ['bonus'];
        }
    }
}
</script>

<style scoped>

</style>