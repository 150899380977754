<template>
    <v-card
            class="ma-3"
            elevation="5"
            min-width="200"
            max-height="210"
    >
        <v-row class="pb-1 flex-nowrap text-truncate">
            <v-card-text>
                <v-row justify="center"
                       class="spacing-playground pa-2"
                >
                    {{getItemTitleName}}
                </v-row>
                <v-row justify="center" name="item-card">
                    {{getBaseType}}: {{getBaseDescription}}
                </v-row>
                <v-row justify="center" name="item-card">
                    Bonus: {{getImpactBonus}}
                </v-row>
                <v-row v-if="this.levelCardData.percentage" justify="center" name="item-card">
                    Percent: {{getRemainingPercentage}}
                </v-row>

                <v-row class="mt-5">
                    <v-virtual-scroll
                            item-height="50"
                            height="50"
                            v-if="isJoinImpact()"
                            :items="this.levelCardData.joinLevelDatas"
                    >
                        <template v-slot:default="{ item }">
                            <v-col cols="0" class="mx-0">
                                <v-row justify="center" name="item-card">
                                    {{getJoinBaseType(item)}}: {{getJoinBaseDescription(item)}}
                                </v-row>
                                <v-row justify="center" name="item-card">
                                    Bonus: {{getJoinImpactBonus(item)}}
                                </v-row>
                            </v-col>
                        </template>
                    </v-virtual-scroll>
                </v-row>
            </v-card-text>
        </v-row>
    </v-card>
</template>

<script>
    export default {
        name: "SiblingLevelCardItem.vue",
        props: ["pLevelCardData"],
        data() {
            return {
                levelCardData: null,
            }
        },
        created() {
            this.levelCardData = this.pLevelCardData;
        },
        watch: {
            pItemData() {
                this.levelCardData = this.pLevelCardData;
            }
        },
        methods: {
            isJoinImpact(){
                return this.levelCardData.joinLevelDatas;
            },
            getJoinImpactBonus(joinLevelData){
                return joinLevelData.bonus;
            },

            getJoinBaseDescription(joinLevelData){
                return joinLevelData.base;
            },

            getJoinRemainingPercentage(joinLevelData){
                return joinLevelData.percentage;
            },

            getJoinBaseType(joinLevelData){
                return joinLevelData.baseType;
            },
            getJoinCost(joinLevelData){
                if (joinLevelData.costInGold){
                    return joinLevelData.costInGold.ToReadableAlphabetV2() + " coins";
                }
                else {
                    return "unknown"
                }
            },
        },
        computed: {
            getItemTitleName() {
                let title = "Level: "+ this.levelCardData.level;
                return title;
            },

            getImpactBonus(){
                return this.levelCardData.bonus;
            },

            getBaseDescription(){
                return this.levelCardData.base;
            },

            getRemainingPercentage(){
                return this.levelCardData.percentage;
            },

            getBaseType(){
                return this.levelCardData.baseType;
            },

            isShowPercent(){
                return this.levelCardData.percentage !== "";
            },
        },
    }
</script>

<style scoped>
    .v-list--two-line .v-list-item,
    .v-list-item--two-line {
        min-height: 30px;
        height:30px;
    }
</style>