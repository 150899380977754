var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"min-width":"1200","elevation":"0","light":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3","align-self":"center"}},[_c('v-card-title',{staticClass:"font-weight-medium text-sm-h6"},[_vm._v(" Chose a behaviour ")])],1),_c('v-col',[_c('v-card-actions',[_c('v-text-field',{attrs:{"placeholder":"filter by description"},model:{value:(_vm.behaviourTypeWord),callback:function ($$v) {_vm.behaviourTypeWord=$$v},expression:"behaviourTypeWord"}}),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-spacer')],1)],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-card-actions',[_c('v-combobox',{attrs:{"items":_vm.itemFilterSelect,"chips":"","clearable":"","label":"Filter by tags","multiple":"","prepend-icon":"mdi-filter-variant","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeItemFilterChosen(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))]),_vm._v("  ")])]}}]),model:{value:(_vm.itemFilterChosen),callback:function ($$v) {_vm.itemFilterChosen=$$v},expression:"itemFilterChosen"}})],1)],1)],1),_c('v-divider'),_c('v-card',{staticClass:"grey lighten-5 overflow-auto",attrs:{"elevation":"0","max-height":_vm.computeWindowHeight - 140}},[_c('v-virtual-scroll',{attrs:{"item-height":"180","height":_vm.computeWindowHeight - 140,"items":_vm.itemToDisplays},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [(item.length > 0)?_c('v-row',{staticClass:"mt-2 ml-5",attrs:{"dense":"","no-gutters":""}},_vm._l((item),function(it){return _c('v-col',{key:it.join,staticClass:"mx-9",attrs:{"cols":"auto","md":"2"}},[_c('v-card',{staticClass:"pa-1 overflow-auto",attrs:{"min-width":"260","max-width":"260","min-height":"170","max-height":"170"},on:{"click":function($event){return _vm.didSelectBehaviour(it)}}},[_c('v-card-title',{staticClass:"font-weight-regular text-sm-body-1"},[_vm._v(" "+_vm._s(it.des.title)+" ")]),_c('v-card-subtitle',[_vm._l((it.receivers),function(re){return _c('div',{key:re.value},[_c('v-chip',{staticClass:"mt-1",attrs:{"label":"","small":""}},[_vm._v(" #"+_vm._s(re.text)+" ")])],1)}),_c('div',[_vm._v(" "+_vm._s(it.des.full_des)+" ")])],2)],1)],1)}),1):_c('v-row',{staticClass:"header-height ml-10",attrs:{"no-gutters":"","align":"center"}},[_c('v-card',{attrs:{"width":"40","height":"40","color":_vm.renderColor(item.headerTitle)}}),_c('v-card-title',{staticClass:"font-weight-bold text-h3"},[_vm._v(" "+_vm._s(item.headerTitle)+" ")])],1)]}}])})],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeoutSnackbar,"centered":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red darken-2","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-card-title',{staticClass:"font-weight-regular text-sm-body-1"},[_vm._v(" "+_vm._s(_vm.titleSnackbar)+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.subtitleSnackbar)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }