import {ItemCategoryNames} from "@/item-editor/constants/ItemCategories";
import {Weapon3DPackTypeName} from "../constants/Weapon3DPackType";

let MAX_HERO = 8;

class HeroAndWeaponSupportUtils {

    constructor (refHeroAndWeaponData, refEquipmentData, refShieldData) {
        this.refHeroAndWeaponData = refHeroAndWeaponData;
        this.refEquipmentData = refEquipmentData;
        this.refShieldData = refShieldData;
    }

    getListHeroesNeedToUseItem(){
        return [1, 2, 3, 4, 5, 6, 8, 9];
    }

    getCleanIconName(iconName) {
        let iconNameClean = iconName;
        if (iconNameClean.includes(".png")) {
            iconNameClean = iconNameClean.substring(0, iconNameClean.length - 4)
        }
        return iconNameClean;
    }

    getCategoryPossibleHero(categorySelected, heroesNeedToUseItem){
        if(heroesNeedToUseItem.length < 8){
            if(heroesNeedToUseItem.includes(3) //hero id 3 is glove
                && Weapon3DPackTypeName[categorySelected] === ItemCategoryNames.weapon){
                categorySelected = "GLOVE";
            } else if (!heroesNeedToUseItem.includes(3)
                && categorySelected === "GLOVE"){
                categorySelected = "SWORD";
            }
        }

        return categorySelected;
    }

    getGroupIconCategory(categoryRef, totalIconList, heroesNeedToUseItem){
        let iconListByTotalUsed = [];
        let iconAndHeroResults = [];
        if(categoryRef === 'ALL'){
            totalIconList.forEach(icon=>{
                let iconAndHero = this.getIconAndHeroPossibleUse(icon, heroesNeedToUseItem);
                iconAndHeroResults.push(iconAndHero);
            });
        } else {
            totalIconList.forEach(icon=>{
                if(icon.categoryRefType === categoryRef){
                    let iconAndHero = this.getIconAndHeroPossibleUse(icon, heroesNeedToUseItem);
                    iconAndHeroResults.push(iconAndHero);
                }
            });
        }

        if(iconAndHeroResults.length > 0){
            let maxHeroPossible = iconAndHeroResults.reduce((a,b) => a.heroCounter > b.heroCounter ? a : b)
            let maxCounter = maxHeroPossible.heroCounter;
            iconAndHeroResults.forEach(ih => {
                if(ih.heroCounter >= maxCounter){
                    iconListByTotalUsed.push(ih.iconData);
                }
            });
        }

        return iconListByTotalUsed;
    }

    getIconAndHeroPossibleUse (iconData, heroesNeedToUseItem) {
        let iconAndHero = {
            iconData: iconData,
            heroCounter: 0
        };
        if(iconData.category !== ItemCategoryNames.weapon
            && iconData.category !== ItemCategoryNames.glove){
            iconAndHero.heroCounter = MAX_HERO;
            return iconAndHero;
        }

        let heroeIds = [];
        let weaponId = "";
        let iconCleanName = this.getCleanIconName(iconData.name);
        let foundInWeapon = this.refEquipmentData.find(ref => ref.weaponName === iconCleanName);
        if (foundInWeapon) {
            weaponId = foundInWeapon.weaponId;
        }
        else {
            let foundInShield = this.refShieldData.find(ref => ref.weaponName === iconCleanName);
            if (foundInShield) {
                weaponId = foundInShield.weaponId;
            }
        }

        if (weaponId){
            this.refHeroAndWeaponData.forEach(hd => {
                if(heroesNeedToUseItem.includes(hd.heroId)){
                    let code = parseInt(weaponId);
                    if (hd.weaponIds.includes(code)){
                        heroeIds.push(hd.heroId);
                    }
                }

            });
            iconAndHero.heroCounter = heroeIds.length;
        }

        return iconAndHero;
    }

    getHeroesWhoNotPossibleUse (iconData, heroesNeedToUseItem) {
        if(iconData.category !== ItemCategoryNames.weapon
            && iconData.category !== ItemCategoryNames.glove){
            return [];
        }

        let heroesCanUseWeapon = [];
        let weaponId = "";
        let iconCleanName = iconData.fullName;
        let foundInWeapon = this.refEquipmentData.find(ref => ref.weaponName === iconCleanName);
        if (foundInWeapon) {
            weaponId = foundInWeapon.weaponId;
        }
        else {
            let foundInShield = this.refShieldData.find(ref => ref.weaponName === iconCleanName);
            if (foundInShield) {
                weaponId = foundInShield.weaponId;
            }
        }
        if (weaponId){
            this.refHeroAndWeaponData.forEach(hd => {
                let code = parseInt(weaponId);
                if (hd.weaponIds.includes(code)){
                    heroesCanUseWeapon.push(hd.heroId);
                }
            });
        }

        return heroesNeedToUseItem.filter(id => !heroesCanUseWeapon.includes(id));
    }

    heroesWhoCanUseItem(itemData) {
        let heroeIds = [];
        if (itemData.equipmentCode.length > 0){
            this.refHeroAndWeaponData.forEach(hd => {
                let code = parseInt(itemData.equipmentCode);
                if (hd.weaponIds.includes(code)){
                    heroeIds.push(hd.heroId);
                }
            });
            return heroeIds;
        }else {
            return heroeIds;
        }
    }

    heroesWhoCantUseItem(itemData){
        let heroeIds = [];
        if (itemData.equipmentCode.length > 0){
            this.refHeroAndWeaponData.forEach(hd => {
                let code = parseInt(itemData.equipmentCode);
                if (!hd.weaponIds.includes(code)){
                    heroeIds.push(hd.heroId);
                }
            });
            return heroeIds;
        }else {
            return heroeIds;
        }
    }

    getWeaponNameWhoCanUseForHero(heroes, weaponType){
        let weaponIds = [];
        this.refHeroAndWeaponData.forEach(hw => {
           if(heroes.includes(hw.heroId)){
               hw.weaponIds.forEach(wpId => {
                   if(!weaponIds.includes(wpId)){
                       weaponIds.push(wpId);
                   }
               });
           }
        });
        let lsWeaponData = this.refEquipmentData.filter(ref => ref.weaponType === weaponType
            && weaponIds.includes(ref.weaponId));

        let randomIndex = Math.floor(Math.random() * lsWeaponData.length);
        return lsWeaponData[randomIndex];
    }
}

export default HeroAndWeaponSupportUtils;
