import UnityServiceApi from '../api/UnityServiceApi';

export default {
    async applyEnvironmentPushHistory(items, environmentList){
        let tasks = [];
        environmentList.forEach(env => {
            tasks.push(
                UnityServiceApi.getItem(env.id)
            );
        });

        let promAll = await Promise.all(tasks);
        for(let i = 0;i<promAll.length;i++){
            this.applyEnvironment(items, environmentList[i].id, promAll[i]);
        }

        return items;
    },

    applyEnvironment(items, environmentId, data){
        if (data.length > 0){
            data.forEach(d =>{
                let groupItem = items.find(i => d.id === this.getItemIdMatchResource(i));
                if(groupItem){
                    if(!groupItem.item.pushStatus){
                        groupItem.item.pushStatus = [];
                    }
                    groupItem.item.pushStatus.push(environmentId);
                }
            });
        }
    },

    getItemIdMatchResource(groupItem){
        if(!groupItem.item) return null;
        let id = "" + groupItem.item.id;
        return id.toUpperCase();//name.toUpperCase().replace(/\s/g, '_')+'_ITEM';
    }
}