import {ValueTypeNames} from "@/item-editor/constants/GamePlayImpactType";
import {ItemLifeTimeNames} from "@/item-editor/constants/ItemLifeTime";

export default {
     "inc-speed-support":{
          "valueTypes":[
               ValueTypeNames.DirectValue,
               ValueTypeNames.Percentage,
          ],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfTaps,
               ItemLifeTimeNames.NumOfImpacts,
               ItemLifeTimeNames.OneShot,
               ItemLifeTimeNames.NumOfGotHits,
          ],
          "periodTypes": false
     },
     "inc-gold-drop":{
          "valueTypes":[
               ValueTypeNames.DirectValue,
               ValueTypeNames.Percentage,
          ],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfGotHits,
          ],
          "periodTypes": true
     },
     "damage_enemy":{
          "valueTypes":[
               ValueTypeNames.DirectValue,
               ValueTypeNames.Percentage,
          ],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfGotHits,
          ],
          "periodTypes": true,
          "requirePeriodForEquip": true
     },
     "poison-enemy":{
          "valueTypes":[
               ValueTypeNames.DirectValue,
               ValueTypeNames.Percentage,
          ],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfGotHits,
          ],
          "periodTypes": true,
          "requirePeriodForEquip": true
     },
     "inc_mana":{
          "valueTypes":[
               ValueTypeNames.DirectValue,
               ValueTypeNames.Percentage,
          ],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfImpacts,
               ItemLifeTimeNames.OneShot,
               ItemLifeTimeNames.NumOfGotHits,
               ItemLifeTimeNames.NumOfTaps
          ],
          "periodTypes": false
     },
     "inc_mana_refill_speed":{
          "valueTypes":[
               ValueTypeNames.DirectValue,
               ValueTypeNames.Percentage,
          ],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfImpacts,
               ItemLifeTimeNames.OneShot,
               ItemLifeTimeNames.NumOfGotHits,
               ItemLifeTimeNames.NumOfTaps
          ],
          "periodTypes": false
     },
     "inc_critical_hit_ratio":{
          "valueTypes":[
               ValueTypeNames.DirectValue,
               ValueTypeNames.Percentage,
          ],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfImpacts,
               ItemLifeTimeNames.OneShot,
               ItemLifeTimeNames.NumOfGotHits,
          ],
          "periodTypes": false
     },
     "inc_gold_enemy_drop":{
          "valueTypes":[
               ValueTypeNames.DirectValue,
               ValueTypeNames.Percentage,
          ],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfImpacts,
               ItemLifeTimeNames.OneShot,
               ItemLifeTimeNames.NumOfGotHits,
               ItemLifeTimeNames.NumOfTaps
          ],
          "periodTypes": false
     },
     "inc_pet_active_time":{
          "valueTypes":[
               ValueTypeNames.DirectValue,
               ValueTypeNames.Percentage,
          ],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfImpacts,
               ItemLifeTimeNames.OneShot,
               ItemLifeTimeNames.NumOfGotHits,
          ],
          "periodTypes": false
     },
     "inc_powerup_capacity":{
          "valueTypes":[
               ValueTypeNames.DirectValue,
               ValueTypeNames.Percentage,
          ],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfImpacts,
               ItemLifeTimeNames.OneShot,
               ItemLifeTimeNames.NumOfGotHits,
               ItemLifeTimeNames.NumOfTaps
          ],
          "skillTypes": true,
          "periodTypes": false,
     },
     "inc_hp":{
          "valueTypes":[
               ValueTypeNames.DirectValue,
               ValueTypeNames.Percentage,
          ],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfImpacts,
               ItemLifeTimeNames.OneShot,
               ItemLifeTimeNames.NumOfGotHits,
               ItemLifeTimeNames.NumOfTaps
          ],
          "periodTypes": false,
     },
     "fill_hp":{
          "valueTypes":[
               ValueTypeNames.DirectValue,
               ValueTypeNames.Percentage,
          ],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfGotHits,
          ],
          "periodTypes": true,
          "requirePeriodForEquip": true
     },
     "fill_mana":{
          "valueTypes":[
               ValueTypeNames.DirectValue,
               ValueTypeNames.Percentage,
          ],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfGotHits,
          ],
          "periodTypes": true,
          "requirePeriodForEquip": true
     },
     "inc_dps":{
          "valueTypes":[
               ValueTypeNames.DirectValue,
               ValueTypeNames.Percentage,
          ],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfImpacts,
               ItemLifeTimeNames.OneShot,
               ItemLifeTimeNames.NumOfGotHits,
               ItemLifeTimeNames.NumOfTaps
          ],
          "periodTypes": false,
     },
     "freeze-enemy":{
          "valueTypes":[
               ValueTypeNames.DurationInSeconds,
          ],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfGotHits,
          ],
          "periodTypes": true,
     },
     "sheep-enemy":{
          "valueTypes":[
               ValueTypeNames.DurationInSeconds,
          ],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfGotHits,
          ],
          "periodTypes": true,
     },
     "inc_size_hero":{
          "valueTypes":[
               ValueTypeNames.DurationInSeconds,
          ],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.OneShot,
               ItemLifeTimeNames.NumOfGotHits,
          ],
          "periodTypes": true,
     },
     "revive_hero":{
          "valueTypes":[],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfGotHits,
               ItemLifeTimeNames.NumOfTaps,
               ItemLifeTimeNames.Forever,
               ItemLifeTimeNames.NumOfImpacts
          ],
          "periodTypes": false,
     },
     "revive_support":{
          "valueTypes":[],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfGotHits,
               ItemLifeTimeNames.NumOfTaps,
               ItemLifeTimeNames.Forever,
               ItemLifeTimeNames.NumOfImpacts
          ],
          "periodTypes": false,
     },
     "cure_freeze":{
          "valueTypes":[],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfGotHits,
               ItemLifeTimeNames.NumOfTaps
          ],
          "periodTypes": false,
     },
     "cure_poison":{
          "valueTypes":[],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfGotHits,
               ItemLifeTimeNames.NumOfTaps
          ],
          "periodTypes": false,
     },
     "cure_sheep":{
          "valueTypes":[],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfGotHits,
               ItemLifeTimeNames.NumOfTaps
          ],
          "periodTypes": false,
     },
     "cure_fire":{
          "valueTypes":[],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfGotHits,
               ItemLifeTimeNames.NumOfTaps
          ],
          "periodTypes": false,
     },
     "inc_bag_slot":{
          "valueTypes":[
               ValueTypeNames.NumOfSlots,
          ],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.Forever,
               ItemLifeTimeNames.NumOfTaps,
               ItemLifeTimeNames.NumOfImpacts,
               ItemLifeTimeNames.DurationInSeconds,
               ItemLifeTimeNames.NumOfGotHits,
          ],
          "periodTypes": false,
     },
     "auto_tap":{
          "valueTypes":[
               ValueTypeNames.NumOfTapsPerSecond,
          ],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfTaps,
               ItemLifeTimeNames.NumOfImpacts,
               ItemLifeTimeNames.OneShot,
               ItemLifeTimeNames.NumOfGotHits,
          ],
          "periodTypes": false,
     },
     "holding_tap":{
          "valueTypes":[
               ValueTypeNames.NumOfTapsPerSecond,
          ],
          "lifeTimeNotSupport":[
               ItemLifeTimeNames.NumOfTaps,
               ItemLifeTimeNames.NumOfImpacts,
               ItemLifeTimeNames.OneShot,
               ItemLifeTimeNames.NumOfGotHits,
          ],
          "periodTypes": false,
     },
}