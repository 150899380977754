
class AutomateItemCreatedModel {
    constructor() {
        this.impactType = "damage_enemy";
        this.startLevel = 50;
        this.endLevel = 500;
        this.minBonus = 50;
        this.maxBonus = 100;
        this.itemRootCount = 15;
        this.nextItemLvRatio = 5;
        this.categoriesSelected = [];
        this.levelRangePeriod = 0;
        this.levelRangePeriodPerItem = 0;
        this.twiceImpactGenPercent = 20;
        this.tripleImpactGenPercent = 10;
        this.twiceJoinOnCountRatio = 0.8;//twice impact will start at create item in 80th
        this.tripleJoinOnCountRatio = 0.9;
        this.items = [];
        this.groupsIds = [];
        this.genItemCount = 0;
        this.lstGeneric = [];
        this.isApplySimulator = false;
    }
}

export default AutomateItemCreatedModel;