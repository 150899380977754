<template>
    <v-row>
        <v-col
                cols="12"
                class="py-2"
        >
            <v-btn-toggle
                    v-model="selectedLocale"
                    tile
                    color="blue darken-1"
                    group
            >
                <v-btn value="en">
                    <InputValidate :rules="[(itemData.title['en'] !== '' && itemData.description['en'] !== '')]">
                        <v-card-text>
                            English *
                        </v-card-text>
                    </InputValidate>
                </v-btn>

                <v-btn value="fr">
                    <InputValidate :rules="[(itemData.title['fr'] !== '' && itemData.description['fr'] !== '')]">
                        <v-card-text>
                            French *
                        </v-card-text>
                    </InputValidate>
                </v-btn>

                <v-btn value="km_u">
                    <InputValidate :rules="[(itemData.title['km_u'] !== '' && itemData.description['km_u'] !== '')]">
                        <v-card-text>
                            Khmer *
                        </v-card-text>
                    </InputValidate>
                </v-btn>

                <v-btn value="es">
                    <InputValidate :rules="[(itemData.title['es'] !== '' && itemData.description['es'] !== '')]">
                        <v-card-text>
                            Spanish *
                        </v-card-text>
                    </InputValidate>
                </v-btn>
            </v-btn-toggle>
        </v-col>

        <v-col>
            <v-combobox
                    label="Title (Wizard)"
                    v-show="selectedLocale === 'en'"
                    :items="refNamesWizard"
                    v-model="titleSelected"
                    clearable
                    dense
                    :rules="rulesNotEmpty('Title')"
                    @change="onSelectNameWizard"
            >
            </v-combobox>
            <v-text-field
                    v-model="itemData.description['en']"
                    label="Description"
                    v-show="selectedLocale === 'en'"
                    :rules="rulesNotEmpty('Description')"
                    @change="onUpdateLocalize"
            ></v-text-field>

            <v-text-field
                    v-model="itemData.title['fr']"
                    label="Titre"
                    v-show="selectedLocale === 'fr'"
                    :rules="rulesNotEmpty('Titre')"
                    @change="onUpdateLocalize"
            ></v-text-field>
            <v-text-field
                    v-model="itemData.description['fr']"
                    label="La description"
                    v-show="selectedLocale === 'fr'"
                    :rules="rulesNotEmpty('La description')"
                    @change="onUpdateLocalize"
            ></v-text-field>

            <v-text-field
                    v-model="itemData.title['km_u']"
                    label="ចំណងជើង"
                    v-show="selectedLocale === 'km_u'"
                    @change="onKhTitleInput"
                    :rules="rulesNotEmpty('ចំណងជើង')"
            ></v-text-field>
            <v-text-field
                    v-model="itemData.description['km_u']"
                    label="ការពិពណ៌នា"
                    v-show="selectedLocale === 'km_u'"
                    @change="onKhDiscriptionInput"
                    :rules="rulesNotEmpty('ការពិពណ៌នា')"
            ></v-text-field>


            <v-text-field
                    label="título"
                    v-model="itemData.title['es']"
                    v-show="selectedLocale === 'es'"
                    :rules="rulesNotEmpty('título')"
                    @change="onUpdateLocalize"
            ></v-text-field>
            <v-text-field
                    v-model="itemData.description['es']"
                    label="descripción"
                    v-show="selectedLocale === 'es'"
                    :rules="rulesNotEmpty('descripción')"
                    @change="onUpdateLocalize"
            ></v-text-field>
        </v-col>
    </v-row>
</template>

<script>
    import InputValidate from "./InputVilidateComponent.vue";
    import LocalizeUnicodeApi from "../dao/LocalizeUnicodeApi";
    import loadash from "lodash";

    const splitTitleName = ' (';

    export default {
        name: "LocalizeItemForm.vue",
        props:["itemData"],
        components: {InputValidate},
        data(){
            return{
                selectedLocale: "en",
                lastTitleName: '',
                titleSelected: undefined,
                refMixNamesList: [],
                refNamesWizard : [],
            }
        },
        async beforeMount() {
            let refWeaponNames = this.$store.state.itemWeaponNames;
            let refPotionNames = this.$store.state.itemPotionNames;
            let itemNameList = this.$store.state.itemNameList;
            refWeaponNames.forEach(ref => {
                this.addNameWizard(ref);
            });
            refPotionNames.forEach(ref => {
                this.addNameWizard(ref);
            });
            itemNameList.forEach(ref => {
                this.addNameWizard(ref);
            });
            this.refNamesWizard = loadash.orderBy(this.refNamesWizard);
            this.titleSelected = this.itemData.title['en'];
        },
        methods:{
            rulesNotEmpty (fieldName) {
                return [
                    v => !!v || fieldName + ' is required!'
                ];
            },

            onKhTitleInput(){
                this.itemData.title['km'] = '';
                LocalizeUnicodeApi.getLimonFromUnicode(this.itemData.title['km_u']).then(result => {
                    this.itemData.title['km'] = result;
                    this.onUpdateLocalize();
                    setTimeout(() => {
                    }, 1500);
                }).catch(error => {
                    console.log("Failed trans code: " + error);
                    this.onFailedLocalize();
                    setTimeout(() => {
                    }, 200)});
            },

            onKhDiscriptionInput(){
                this.itemData.description['km'] = '';
                LocalizeUnicodeApi.getLimonFromUnicode(this.itemData.description['km_u']).then(result => {
                    this.itemData.description['km'] = result;
                    this.onUpdateLocalize();
                    setTimeout(() => {
                    }, 1500);
                }).catch(error => {
                    console.log("Failed trans code: " + error);
                    this.onFailedLocalize();
                    setTimeout(() => {
                    }, 200)});
            },

            onSelectNameWizard(){
                let refName = this.titleSelected;
                if(refName && refName !== ''){
                    if(refName.includes(splitTitleName)){
                        refName = refName.split(splitTitleName)[0];
                        this.titleSelected = refName;
                    }
                    let wizard = this.refMixNamesList.find(ref => ref.name === refName);
                    if(wizard){
                        this.itemData.title.en = wizard.name;
                        this.itemData.title.fr = wizard.name_fr;
                        this.itemData.title.es = wizard.name_es;
                        this.itemData.title.km_u = wizard.name_km;
                    } else{
                        this.itemData.title.en = refName;
                        this.itemData.title.fr = '';
                        this.itemData.title.es = '';
                        this.itemData.title.km_u = '';
                    }
                    this.onUpdateLocalize();
                }
            },

            addNameWizard(refName){
                let name = refName.name;
                if(refName.usage > 0){
                    name = refName.name+splitTitleName+(refName.usage)+")";
                }
                if(!this.refNamesWizard.includes(name)){
                    this.refNamesWizard.push(name);
                    this.refMixNamesList.push(refName);
                }
            },

            onUpdateLocalize(){
                this.$emit("onUpdateLocalize");
            },

            onFailedLocalize(){
                this.$emit("onFailedLocalize");
            },
        },
    }
</script>

<style scoped>
    @font-face {
        font-family: 'Limon F6';
        src:url('../assets/font/lmnf6.ttf');
    }
    .limon_input{
        font: 32px 'Limon F6';
    }
</style>