export default
[
     "THUNDER_ATTACK",//HeavenlyStrike_Skill
     "TWIN_SHADOW",//ShadowClone_Skill
     "LAST_HAND",//CriticalStrike_Skill
     "FAST_AND_FURIOUS", //SMPSkillWarCry
     "ANGER_OF_GOD", //SMPBerserkerRageSkill
     "GOLD_FINGER",//Hand_of_Midas_Skill
     "ANGER_OF_PET",//AngerOfPet_Skill
     "PROTECTION",//Protection_Skill
     "FLYING_SUPPORT",//SupportFly_Skill
     "THE_TEAMMATE"//Teammate_Skill_container
]

