import {PeriodTypeShortcut} from "@/item-editor/constants/GamePlayImpactType";
import {PeriodTypeNames} from "@/item-editor/constants/GamePlayImpactType";
import BehaviourTypes from "../constants/BehaviourTypes";

let numTapPerSecond = 8;
let numSecondPerBossHit = 2;
let isCheckPeriodAllImpactType = true;

export default {
    getPeriodTypeList(mainBehave) {
        if (!mainBehave || mainBehave.behaviourBean === null) return [];
        let periodTypes = [];
        let isPeriodLimit = mainBehave.behaviourBean.type === BehaviourTypes.BehaviourByNames.incHeroSize;
        if (isPeriodLimit || isCheckPeriodAllImpactType) {
            let minPeriodTimeBase = -1;

            if (mainBehave.gamePlayImpact.duration > 0) {
                minPeriodTimeBase = mainBehave.gamePlayImpact.duration + 1;
            } else {
                switch (mainBehave.gamePlayImpact.valueType) {
                    case PeriodTypeNames.DurationInSeconds:
                        minPeriodTimeBase = mainBehave.gamePlayImpact.value;
                        break;
    
                    case PeriodTypeNames.NumOfTaps:
                        minPeriodTimeBase = mainBehave.gamePlayImpact.value * numTapPerSecond;
                        break;
    
                    case PeriodTypeNames.NumOfGotHits:
                        minPeriodTimeBase = mainBehave.gamePlayImpact.value * numSecondPerBossHit;
                        break;
                }
            }

            for (const periodTypeShortcutKey in PeriodTypeShortcut) {
                let isRuleValidate = false;
                let periodShortcut = PeriodTypeShortcut[periodTypeShortcutKey];
                switch (periodShortcut.type) {
                    case PeriodTypeNames.DurationInSeconds:
                        isRuleValidate = periodShortcut.value > minPeriodTimeBase;
                        break;

                    case PeriodTypeNames.NumOfTaps:
                        isRuleValidate = periodShortcut.value / numTapPerSecond >= minPeriodTimeBase;
                        break;

                    case PeriodTypeNames.NumOfGotHits:
                        isRuleValidate = periodShortcut.value / numSecondPerBossHit >= minPeriodTimeBase;
                        break;
                }
                if (isRuleValidate) {
                    periodTypes.push({
                        text: periodShortcut.value + ' ' + periodShortcut.unit,
                        value: periodTypeShortcutKey
                    })
                }
            }
        } else {
            for (const periodTypeShortcutKey in PeriodTypeShortcut) {
                let periodShortcut = PeriodTypeShortcut[periodTypeShortcutKey];
                periodTypes.push({text: periodShortcut.value + ' ' + periodShortcut.unit, value: periodTypeShortcutKey})
            }
        }
        return periodTypes;
    }
}