export default [
     'weapon', 'shield', 'glove', 'ring', 'scroll', 'potion', 'ensign', 'lantern',
]
export let ItemCategoryNames = {
     'weapon':'weapon',
     'shield':'shield',
     'glove':'glove',
     'ring':'ring',
     'scroll':'scroll',
     'potion':'potion',
     'ensign': 'ensign',
     'lantern': 'lantern',
}