<template>
    <v-card
            class="my-1"
            width="115"
            elevation="3"
            max-width="300"
            @click="this.dispatchClick"
            :color="getBoarderColor()"
    >
        <v-row justify="center"
               class="spacing-playground pa-1"
        >
          <v-img
              :src="getItemIcon()"
              max-width="85px"
              max-height="85px"
              dark
              class="mt-3"
          >
          </v-img>
          <v-chip
              v-if="this.iconData.totalUsed > 0"
              class="ma-4"
              color="green darken-2"
              text-color="white"
          >
            <div class="text-lg-button">
              {{this.iconData.totalUsed}}
            </div>
          </v-chip>
          <v-chip
              v-else
              class="ma-4"
              color="grey darken-3"
              text-color="deep-orange lighten-4"
          >
            Never used
          </v-chip>
        </v-row>
    </v-card>
</template>

<script>
import {EVENT_TO_OPEN_ITEM_FORM, EVENT_TO_OPEN_SIBLING_ITEM_FORM, GlobalEvents} from "@/item-editor/events/GlobalEvents";

    export default {
        name: "ItemIconListCardItem.vue",
        props:{
            'pIconData':{
                required: true
            },
            'pFromSibling':{
                type: Boolean,
                default: false,
                required: false
            },
        },
        data() {
            return {
                iconData: null,
            }
        },
        created() {
            this.iconData = this.pIconData;
        },
        methods: {
            dispatchClick() {
                if(this.pFromSibling){
                    GlobalEvents.$emit(EVENT_TO_OPEN_SIBLING_ITEM_FORM, this.iconData);
                } else {
                    GlobalEvents.$emit(EVENT_TO_OPEN_ITEM_FORM, this.iconData);
                }
            },
            getItemIcon() {
                //return require('../assets/icons/itemicons/' + this.iconData.name);
                //return require('../sr' + this.iconData.name);
                return require('../assets/weapons/textures/' + this.iconData.name);
            },
            getBoarderColor(){
                if(this.iconData.isCurrentSelect){
                    return "green";
                }
                return "grey darken-4";
            }
        }
    }
</script>

<style scoped>
    .v-list--two-line .v-list-item,
    .v-list-item--two-line {
        min-height: 30px;
        height:30px;
    }
</style>