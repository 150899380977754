var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-card-actions',[_c('v-card-title',[_vm._v(" Filter: ")]),_c('v-col',[_c('v-card-actions',[_c('v-text-field',{attrs:{"label":"By description"},on:{"input":_vm.onWordChange},model:{value:(_vm.itemFilterTypeWord),callback:function ($$v) {_vm.itemFilterTypeWord=$$v},expression:"itemFilterTypeWord"}})],1)],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"By game level","type":"number"},on:{"input":_vm.onLevelFilterChange},model:{value:(_vm.levelFilter),callback:function ($$v) {_vm.levelFilter=$$v},expression:"levelFilter"}})],1),_c('v-col',[_c('v-combobox',{attrs:{"items":_vm.environmentRef,"label":"By Environment Status","prepend-icon":"mdi-filter-variant","solo":"","clearable":""},on:{"change":_vm.onEnvironmentChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeEnvironmentFilterChosen(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))]),_vm._v("  ")])]}}]),model:{value:(_vm.environmentSelected),callback:function ($$v) {_vm.environmentSelected=$$v},expression:"environmentSelected"}})],1),_c('v-col',[_c('v-combobox',{attrs:{"items":_vm.heroFilterSelect,"chips":"","clearable":"","label":"By hero name","prepend-icon":"mdi-filter-variant","solo":""},on:{"change":_vm.onHeroFilterChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeHeroFilterChosen(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))]),_vm._v("  ")])]}}]),model:{value:(_vm.heroFilterChosen),callback:function ($$v) {_vm.heroFilterChosen=$$v},expression:"heroFilterChosen"}})],1),_c('v-col',[_c('v-combobox',{attrs:{"items":_vm.impactFilterSelect,"chips":"","clearable":"","label":"By impact","prepend-icon":"mdi-filter-variant","solo":""},on:{"change":_vm.onFilterChangeChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeImpactFilterChosen(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))]),_vm._v("  ")])]}}]),model:{value:(_vm.impactFilterChosen),callback:function ($$v) {_vm.impactFilterChosen=$$v},expression:"impactFilterChosen"}})],1),_c('v-col',[_c('v-combobox',{attrs:{"items":_vm.sortFilterSelect,"chips":"","clearable":"","label":"Sorting by","prepend-icon":"mdi-filter-variant","solo":""},on:{"change":_vm.onSortFilterChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeSortFilterChosen(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))]),_vm._v("  ")])]}}]),model:{value:(_vm.sortFilterChosen),callback:function ($$v) {_vm.sortFilterChosen=$$v},expression:"sortFilterChosen"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }